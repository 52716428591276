.targeted-campaign {
  display: grid;
  position: relative;
  gap: rem(32px);

  @include respond-to('laptop') {
    grid-template-columns: 1fr rem(380px);
  }

  @include respond-to('laptop-l') {
    grid-template-columns: 1fr rem(468px);
  }

  &__main {
    @include flex(null, null, column);

    gap: rem(32px);
  }

  &__head {
    @include flex(null, null, column-reverse);

    gap: rem(32px);

    @include respond-to('laptop') {
      flex-direction: column;
    }
  }

  &__widget {
    @include flex(center);

    @include respond-to('laptop') {
      display: none;
    }
  }

  &__title {
    margin-bottom: rem(16px);
    font-size: rem(22px);
    line-height: 1.2;

    @include respond-to('tablet') {
      font-size: rem(32px);
    }

    @include respond-to('laptop') {
      font-size: rem(40px);
    }
  }

  &__preview {
    @include flex(center, center);

    width: 100%;
    background-color: var(--cb);
    overflow: hidden;
    border-radius: rem(16px);
    position: relative;

    @include respond-to('laptop') {
      border-radius: rem(24px);
    }
  }

  &__preview-cover {
    @include absolute-center;
    @include flex(center, center);
    @include size(100%);

    background: rgba($black, 0.7);
  }

  &__preview-cover-text {
    @include flex(center, center);
    @include font-size(18px, 21px);

    padding: rem(8px) rem(16px);
    border-radius: rem(28px);
    background-color: var(--f5);
    text-align: center;
    font-weight: 700;

    @include respond-to('laptop') {
      @include font-size(24px, 38px);
    }
  }

  &__img {
    @include size(100%, 100%);

    object-fit: contain;
  }

  &__status {
    @include flex(flex-start, center);

    margin-bottom: rem(8px);
  }

  &__sidebar {
    display: none;
    position: relative;

    @include respond-to('laptop') {
      display: block;
    }
  }

  &__sticky {
    position: sticky;
    top: calc(var(--header-height) + rem(24px));
  }

  &__content {
    h2,
    h3 {
      @include font-size(24px, 32px);

      margin-top: 0;
      margin-bottom: rem(32px);
    }

    p {
      @include font-size(18px, 28px);

      margin-bottom: rem(32px);

      * {
        font-family: inherit !important;
        font-size: inherit !important;
        color: inherit !important;
      }

      u {
        text-decoration: none;
      }
    }

    a {
      border-bottom: 1px solid var(--black);
      text-decoration: none;
      color: var(--black);
      transition:
        color $animation,
        border-color $animation;

      &:hover {
        text-decoration: none;
        color: var(--black);
        border-color: transparent;
      }
    }

    img {
      display: block;
      width: auto;
      max-width: 100%;
      border-radius: rem(16px);
      margin-bottom: rem(32px);

      @include respond-to('laptop') {
        border-radius: rem(24px);
      }
    }

    iframe {
      display: block;
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      border-radius: rem(16px);
      margin-bottom: rem(32px);

      @include respond-to('laptop') {
        border-radius: rem(24px);
      }
    }

    ul,
    ol {
      @include font-size(18px, 28px);

      margin-bottom: rem(32px);
    }

    .youtube-embed-wrapper {
      margin-bottom: rem(32px);
      border-radius: rem(16px);

      @include respond-to('laptop') {
        border-radius: rem(24px);
      }
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}
