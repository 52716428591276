.contacts-form {
  @include flex(null, flex-start, column);

  width: 100%;

  &__label {
    display: block;
    width: 100%;
    margin-bottom: rem(64px);
  }

  &__input {
    display: block;
    width: 100%;
    background-color: transparent;
    padding-bottom: rem(16px);
    border: 0;
    border-bottom: 1px solid #272727;

    &::placeholder {
      color: $black;
    }

    &:focus {
      outline: none;
    }

    &--textarea {
      min-height: rem(88px);
      resize: none;
    }
  }

  &__submit {
    margin-top: rem(64px);
    color: var(--black);
  }
}
