.back-to-top {
  --size: #{rem(32px)};

  width: var(--size);
  height: var(--size);
  position: absolute;
  right: rem(24px);
  bottom: rem(32px);
  padding: 0;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #fff;
  cursor: pointer;
  transition: border-color $animation;

  @include respond-to('tablet') {
    --size: #{rem(48px)};

    right: rem(32px);
  }

  &:hover {
    border-color: var(--primary);
  }

  &::after {
    --size: #{rem(10px)};

    content: '';
    width: var(--size);
    height: var(--size);
    margin-top: rem(2px);
    background-color: transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(-135deg);

    @include respond-to('tablet') {
      --size: #{rem(16px)};

      margin-top: rem(4px);
      right: rem(32px);
    }
  }
}
