.btn {
  @include reset-button;
  @include font-size(18px, 24px);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(6px);
  padding: rem(18px) rem(32px);
  color: var(--black);
  outline: 0;
  border: 2px solid transparent;
  border-radius: rem(32px);
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  transition:
    color $animation,
    background-color $animation,
    border $animation;

  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
  }

  &__icon {
    @include flex(center);

    pointer-events: none;
  }

  &--primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--black);

    &:visited {
      color: var(--black);
    }

    &:visited,
    &:active {
      background-color: var(--primary);
      border-color: var(--primary);
    }

    &:hover {
      background-color: var(--primary-hover);
      border-color: var(--primary-hover);
      color: var(--black);
    }

    &:focus-visible {
      background-color: var(--primary-hover);
      border-color: var(--primary);
    }

    &:disabled {
      background-color: var(--cb);
      border-color: var(--cb);
      color: var(--c-666);
    }
  }

  &--secondary {
    background-color: var(--white);
    border-color: #e7e9ef;
    color: var(--black);

    &:visited {
      background-color: var(--white);
      border-color: #e7e9ef;
      color: var(--black);
    }

    &:hover,
    &:focus-visible,
    &:active {
      background-color: var(--white);
      border-color: var(--black);
      color: var(--black);
    }

    &:disabled {
      background-color: transparent;
      border-color: #e7e9ef;
      color: var(--c-666);
    }
  }

  &--dark {
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);

    &:visited {
      color: var(--white);
    }

    &:visited,
    &:active {
      background-color: var(--black);
      border-color: var(--black);
    }

    &:hover {
      background-color: var(--black-hover);
      border-color: var(--black-hover);
      color: var(--white);
    }

    &:focus-visible {
      background-color: var(--black-hover);
      border-color: var(--black);
    }

    &:disabled {
      background-color: var(--cb);
      border-color: var(--cb);
      color: var(--c-666);
    }
  }

  &--link {
    padding: rem(8px) rem(32px);
    gap: rem(4px);

    &:visited,
    &:hover,
    &:focus-visible,
    &:active {
      color: var(--black);
    }
  }

  &--blue {
    background-color: #003087;
    border-color: #003087;
    color: var(--white);

    &:visited,
    &:hover,
    &:focus-visible,
    &:active {
      background-color: #003087;
      border-color: #003087;
      color: var(--white);
    }
  }

  &--social {
    @include font-size(12px);

    text-transform: uppercase;
    font-weight: 600;
    padding: rem(12px) rem(20px);
  }

  &--size_40 {
    @include font-size(14px);

    min-width: rem(40px);
    min-height: rem(40px);
    padding: rem(10px) rem(16px);

    .btn__icon {
      @include font-size(16px);
    }
  }

  &--size_48 {
    @include font-size(16px);

    min-width: rem(48px);
    min-height: rem(48px);
    padding: rem(10px) rem(16px);

    .btn__icon {
      @include font-size(18px);
    }
  }

  &--width_100p {
    width: 100%;
  }

  &--icon {
    @include font-size(16px);

    gap: rem(6px);
    padding: 0;
  }

  &--toggle {
    .btn__icon {
      display: flex;

      &--active {
        display: none;
      }
    }

    &.is-active {
      .btn__icon {
        display: none;

        &--active {
          display: flex;
        }
      }
    }
  }
}
