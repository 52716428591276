.faq-block {
  &__title {
    margin-bottom: rem(56px);

    @include respond-to('tablet') {
      margin-bottom: rem(80px);
    }
  }

  .accordion {
    border-color: var(--white);

    &__section {
      border-color: var(--white);
    }

    &__trigger {
      color: var(--white);

      &:focus {
        color: var(--white);
      }
    }

    &__panel {
      .accordion {
        &__text {
          color: var(--white);
        }
      }
    }
  }
}
