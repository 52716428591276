@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.heading {
  @include flex(null, center);
  @include font-size(22px, 26px);

  margin-bottom: rem(24px);
  gap: rem(8px);
  font-family: var(--heading-font-family);
  font-weight: 700;

  @include respond-to('laptop') {
    @include font-size(32px, 38px);

    margin-bottom: rem(32px);
  }
}
