@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.block {
  @include flex(center, center, column);

  width: 100%;
  flex-grow: 1;
  min-height: rem(272px);
  background-color: var(--white);
  border-radius: rem(16px);
  padding: rem(24px);

  @include respond-to('laptop') {
    min-height: rem(466px);
  }
}

.image {
  @include size(rem(124px), rem(160px));

  margin-bottom: rem(32px);
}

.title {
  @include font-size(20px, 24px);

  margin-bottom: rem(16px);
  text-align: center;
}

.text {
  @include font-size(14px, 21px);

  margin-bottom: 0;
  text-align: center;
}
