.social-media {
  @include flex(null, center);

  gap: rem(8px);

  &__link {
    @include size(rem(52px));

    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--primary);
  }
}
