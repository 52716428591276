.cta-banner {
  overflow: hidden;
  position: relative;
  padding: rem(32px) 0;

  &::after {
    --size: #{rem(1024px)};

    content: '';
    display: none;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: rgba($primary, 0.3);
    position: absolute;
    right: -400px;
    bottom: -50%;

    @include respond-to('mobile') {
      --size: #{rem(800px)};

      display: block;
      right: -500px;
    }

    @include respond-to('tablet') {
      --size: #{rem(400px)};

      right: -150px;
    }

    @include respond-to('laptop') {
      --size: #{rem(500px)};

      right: -150px;
    }

    @include respond-to('laptop-l') {
      --size: #{rem(800px)};

      right: -350px;
    }

    @include respond-to('4k') {
      --size: #{rem(1024px)};
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: rem(32px);
    position: relative;
    z-index: 2;

    @include respond-to('tablet') {
      flex-direction: row;
    }
  }

  &__logo {
    --size: #{rem(64px)};

    width: var(--size);
    height: var(--size);

    @include respond-to('laptop-l') {
      --size: #{rem(96px)};
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: rem(24px);

      @include respond-to('laptop-l') {
        gap: rem(40px);
      }
    }

    &-title {
      @include font-size(20px, 30px);

      margin-bottom: rem(4px);

      @include respond-to('mobile') {
        @include font-size(24px, 32px);
      }

      @include respond-to('laptop-l') {
        @include font-size(32px, 40px);
      }
    }

    &-slogan {
      @include font-size(14px, 20px);

      margin-bottom: 0;

      @include respond-to('mobile') {
        @include font-size(16px, 24px);
      }

      @include respond-to('laptop-l') {
        @include font-size(20px, 24px);
      }
    }
  }
}
