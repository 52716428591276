h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  margin-top: 0;
  margin-bottom: rem(24px);
  font-weight: 700;
  line-height: 1.3;

  @include respond-to('laptop') {
    margin-bottom: rem(40px);
  }
}

h1,
.h1 {
  font-size: var(--fs-h1);
}

h2,
.h2 {
  font-size: var(--fs-h2);
}

h3,
.h3 {
  font-size: var(--fs-h3);
}

h4,
.h4 {
  font-size: var(--fs-h4);
}

h5,
.h5 {
  font-size: var(--fs-h5);
}

h6,
.h6 {
  font-size: var(--fs-h6);
}

p {
  margin-top: 0;
  margin-bottom: rem(16px);
  font-size: rem(16px);
  color: var(--black);
}

a {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;

  &:visited {
    color: var(--black);
  }

  &:hover {
    text-decoration: underline;
    color: var(--primary);
  }
}
