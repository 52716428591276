.pagination {
  @include flex(center, center);

  gap: rem(8px);
  margin: 0;
  padding: 0;

  @include respond-to('mobile') {
    gap: rem(16px);
  }

  &__item {
    --size: #{rem(32px)};

    @include flex(center, center);

    width: var(--size);
    height: var(--size);

    @include respond-to('mobile') {
      --size: #{rem(40px)};

      &:first-child {
        margin-right: rem(16px);
      }

      &:last-child {
        margin-left: rem(16px);
      }
    }
  }

  &__link {
    --size: 100%;

    @include flex(center, center);

    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: #f5f5f5;
    transition: background-color $animation;

    &:hover {
      background-color: var(--primary);
      text-decoration: none;
      color: var(--black);
    }

    &.is-active {
      background-color: var(--primary);
    }
  }
}
