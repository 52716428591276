.kolossus-member {
  @include flex(null, center, column);
  @include size(100%);

  padding: rem(16px);
  border-radius: rem(32px);
  background-color: rgba($primary, 0.24);

  &__logo {
    --size: #{rem(120px)};

    @include size(var(--size));
    @include flex(center, center);

    margin-bottom: rem(16px);
    border-radius: 50%;
    background-color: #fff;

    @include respond-to('mobile') {
      --size: #{rem(160px)};
    }

    @include respond-to('laptop') {
      --size: #{rem(180px)};
    }
  }

  &__name {
    @include font-size(24px, 40px);

    width: 100%;
  }

  &__description {
    width: 100%;
  }
}
