.main {
  &__wrapper {
    @include flex(center, null, column);

    width: 100%;
    max-width: rem(1016px);
    margin-left: auto;
    margin-right: auto;
  }

  &__kolona {
    @include flex(center, center);

    overflow: hidden;

    img {
      max-width: rem(800px);

      @include respond-to('mobile') {
        max-width: 100%;
      }
    }
  }

  &__title-block {
    padding-top: rem(40px);
    border-top: 1px solid rgba($grey-9e, 0.24);

    @include respond-to('laptop') {
      padding-top: rem(80px);
    }
  }
}
