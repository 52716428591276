.hero-vacation-card {
  @include flex(null, flex-start, column);

  width: auto;
  padding: rem(12px);
  gap: rem(6px);
  border-radius: rem(8px);
  border-left: 4px solid var(--primary);
  background-color: #fff;
  box-shadow: -3px 5px 22px 0 rgb(147 146 146 / 25%);
  font-family: var(--heading-font-family);
  font-weight: 500;
  color: #000;

  @include respond-to('laptop') {
    min-width: rem(236px);
    padding: rem(16px);
    gap: rem(16px);
  }

  &__label {
    @include font-size(12px, 16px);

    @include respond-to('laptop') {
      @include font-size(14px, 20px);
    }

    @include respond-to('4k') {
      @include font-size(16px, 21px);
    }
  }

  &__value {
    @include font-size(14px, 18px);

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }

    @include respond-to('4k') {
      @include font-size(34px, 44px);
    }
  }
}
