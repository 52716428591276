.campaign-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: rem(20px) rem(16px);
  background-color: rgba($black, 0.04);
  border-radius: rem(20px);
  text-decoration: none;
  transition: box-shadow $animation;
  cursor: pointer;

  &:visited {
    text-decoration: none;
    color: var(--black);
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 0 20px rgba($black, 0.2);
  }

  @include respond-to('laptop') {
    border-radius: rem(40px);
  }

  @include respond-to('laptop-l') {
    padding: rem(40px) rem(24px);
    border-radius: rem(40px);
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__photo-wrapper {
    --size: #{rem(100px)};

    width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    border: 4px solid var(--primary);
    overflow: hidden;
    margin-bottom: rem(10px);

    @include respond-to('mobile') {
      --size: #{rem(128px)};

      border: 8px solid var(--primary);
    }

    @include respond-to('laptop-l') {
      --size: #{rem(180px)};

      margin-bottom: rem(20px);
    }
  }

  &__person-name {
    @include font-size(24px, 40px);

    margin-bottom: rem(8px);
    font-weight: var(--fw-700);
    text-align: center;
  }

  &__title {
    @include font-size(20px, 28px);

    margin-bottom: rem(8px);
    font-weight: var(--fw-600);
    text-align: center;
  }

  &__period {
    @include font-size(14px, 20px);

    margin-bottom: rem(16px);
    color: rgba($black, 0.6);

    @include respond-to('laptop-l') {
      @include font-size(16px, 24px);

      margin-bottom: rem(32px);
    }
  }

  &__total {
    @include font-size(16px, 24px);

    margin-bottom: rem(16px);
    text-align: center;

    @include respond-to('laptop-l') {
      @include font-size(18px, 28px);

      margin-bottom: rem(32px);
    }

    &-sum {
      font-weight: var(--fw-700);
    }

    &-label {
      &--empty {
        color: rgba($black, 0.8);
      }
    }
  }

  &__btn {
    width: 100%;
    max-width: rem(218px);
    min-width: auto;
    height: rem(48px);
    padding: 0;

    @include respond-to('laptop') {
      height: rem(60px);
    }
  }

  &__label {
    margin-bottom: rem(8px);
    color: rgba($black, 0.6);
    text-align: center;
  }

  &.is-past {
    background-color: rgba($primary, 0.3);

    .campaign-card {
      &__total {
        @include flex(center, center, column);

        &-label {
          display: block;
          margin-bottom: rem(10px);
        }

        &-sum {
          @include font-size(32px, 32px);
        }
      }
    }
  }
}
