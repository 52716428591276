.separator {
  width: 100%;
  height: 1px;
  background-color: rgba($black, 0.2);
  margin-top: rem(24px);
  margin-bottom: rem(24px);

  @include respond-to('tablet') {
    margin-top: rem(40px);
    margin-bottom: rem(40px);
  }

  &--short {
    max-width: rem(168px);
    height: 2px;
    background-color: #c9c9c9;
    margin: rem(32px) auto;

    @include respond-to('tablet') {
      margin: rem(56px) auto;
    }
  }
}
