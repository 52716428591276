.js-char-animation {
  opacity: 0;
  will-change: opacity, filter;
  filter: blur(4px);

  &.active {
    opacity: 1;
    filter: blur(0);
  }
}
