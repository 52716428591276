.greeting {
  display: flex;
  align-items: center;
  gap: rem(16px);
  padding: rem(16px) rem(24px);
  background-color: var(--f9);
  border-radius: rem(20px);
  position: relative;

  @include respond-to('mobile') {
    padding: rem(24px) rem(32px);
    border-radius: rem(24px);
    gap: rem(24px);
  }

  @include respond-to('laptop-l') {
    padding: rem(32px) rem(40px);
    border-radius: rem(40px);
  }

  &.is-highlighted {
    background-color: rgba($primary, 0.3);
  }

  &.is-double {
    margin-bottom: rem(16px);

    @include respond-to('mobile') {
      margin-bottom: rem(24px);
    }

    @include respond-to('laptop-l') {
      margin-bottom: rem(40px);
    }
  }

  &__body {
    flex: 1 0 0%;
  }

  &__author {
    @include font-size(20px, 24px);

    display: inline-flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    font-weight: var(--fw-700);

    @include respond-to('mobile') {
      @include font-size(22px, 30px);
    }

    @include respond-to('tablet') {
      align-items: center;
      flex-direction: row;
      margin-bottom: rem(4px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }
  }

  &__message {
    @include font-size(16px, 24px);

    margin-bottom: 0;

    @include respond-to('mobile') {
      @include font-size(20px, 30px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }

    &.is-empty {
      color: rgba($black, 0.4);
    }
  }

  &__sum {
    font-size: rem(16px);
    font-weight: var(--fw-700);
    margin-bottom: 0;

    @include respond-to('mobile') {
      font-size: rem(24px);
    }

    @include respond-to('laptop-l') {
      font-size: rem(32px);
    }
  }

  &__top-label {
    @include font-size(12px, 12px);

    display: inline-flex;
    align-items: center;
    margin-bottom: rem(16px);
    padding: rem(8px) rem(8px);
    background-color: var(--primary);
    font-weight: var(--fw-700);

    @include respond-to('tablet') {
      @include font-size(16px, 16px);

      margin-left: rem(16px);
      margin-bottom: 0;
      padding: rem(8px) rem(16px);
    }
  }
}
