.input {
  @include font-size(16px);

  display: block;
  width: 100%;
  height: rem(56px);
  padding: rem(12px);
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: rem(8px);
  outline: none;
  font-weight: 600;
  transition: border-color #{$animation};

  @include respond-to('mobile') {
    padding: rem(12px) rem(16px);
  }

  &::placeholder {
    color: rgba($black, 0.4);
    font-weight: 400;
  }

  &:disabled,
  &:read-only {
    cursor: default;
    color: rgba($black, 0.5);
  }

  &:focus {
    &:not(:read-only) {
      border-color: var(--primary);
    }
  }

  &--textarea {
    min-height: rem(132px);
    height: auto;
    resize: none;
    line-height: 1.5;
  }

  &--with-btn {
    padding-right: rem(48px);

    @include respond-to('laptop-l') {
      padding-right: rem(64px);
    }
  }

  &--border {
    border-color: var(--c4);
  }

  &--grey {
    @include font-size(14px);

    background-color: var(--f9);
    border-color: var(--f9);
    margin-bottom: rem(16px);

    &::placeholder {
      color: var(--c-9e);
    }
  }
}

.input-block {
  width: 100%;
  position: relative;

  &:not(:last-child) {
    margin-bottom: rem(20px);
  }

  &__label {
    font-size: rem(14px);
    white-space: nowrap;
    text-align: center;

    @include respond-to('mobile') {
      padding-left: rem(26px);
      font-size: rem(16px);
      text-align: left;
    }
  }

  &__description {
    @include font-size(16px, 24px);

    display: inline-block;
    margin-top: rem(8px);
    padding-left: rem(18px);
  }

  &__hint-button {
    @include reset-button;
    @include flex(center, center);
    @include size(rem(32px));

    position: absolute;
    right: rem(16px);
    top: rem(8px);

    @include respond-to('mobile') {
      right: rem(24px);
    }

    svg {
      @include size(100%);

      opacity: 0.2;
      transition: opacity $animation;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  &--date {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: rem(16px);

    @include respond-to('mobile') {
      flex-direction: row;
      align-items: center;
      gap: rem(24px);
    }

    @include respond-to('laptop-l') {
      gap: rem(32px);
    }
  }
}

// .auto-width-field {
//   @include flex(center, center);
//   @include font-size(16px);

//   width: 100%;
//   height: rem(40px);
//   padding: 0;
//   background-color: #fff;
//   border: 2px solid #fff;
//   border-radius: rem(20px);
//   outline: none;
//   transition: border-color #{$animation};
//   position: relative;

//   @include respond-to('mobile') {
//     height: rem(48px);
//     padding: 0 rem(24px);
//     border-radius: rem(24px);
//   }

//   @include respond-to('laptop-l') {
//     @include font-size(20px);

//     height: rem(64px);
//     padding: 0 rem(32px);
//     border-radius: rem(32px);
//   }

//   @include respond-to('4k') {
//     @include font-size(24px);
//   }

//   &::placeholder {
//     color: rgba(#000, 0.5);
//   }

//   &:focus {
//     border-color: var(--primary);
//   }

//   &__input {
//     width: 100%;
//     padding: 0;
//     border-radius: 0;
//     border: 0;
//     background-color: transparent;
//     text-align: center;

//     &:focus {
//       outline: none;
//     }
//   }

//   &__size {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 0;
//     overflow: hidden;
//     visibility: hidden;
//     opacity: 0;
//     white-space: nowrap;
//   }
// }
