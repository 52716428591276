@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.row {
  @include flex(null, null, column);

  gap: rem(8px);

  @include respond-to('laptop') {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(16px);

    &.mono {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
