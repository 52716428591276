.targeted-banner-modal {
  max-width: rem(340px);
  border-radius: rem(16px);

  @include respond-to('mobile') {
    max-width: rem(418px);
  }

  &__body {
    @include flex(null, null, column);

    gap: rem(32px);
    padding: rem(56px) rem(16px) rem(16px);

    @include respond-to('mobile') {
      padding: rem(56px) rem(24px) rem(24px);
    }
  }

  &__close {
    @include size(rem(24px));

    position: absolute;
    right: rem(24px);
    top: rem(16px);

    span {
      @include size(100%);
      @include flex(center, center);
    }
  }

  &__title {
    @include font-size(24px, 26px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: 700;
  }

  &__partners {
    @include flex(center, center);
    @include size(100%, auto);

    min-height: rem(48px);
    flex-wrap: wrap;
    gap: rem(12px);
  }

  &__logo {
    height: rem(16px);
    filter: brightness(0.5);

    img {
      @include size(100%);

      object-fit: contain;
    }
  }

  &__empty {
    @include size(100%, rem(12px));
    @include font-size(12px);

    overflow: hidden;
    color: var(--c-95);
  }
}
