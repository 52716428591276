.modal-window {
  outline: 0;
  width: 100%;
  max-width: 90%;
  border-radius: rem(20px);
  background-color: #fff;

  @include respond-to('mobile') {
    max-width: rem(480px);
  }

  @include respond-to('laptop-l') {
    max-width: rem(640px);
  }

  &__header {
    padding: rem(24px);

    @include respond-to('laptop') {
      padding: rem(40px);
    }
  }

  &__body {
    @include flex(flex-start, stretch, column);

    width: 100%;
    padding: 0 rem(24px) rem(16px);

    @include respond-to('laptop') {
      padding: 0 rem(40px) rem(16px);
    }
  }

  &__title {
    @include font-size(20px, 30px);

    margin-bottom: rem(8px);

    @include respond-to('tablet') {
      @include font-size(32px, 40px);

      margin-bottom: rem(16px);
    }

    @include respond-to('laptop-l') {
      @include font-size(48px, 64px);

      margin-bottom: rem(24px);
    }
  }

  &__sub-title {
    @include font-size(16px, 24px);

    margin-bottom: rem(16px);
    font-weight: var(--fw-700);

    @include respond-to('tablet') {
      @include font-size(20px, 30px);

      margin-bottom: rem(24px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 32px);
    }
  }

  &__text {
    @include font-size(14px, 20px);

    @include respond-to('tablet') {
      @include font-size(16px, 24px);
    }
  }

  &__footer {
    @include flex(flex-start, center, column);

    width: 100%;
    padding: rem(24px);

    @include respond-to('tablet') {
      margin-top: rem(24px);
    }

    @include respond-to('laptop') {
      padding: rem(40px);
    }

    @include respond-to('laptop-l') {
      margin-top: rem(40px);
    }
  }

  &__link {
    @include font-size(14px, 20px);

    font-weight: var(--fw-700);

    @media screen and (max-width: 767px) {
      min-width: auto;
    }

    @include respond-to('tablet') {
      @include font-size(16px, 24px);
    }
  }

  &__close {
    @include reset-button;
    @include size(32px);

    position: absolute;
    top: rem(10px);
    right: rem(10px);

    @include respond-to('tablet') {
      top: rem(24px);
      right: rem(24px);
    }
  }

  &--content {
    .modal-window {
      &__body {
        padding: 0 rem(16px);

        @include respond-to('tablet') {
          border-radius: rem(20px);
          padding: 0 rem(40px);
        }

        @include respond-to('laptop-l') {
          padding: 0 rem(72px);
        }
      }

      &__title {
        margin-bottom: 0;
      }
    }
  }
}
