.advent-carousel {
  width: 250px;
  height: 348px;
  overflow: visible;

  &__btn {
    @include reset-button;
    @include size(rem(38px), rem(62px));
    @include flex(center, center);

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border-radius: rem(20px);
    background-color: var(--white);
    border: 1px solid var(--white);
    color: var(--black);
    transition:
      border-color $animation,
      background-color $animation;

    &:hover {
      border-color: var(--cb);
    }

    &:disabled {
      color: var(--cb);
    }

    &:active {
      background-color: var(--cb);
      border-color: var(--cb);

      &:disabled {
        background-color: var(--white);
        color: var(--cb);
        border-color: var(--white);
      }
    }

    &--left {
      left: rem(-48px);

      @include respond-to('laptop') {
        left: rem(-112px);
      }
    }

    &--right {
      right: rem(-48px);

      @include respond-to('laptop') {
        right: rem(-112px);
      }

      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      fill: currentColor; // stylelint-disable-line
      transition: color $animation;
    }
  }

  .swiper-slide {
    background-color: var(--cb);
    overflow: hidden;
    border-radius: rem(8px);

    .advent-carousel-card {
      opacity: 0;
    }

    &-next,
    &-prev {
      background-color: var(--f5);
      box-shadow: 0 4px 7px 5px rgba($black, 0.12);
    }

    &-active {
      background-color: var(--white);
      box-shadow: 0 4px 7px 5px rgba($black, 0.12);

      .advent-carousel-card {
        opacity: 1;
      }
    }
  }
}
