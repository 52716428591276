.kava-choice {
  padding: rem(16px) 0 rem(72px);
  background: linear-gradient(
    180deg,
    rgb(191 191 191 / 80%) 0%,
    rgb(255 243 166 / 80%) 54.46%,
    rgb(255 255 255 / 80%) 100%
  );

  @include respond-to('tablet') {
    padding: rem(40px) 0 rem(96px);
  }

  &__grid {
    @include flex(null, center, column);

    grid-column-gap: rem(40px);

    @include respond-to('tablet') {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__left {
    order: 1;

    @include respond-to('tablet') {
      order: initial;
    }
  }

  &__right {
    @include flex(null, center, column);

    width: 100%;
    order: 0;

    @include respond-to('tablet') {
      order: initial;
    }
  }

  &__preview {
    --size: #{rem(256px)};

    @include size(var(--size));
    @include flex(center, center);

    position: relative;
    flex: 1 0 auto;

    @include respond-to('tablet') {
      --size: #{rem(304px)};
    }

    @include respond-to('laptop') {
      --size: #{rem(400px)};
    }
  }

  &__emoji {
    @include absolute-center;

    margin-bottom: 0;
    margin-top: rem(4px);
    font-size: 3rem;
    line-height: 1;
  }

  &__title {
    @include font-size(24px, 32px);

    margin-bottom: rem(16px);

    @include respond-to('laptop') {
      @include font-size(28px, 38px);
    }

    &--mobile {
      display: block;

      @include respond-to('tablet') {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include respond-to('tablet') {
        display: block;
      }
    }
  }

  &__explainer {
    @include font-size(20px, 32px);

    width: 100%;
    margin-bottom: rem(24px);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }
  }

  &__copy {
    width: 100%;
    margin-bottom: rem(40px);
  }

  &__social {
    width: 100%;
    margin-bottom: rem(40px);
    margin-top: rem(40px);

    @include respond-to('laptop') {
      margin-top: rem(40px);
    }
  }

  &__web {
    @include flex(center, center);

    width: 100%;

    @include respond-to('tablet') {
      display: none;
    }

    &-link {
      @include font-size(12px, 16px);

      color: rgba($black, 0.6);
      text-align: center;
    }
  }

  &__prediction {
    margin-bottom: rem(8px);

    @include respond-to('tablet') {
      margin-bottom: rem(24px);
    }
  }

  &__link {
    @include font-size(18px);

    font-weight: var(--fw-700);
    text-decoration: none;

    @include respond-to('laptop') {
      @include font-size(24px);
    }

    &-border {
      border-bottom: 1px solid var(--black);
      transition: border-color $animation;
    }

    &:hover {
      color: var(--black);
      text-decoration: none;

      .kava-choice__link-border {
        border-color: transparent;
      }
    }

    &--desktop {
      display: none;

      @include respond-to('tablet') {
        display: block;
      }
    }

    &--mobile {
      display: block;

      @include respond-to('tablet') {
        display: none;
      }
    }
  }

  &__divider {
    @include size(100%, 1px);

    display: block;
    margin: rem(24px) 0;
    background-color: rgba($kava, 0.2);

    @include respond-to('tablet') {
      display: none;
    }
  }
}
