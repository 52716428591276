.order-choice {
  &__content {
    @include font-size(18px, 18px);

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: rem(80px);
    height: rem(48px);
    border-radius: rem(24px);
    background-color: #fff;
    border: 2px solid rgba($black, 0.1);
    cursor: pointer;
    transition:
      border-color $animation,
      background-color $animation;

    @include respond-to('laptop-l') {
      @include font-size(24px, 24px);

      min-width: rem(112px);
      height: rem(64px);
      border-radius: rem(32px);
    }

    &:hover {
      border-color: var(--primary);
    }
  }

  &__input[type='radio'] {
    @include reset-radio-button;

    &:focus {
      outline: none;
    }

    &:focus + .order-choice__content {
      border-color: var(--primary);
    }

    &:checked + .order-choice__content {
      background-color: rgba($primary, 0.2);
      border-color: var(--primary);
      font-weight: var(--fw-700);
    }
  }
}
