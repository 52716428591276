.leading-text {
  @include font-size(16px, 24px);

  width: 100%;
  max-width: rem(960px);
  margin-bottom: rem(24px);
  margin-left: auto;
  margin-right: auto;
  color: var(--grey);
  text-align: center;

  @include respond-to('tablet') {
    margin-bottom: rem(32px);
  }

  @include respond-to('laptop') {
    @include font-size(24px, 40px);

    margin-bottom: rem(56px);
  }

  &--sm {
    @include respond-to('laptop') {
      @include font-size(20px, 32px);
    }
  }

  &--mb_24 {
    margin-bottom: rem(24px);
  }
}
