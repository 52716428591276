.grey-box {
  width: 100%;
  padding: rem(24px) rem(16px);
  background-color: var(--f9);
  border-radius: rem(24px);

  @include respond-to('tablet') {
    padding: rem(40px);
    border-radius: rem(32px);
  }

  @include respond-to('laptop-l') {
    padding: rem(80px);
    border-radius: rem(48px);
  }

  &:not(:last-child) {
    margin-bottom: rem(40px);

    @include respond-to('laptop-l') {
      margin-bottom: rem(80px);
    }
  }

  &__description {
    margin-bottom: rem(16px);

    @include respond-to('tablet') {
      margin-bottom: rem(40px);
    }

    @include respond-to('laptop-l') {
      margin-bottom: rem(80px);
    }

    p {
      font-size: rem(20px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__grid {
    @include respond-to('laptop') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rem(64px);
    }
  }
}
