.tc-recent-campaigns {
  width: 100%;
  height: 100%;
  padding-bottom: rem(48px);

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    @include flex(space-between, center);

    gap: rem(12px);
    bottom: 0;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    flex: 1 0 auto;
    width: auto;
    height: rem(16px);
    max-width: none;
    border-radius: rem(8px);
    background-color: #fff;
    opacity: 1;
    transition: background-color $animation;

    &.swiper-pagination-bullet-active {
      background-color: var(--primary);
    }
  }
}
