.advent-day-page {
  background-color: var(--black);
  position: relative;
  padding-top: rem(40px);

  &__container {
    position: relative;
    z-index: 2;
  }

  &__content {
    color: var(--white);

    h2,
    h3 {
      @include font-size(20px, 24px);

      font-family: var((--heading-font-family));
      font-weight: 700;

      @include respond-to('mobile') {
        @include font-size(32px, 38px);
      }
    }

    p {
      @include font-size(18px, 28px);

      margin-bottom: rem(32px);
      font-weight: 500;
      color: var(--white);
    }
  }

  &__media {
    margin-bottom: rem(24px);
    position: relative;
  }

  &__media-container {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: rem(8px);
    overflow: hidden;

    @include respond-to('mobile') {
      border-radius: rem(16px);
    }

    iframe,
    img {
      @include size(100%);

      object-fit: contain;
    }
  }

  &__title {
    @include font-size(20px, 24px);

    font-family: var((--heading-font-family));
    font-weight: 700;
  }

  &__snow {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  &__snow-left {
    width: rem(70px);
    left: -2px;
    bottom: -8px;

    @include respond-to('mobile') {
      width: rem(158px);
      bottom: -16px;
    }
  }

  &__snow-right {
    width: rem(90px);
    right: 0;
    top: -6px;

    @include respond-to('mobile') {
      width: rem(200px);
      top: -12px;
    }
  }
}
