.kolona-cta {
  @include flex(null, null, column);

  gap: rem(12px);
  background-color: rgba($primary, 0.4);
  border-radius: rem(32px);
  padding: rem(24px) rem(20px);

  @include respond-to('laptop') {
    gap: rem(16px);
    padding: rem(32px);
  }

  &__title {
    @include font-size(28px, 36px);

    font-weight: 500;

    @include respond-to('laptop') {
      @include font-size(36px, 42px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    font-weight: 500;
    color: rgba($black, 0.7);

    @include respond-to('laptop') {
      @include font-size(18px, 28px);
    }
  }

  &__action {
    margin-top: rem(12px);

    @include respond-to('laptop') {
      margin-top: 0;
    }
  }
}
