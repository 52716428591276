.social-links {
  @include flex(null, center);

  gap: rem(24px);
  margin: 0;
  padding: 0;
  list-style: none;

  @include respond-to('tablet') {
    gap: rem(32px);
  }

  &__link {
    @include flex(center, center);

    font-size: rem(30px);
    line-height: 1;
    color: #fff;
    transition: color $animation;

    &:visited {
      color: #fff;
    }

    &:hover {
      color: var(--primary);
    }
  }

  &__icon {
    fill: currentColor; // stylelint-disable-line
  }

  &--kava {
    .social-links {
      &__link {
        color: var(--kava);

        &:visited {
          color: var(--kava);
        }

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  &--black {
    // stylelint-disable
    .social-links {
      &__link {
        // stylelint-enable
        color: var(--black);

        &:visited {
          color: var(--black);
        }

        &:hover {
          color: var(--black);
        }
      }
    }
  }

  &--full_width {
    justify-content: space-around;
  }
}
