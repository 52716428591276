.targeted-campaigns {
  &__grid {
    display: grid;
    gap: rem(32px);
    margin-top: rem(40px);
    margin-bottom: rem(40px);

    @include respond-to('tablet') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('laptop') {
      margin-top: rem(48px);
      margin-bottom: rem(48px);
    }

    @include respond-to('laptop-l') {
      grid-template-columns: repeat(3, 1fr);
      margin-top: rem(64px);
      margin-bottom: rem(96px);
    }
  }
}
