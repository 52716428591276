.google-button {
  @include reset-button;
  @include flex(center, center);
  @include font-size(14px);

  width: 100%;
  gap: 2px;
  min-height: 56px;
  overflow: hidden;
  padding: 0 56px;
  border: 1px solid #747775;
  border-radius: 56px;
  font-family: var(--google-font);
  font-weight: 500;
  color: #000;
  transition: all .3s;

  &:hover {
    box-shadow: 0 2px 8px 0 rgba($black, 0.3);
  }
}
