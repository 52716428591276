.tc-modal-donations {
  @media (max-width: 37.4375em) {
    border-radius: 0;
    height: 100%;
  }

  @media (max-width: 47.9375em) {
    max-width: rem(600px);
  }

  &__title {
    margin-bottom: 0;
  }

  &__description {
    @include font-size(14px, 24px);

    margin: rem(16px) 0 rem(40px);
    color: var(--grey);
  }

  &__form {
    @include flex(null, ceter, column);

    gap: rem(16px);
    background-color: var(--f9);
    padding: rem(16px);
    border-radius: rem(24px);
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(24px);
  }

  &__cta {
    max-height: rem(64px);
    margin-top: rem(40px);
  }

  &__button-group {
    @include flex(space-between, center);

    gap: rem(16px);
    margin-bottom: rem(24px);
  }

  &__list {
    @include flex(null, stretch, column);

    height: calc(100vh - 380px);
    padding-right: rem(14px);

    @include respond-to('mobile') {
      max-height: rem(240px);
    }
  }

  &__item {
    margin-bottom: rem(24px);
  }
}

.simplebar-scrollbar::before {
  background-color: var(--primary);
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}
