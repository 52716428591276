.donate-collapsible {
  padding: rem(24px) rem(24px) 0;
  background-color: #fff;
  border-radius: rem(20px);

  @include respond-to('laptop-l') {
    padding: rem(40px) rem(40px) 0;
  }

  &:not(:last-child) {
    margin-bottom: rem(24px);

    @include respond-to('laptop') {
      margin-bottom: rem(32px);
    }
  }

  &__trigger {
    @include flex(space-between, center);

    padding-bottom: rem(24px);
    font-family: var(--heading-font-family);
    font-size: rem(24px);
    font-weight: 700;
    color: var(--black);

    @include respond-to('laptop-l') {
      padding-bottom: rem(40px);
      font-size: rem(40px);
    }
  }

  &__icon {
    @include flex(center, center);
  }

  &__wrapper {
    padding-bottom: rem(24px);

    @include respond-to('laptop-l') {
      padding-bottom: rem(40px);
    }
  }
}
