.donation-widget {
  @include flex(null, null, column);

  width: 100%;
  max-width: rem(468px);
  gap: rem(8px);

  &__progress {
    @include flex(null, null, column);

    padding: rem(16px);
    border-radius: rem(16px);
    border: 1px solid var(--cb);
    background-color: var(--white);
    gap: rem(8px);

    @include respond-to('laptop-l') {
      padding: rem(16px) rem(32px);
      border-radius: rem(24px);
    }
  }

  &__body {
    @include flex(null, null, column);

    padding: rem(16px);
    gap: rem(24px);
    border-radius: rem(16px);
    border: 1px solid var(--cb);
    background-color: var(--f5);

    @include respond-to('laptop-l') {
      padding: rem(24px) rem(32px);
      border-radius: rem(24px);
    }
  }

  &__title {
    @include font-size(20px, 24px);

    margin-bottom: 0;
    color: var(--black);
    font-family: var(--heading-font-family);
    font-weight: 700;

    @include respond-to('laptop-l') {
      @include font-size(28px, 34px);
    }
  }

  &__form {
    @include flex(null, null, column);

    gap: rem(24px);
  }

  &__label {
    width: 100%;
    position: relative;
  }

  &__currency {
    @include font-size(24px, 38px);

    color: var(--c-666);
    font-weight: 700;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: color $animation;
  }

  &__input {
    @include font-size(40px, 48px);

    width: 100%;
    padding: rem(4px) rem(20px) rem(4px) 0;
    border: 0;
    border-bottom: 1px solid var(--cb);
    color: var(--c-666);
    font-weight: 700;
    background-color: transparent;
    transition:
      border-color $animation,
      color $animation;

    &:focus,
    &.is-valid {
      border-color: var(--black);
      outline: none;
      color: var(--black);

      & + .donation-widget__currency {
        color: var(--black);
      }
    }
  }

  &__amounts {
    @include flex(space-between, center);

    gap: rem(8px);
  }

  &__amount {
    @include reset-button;
    @include flex(center, center);
    @include font-size(18px, 22px);

    padding: rem(16px) rem(12px);
    gap: rem(8px);
    align-self: stretch;
    border-radius: rem(72px);
    border: 2px solid var(--cb);
    background: var(--white);
    color: var(--black);
    font-weight: 700;
    white-space: nowrap;

    &:focus-visible {
      border-color: var(--primary);
      outline: none;
    }

    &:active {
      border-color: var(--primary);
    }

    @include respond-to('laptop-l') {
      @include font-size(22px, 26px);

      padding: rem(17px) rem(24px);
    }
  }

  &__cta {
    @include flex(null, null, column);

    gap: rem(16px);
  }

  &__mono {
    @include flex(center, null, column);

    gap: rem(24px);
    padding: rem(16px);
    border-radius: rem(16px);
    border: 1px solid var(--cb);
    background-color: var(--white);

    @include respond-to('laptop-l') {
      padding: rem(32px);
      border-radius: rem(24px);
    }
  }

  &__mono-text {
    @include font-size(14px, 21px);

    margin-bottom: 0;
  }

  &__copy {
    @include font-size(14px, 21px);

    margin-bottom: 0;
    margin-top: rem(8px);
    color: var(--c-666);

    a {
      color: inherit;
    }
  }

  &__reports {
    @include flex(null, null, column);

    gap: rem(24px);
    padding: rem(32px);
  }

  &__icon {
    @include size(rem(24px));

    font-size: rem(24px);
  }

  &--modal {
    .donation-widget {
      &__body {
        padding: rem(16px);
        gap: rem(12px);
        border-radius: rem(16px);

        @include respond-to('mobile') {
          gap: rem(24px);
        }
      }

      &__title {
        @include font-size(20px, 24px);
      }

      &__form {
        gap: rem(12px);

        @include respond-to('mobile') {
          gap: rem(24px);
        }
      }

      &__amount {
        @include font-size(18px, 22px);

        padding: rem(16px) rem(8px);

        @include respond-to('mobile') {
          padding: rem(16px);
        }
      }

      &__cta {
        gap: rem(12px);

        @include respond-to('mobile') {
          gap: rem(16px);
        }
      }

      &__mono {
        padding: rem(16px);
        border-radius: rem(16px);
        gap: rem(12px);

        @include respond-to('mobile') {
          gap: rem(24px);
        }
      }
    }
  }
}
