* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  font-weight: var(--fw-400);
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  line-height: var(--line-height);
  color: var(--black);
  min-width: rem(320px);
  overflow: hidden auto;
  -webkit-font-smoothing: antialiased; // stylelint-disable-line
}

img {
  display: block;
  width: auto;
  max-width: 100%;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

hr {
  height: 1px;
  margin: rem(40px) 0;
  background-color: var(--f9);
  border: none;
}

.input {
  font-size: 1.125rem;
  line-height: 1;
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

// Container for the SVG sprite emitted inside the HTML document
#svg-sprite-container {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.default-list {
  margin: 0 0 rem(24px);
  padding-left: rem(16px);
}
