.user-profile {
  width: 100%;
  max-width: rem(316px);
  overflow: hidden;
  background-color: var(--white);

  &__header {
    padding: rem(8px) 0 rem(20px);
  }

  &__body {
    padding: rem(20px) 0;
    border-top: 1px solid #e7e9ef;
  }

  &__title {
    display: none;
  }

  &__link {
    @include flex(space-between, center);

    gap: rem(16px);

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  &--popup {
    width: 100%;
    height: 100%;
    padding: 0 rem(24px) rem(8px);
    max-width: none;

    @include respond-to('mobile') {
      height: auto;
      max-width: rem(390px);
      padding: 0 rem(32px) rem(8px);
      box-shadow: $box-shadow;
    }

    .user-profile {
      &__header {
        padding: rem(32px) 0 rem(20px);
      }

      &__title {
        @include font-size(8px, 12px);

        display: block;
        margin-bottom: rem(8px);
        font-family: var(--heading-font-family);
        font-weight: var(--fw-700);
        text-align: center;
      }
    }
  }
}
