.kolo-cannes-info {
  background: linear-gradient(180deg, rgb(255 221 0 / 0%) 0%, #fd0 100%);

  &__subtitle {
    @include font-size(20px, 32px);

    margin-bottom: rem(32px);
    color: rgba($black, 0.68);

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }

    @include respond-to('laptop-l') {
      @include font-size(32px, 40px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    margin-bottom: rem(48px);
    color: rgba($black, 0.68);

    @include respond-to('laptop') {
      @include font-size(20px, 32px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }
  }

  &__wrapper {
    @include flex(center, flex-start, column);

    gap: rem(32px);

    @include respond-to('mobile') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__card {
    align-self: stretch;
  }
}
