.tc-widget {
  display: block;
  width: 100%;
  overflow: hidden;

  @include respond-to('laptop') {
    display: none;
    border-radius: rem(40px);
    background-color: var(--f9);
    padding: rem(32px);
  }

  @include respond-to('laptop-l') {
    padding: rem(40px);
  }

  @include respond-to('4k') {
    border-radius: rem(48px);
  }

  &.is-desktop {
    display: none;

    @include respond-to('laptop') {
      display: block;
    }
  }

  &__body {
    @include flex(null, null, column-reverse);

    @include respond-to('laptop') {
      flex-direction: column;
    }
  }

  &__info {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: rem(16px);

    &--desktop {
      display: none;

      @include respond-to('laptop') {
        display: flex;
      }
    }

    &--mobile {
      display: flex;
      margin-top: rem(32px);
      margin-bottom: 0;

      @include respond-to('laptop') {
        display: none;
      }
    }
  }

  &__remaining {
    @include flex(null, center);
  }

  &__small {
    @include font-size(12px, 20px);

    margin-bottom: 0;
    color: var(--grey);

    @include respond-to('laptop') {
      @include font-size(14px, 24px);
    }
  }

  &__progress {
    margin-bottom: rem(12px);

    @include respond-to('laptop') {
      margin-bottom: rem(32px);
    }

    @include respond-to('laptop-l') {
      margin-bottom: rem(40px);
    }
  }

  &__cta {
    @include flex(null, center, column);

    gap: rem(16px);
    background-color: var(--f9);
    padding: rem(24px) rem(16px);
    border-radius: rem(24px);

    @include respond-to('mobile') {
      margin-bottom: rem(40px);
      padding: rem(40px);
      border-radius: rem(40px);
    }

    @include respond-to('laptop') {
      align-items: stretch;
      margin-bottom: rem(40px);
      background-color: transparent;
      padding: 0;
      border-radius: 0;
    }
  }

  &__form {
    @include flex(null, center, column);
  }

  &__currency {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(24px);
    margin-bottom: rem(16px);
  }

  &__partners {
    display: none;

    @include respond-to('laptop') {
      @include flex(center, center);
    }
  }

  &__btn {
    width: 100%;
    max-width: rem(340px);
    min-width: auto;
    margin-top: rem(16px);

    @include respond-to('tablet') {
      margin-top: rem(24px);
    }

    @include respond-to('laptop') {
      max-width: none;
    }
  }
}
