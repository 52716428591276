.advent-gift-box {
  @include size(100%, auto);
  @include flex(center, center);

  max-width: rem(768px);
  margin-left: auto;
  margin-right: auto;
  padding: rem(16px) rem(20px) rem(20px);
  position: relative;
  background-color: var(--white);
  border-bottom-left-radius: rem(16px);
  border-bottom-right-radius: rem(16px);

  @include respond-to('laptop') {
    padding-bottom: rem(40px);
  }

  &::before {
    @include size(100%, rem(30px));

    content: '';
    background-image: url('~@assets/images/snow-hill.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: rem(-8px);
    left: 0;
    z-index: 0;

    @include respond-to('mobile') {
      height: rem(50px);
      top: rem(-15px);
    }

    @include respond-to('tablet') {
      height: rem(66px);
      top: rem(-19px);
    }

    @include respond-to('laptop') {
      height: rem(69px);
      top: rem(-19px);
    }
  }

  &::after {
    @include size(100%, 100%);

    content: '';
    background-color: var(--white);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-bottom-left-radius: rem(16px);
    border-bottom-right-radius: rem(16px);
  }

  &__trees {
    @include flex(space-between, flex-start);
    @include size(100%, rem(92px));

    position: absolute;
    left: 0;
    top: rem(-42px);

    @include respond-to('mobile') {
      top: rem(-56px);
      padding-right: rem(6px);
    }
  }

  &__trees-left {
    @include size(rem(156px), rem(92px));
  }

  &__trees-right {
    @include size(rem(85px), rem(80px));

    margin-top: rem(4px);
  }

  &__card {
    @include flex(center, center);
    @include size(rem(568px), rem(370px));

    border-radius: rem(8px);
    background: var(--white);
    padding: 0 rem(20px);
    box-shadow: 0 4px 40px 0 rgba($black, 0.1);
    position: relative;
    z-index: 2;
    overflow: hidden;

    &::before,
    &::after {
      @include size(100%, 13px);

      content: '';
      background-image: url('~@assets/images/border.svg');
      background-size: cover;
      position: absolute;
      left: 0;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__container {
    @include flex(center, center, column);

    width: rem(262px);
    gap: rem(24px);
  }

  &__decor {
    @include flex(center, center);

    gap: rem(24px);
  }

  &__snowflake {
    @include size(rem(20px), rem(20px));
  }

  &__circle {
    @include size(rem(66px), rem(66px));
  }

  &__message {
    @include font-size(18px, 25px);

    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
  }

  &__cta {
    width: 100%;
  }
}
