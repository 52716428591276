@use 'sass:math';

// Functions
@function rem($pxValue) {
  @return math.div($pxValue, 16px) * 1rem;
}

// Mixins
@mixin font-size($size, $height: $size) {
  font-size: rem($size);
  line-height: math.div($height, $size) * 1;
}

@mixin flex($justify-content: null, $align-items: null, $flex-direction: null) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin reset-button {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}

@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin reset-radio-button {
  /* Remove most all native input styles */
  appearance: none;

  /* For iOS < 15 */
  background-color: #fff;

  /* Not removed via appearance */
  margin: 0;
  color: currentColor; // stylelint-disable-line
  width: 0.1px;
  height: 0.1px;
  border: none;
  position: absolute;
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
