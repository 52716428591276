.campaign {
  width: 100%;

  &__heading {
    display: none;
    margin-bottom: rem(16px);
    text-align: center;

    @include respond-to('laptop') {
      display: block;
      text-align: left;
    }

    &--mobile {
      display: block;

      @include respond-to('laptop') {
        display: none;
      }
    }
  }

  &__description {
    display: none;
    margin-bottom: rem(16px);
    font-size: rem(14px);
    color: var(--c-999);
    text-align: center;

    @include respond-to('mobile') {
      margin-bottom: rem(24px);
    }

    @include respond-to('tablet') {
      font-size: rem(18px);
    }

    @include respond-to('laptop') {
      display: block;
      text-align: left;
    }

    @include respond-to('laptop-l') {
      font-size: rem(24px);
    }

    &--mobile {
      display: block;

      @include respond-to('laptop') {
        display: none;
      }
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(16px);

    @include respond-to('mobile') {
      gap: rem(32px);
    }

    @include respond-to('laptop') {
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
      gap: 0;
    }
  }

  &__upload {
    &-logo {
      width: 50%;
      height: 50%;
      transform: scale(1);
      transition: transform $animation;
    }

    &-area {
      --size: #{rem(150px)};

      width: var(--size);
      height: var(--size);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: rem(16px);
      background-color: rgba($black, 0.1);
      border: 8px solid $white;
      position: relative;
      border-radius: 50%;
      cursor: pointer;

      @include respond-to('mobile') {
        --size: #{rem(250px)};

        margin-top: rem(16px);
        margin-bottom: rem(24px);
      }

      @include respond-to('laptop') {
        --size: #{rem(300px)};
      }

      @include respond-to('laptop-l') {
        margin-bottom: rem(40px);
      }

      @include respond-to('4k') {
        --size: #{rem(400px)};
      }

      &::after {
        --size: #{rem(110px)};

        content: '';
        display: block;
        width: var(--size);
        height: var(--size);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
        border: 2px dashed rgba($black, 0.2);
        border-radius: 50%;
        transition: transform $animation;

        @include respond-to('mobile') {
          --size: #{rem(200px)};
        }

        @include respond-to('laptop') {
          --size: #{rem(250px)};
        }

        @include respond-to('laptop-l') {
          margin-bottom: rem(40px);
        }

        @include respond-to('4k') {
          --size: #{rem(350px)};
        }
      }

      &:hover {
        &::after {
          transform: translate(-50%, -50%) scale(0.98);
        }

        .campaign__upload-logo {
          transform: scale(1.1);
        }
      }

      &.is-error {
        border-color: var(--red);
      }
    }
  }

  &__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__preview-block {
    @include flex(center, center);

    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    user-select: none;
    z-index: 10;
    overflow: hidden;
    border-radius: 50%;
  }

  &__hint {
    @include font-size(16px, 24px);

    margin-bottom: rem(4px);
    color: rgba($black, 0.5);

    @include respond-to('mobile') {
      @include font-size(20px, 30px);

      margin-bottom: rem(10px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 36px);
    }
  }

  &__size-restriction {
    @include font-size(12px, 20px);

    margin-bottom: 0;
    color: rgba($black, 0.5);

    &.is-error {
      color: var(--red);
    }

    @include respond-to('mobile') {
      @include font-size(14px, 20px);
    }

    @include respond-to('laptop-l') {
      @include font-size(16px, 24px);
    }
  }

  &__fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  &__uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__fields {
    width: 100%;
    max-width: rem(560px);

    @include respond-to('laptop-l') {
      max-width: rem(680px);
    }

    @include respond-to('4k') {
      max-width: rem(768px);
    }
  }

  &__select-group {
    display: grid;
    gap: rem(24px);

    @include respond-to('mobile') {
      grid-template-columns: 1fr 2fr 2fr;
    }

    @include respond-to('laptop-l') {
      grid-template-columns: 1fr 2fr 2fr;
    }

    @include respond-to('4k') {
      gap: rem(32px);
    }
  }

  &__submit {
    margin-top: rem(16px);

    @include respond-to('mobile') {
      margin-top: rem(32px);
    }

    @include respond-to('laptop-l') {
      margin-top: rem(64px);
    }
  }

  &__double {
    @include flex(null, flex-start, column);

    position: relative;
    gap: rem(16px);

    @include respond-to('mobile') {
      @include flex(space-between, center, row);
    }
  }

  &__popup {
    display: none;
    position: absolute;
    margin-bottom: rem(80px);
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: rem(40px) rem(24px) rem(16px);
    background-color: var(--primary);
    border-radius: rem(24px);

    @include respond-to('mobile') {
      margin-bottom: rem(40px);
    }

    @include respond-to('laptop-l') {
      margin-bottom: rem(48px);
      padding: rem(64px) rem(32px) rem(24px);
      border-radius: rem(40px);
    }

    &.is-active {
      display: block;
      animation: 200ms ease-in-out fade-in;
    }

    &-close {
      @include reset-button;
      @include flex(center, center);

      position: absolute;
      right: rem(24px);
      top: rem(20px);

      @include respond-to('laptop-l') {
        right: rem(24px);
        top: rem(24px);
      }
    }

    &-text {
      @include font-size(16px, 24px);

      @include respond-to('laptop-l') {
        @include font-size(18px, 32px);
      }
    }
  }

  // Created company
  &__container {
    @include flex(center, center, column);

    width: 100%;
    gap: rem(16px);

    @include respond-to('tablet') {
      gap: rem(24px);
    }

    @include respond-to('laptop') {
      gap: rem(80px);
      align-items: flex-start;
      display: grid;
      grid-template-columns: rem(300px) 1fr;
    }

    @include respond-to('laptop-l') {
      gap: rem(100px);
    }

    @include respond-to('4k') {
      grid-template-columns: rem(400px) 1fr;
      gap: rem(140px);
    }
  }

  &__info {
    width: 100%;
    max-width: rem(560px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to('laptop') {
      display: block;
      max-width: rem(600px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(720px);
    }

    @include respond-to('4k') {
      max-width: rem(826px);
    }
  }

  &__profile {
    @include flex(null, center, column);

    gap: rem(16px);

    @include respond-to('tablet') {
      gap: rem(24px);
    }

    @include respond-to('laptop') {
      gap: rem(32px);
    }
  }

  &__photo-wrapper {
    --size: #{rem(100px)};

    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: #fff;
    border: 6px solid var(--primary);

    @include respond-to('tablet') {
      --size: #{rem(150px)};
    }

    @include respond-to('laptop') {
      --size: #{rem(240px)};

      border-width: 10px;
    }

    @include respond-to('laptop-l') {
      --size: #{rem(300px)};
    }

    @include respond-to('4k') {
      --size: #{rem(400px)};
    }
  }

  &__title {
    margin-bottom: rem(12px);
    font-size: rem(24px);
    text-align: center;

    @include respond-to('tablet') {
      font-size: rem(32px);
    }

    @include respond-to('laptop') {
      font-size: rem(40px);
      text-align: left;
    }

    @include respond-to('laptop-l') {
      font-size: rem(56px);
    }

    @include respond-to('4k') {
      font-size: rem(64px);
    }
  }

  &__name {
    margin-bottom: 0;
    font-size: rem(20px);

    @include respond-to('tablet') {
      font-size: rem(24px);
    }

    @include respond-to('laptop-l') {
      font-size: rem(32px);
    }

    &-wrapper {
      @include flex(space-between, null, column);

      gap: rem(16px);
      margin-bottom: rem(16px);

      @include respond-to('tablet') {
        margin-bottom: rem(24px);
      }

      @include respond-to('laptop') {
        flex-direction: row;
        gap: rem(24px);
      }
    }
  }

  &__period {
    margin-bottom: rem(8px);
    font-size: rem(16px);
    color: var(--c-999);

    @include respond-to('tablet') {
      margin-bottom: rem(16px);
    }

    @include respond-to('laptop') {
      margin-bottom: rem(24px);
      font-size: rem(20px);
    }

    @include respond-to('laptop-l') {
      font-size: rem(24px);
    }
  }

  &__author-message {
    @include font-size(16px, 24px);

    width: 100%;
    min-width: rem(288px);
    margin-bottom: 0;
    border-radius: rem(24px);
    padding: rem(16px);
    background-color: rgba($primary, 0.3);
    font-size: rem(16px);

    @include respond-to('laptop-l') {
      padding: rem(24px);
      border-radius: rem(40px);
      font-size: rem(20px);
    }

    p:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &--white {
      background-color: #fff;
    }
  }

  &__wrapper {
    width: 100%;
    margin: rem(40px) 0;

    @include respond-to('laptop-l') {
      margin: rem(80px) 0;
    }

    &.is-double {
      @include flex(center, center, column);

      gap: rem(24px);

      @include respond-to('mobile') {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__col {
    width: 100%;
    text-align: center;

    @include respond-to('laptop') {
      text-align: left;
    }
  }

  &__label {
    margin-bottom: rem(12px);
    font-size: rem(16px);
    color: var(--c-999);

    @include respond-to('laptop') {
      font-size: rem(20px);
    }

    @include respond-to('laptop-l') {
      font-size: rem(24px);
    }

    @include respond-to('4k') {
      font-size: rem(32px);
    }
  }

  &__balance {
    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-size: rem(40px);
    font-weight: var(--fw-700);

    @include respond-to('laptop-l') {
      font-size: rem(56px);
    }

    @include respond-to('4k') {
      font-size: rem(56px);
    }
  }

  &__double-block {
    padding: rem(16px);
    background-color: rgba($primary, 0.3);
    border-radius: rem(24px);
    text-align: center;

    @include respond-to('laptop-l') {
      border-radius: rem(40px);
      padding: rem(24px);
    }
  }

  &__double-title {
    @include font-size(16px);

    margin-bottom: rem(16px);

    @include respond-to('laptop-l') {
      @include font-size(24px);

      margin-bottom: rem(24px);
    }
  }

  &__double-sum {
    @include font-size(24px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: var(--fw-600);

    @include respond-to('laptop') {
      @include font-size(32px);
    }
  }

  // Order
  &__order-form {
    width: 100%;
  }

  &__reason {
    @include font-size(16px, 24px);

    margin-bottom: rem(8px);
    text-align: center;

    @include respond-to('tablet') {
      @include font-size(20px, 30px);

      margin-bottom: rem(16px);
      text-align: left;
    }

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }
  }

  &__donations {
    @include flex(null, center, column);

    gap: rem(16px);
    margin-bottom: rem(16px);

    @include respond-to('tablet') {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }

  // Order detail
  &__present {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: rem(24px);

    @include respond-to('mobile') {
      margin-bottom: rem(40px);
    }

    &-label {
      @include font-size(16px, 24px);

      margin-bottom: 0;

      @include respond-to('mobile') {
        @include font-size(20px, 30px);
      }

      @include respond-to('laptop-l') {
        @include font-size(28px, 38px);
      }
    }

    &-amount {
      @include font-size(18px, 18px);

      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(48px);
      margin-bottom: 0;
      padding: 0 rem(16px);
      background-color: rgba($primary, 0.2);
      border: 2px solid var(--primary);
      border-radius: rem(24px);
      font-weight: var(--fw-700);

      @include respond-to('mobile') {
        padding: 0 rem(20px);
      }

      @include respond-to('laptop-l') {
        @include font-size(24px, 24px);

        height: rem(64px);
        border-radius: rem(32px);
      }
    }
  }

  // Thank you page
  &__ty-explainer {
    @include font-size(16px, 24px);

    margin-top: rem(24px);
    margin-bottom: rem(40px);
    padding: rem(24px) rem(16px);
    background-color: rgba($primary, 0.3);
    border-radius: rem(20px);
    text-align: center;

    @include respond-to('tablet') {
      @include font-size(20px, 30px);

      margin-top: rem(32px);
      margin-bottom: rem(48px);
      padding: rem(32px) rem(24px);
    }

    @include respond-to('laptop') {
      @include font-size(24px, 40px);

      margin-top: rem(40px);
      margin-bottom: rem(64px);
      padding: rem(40px) rem(32px);
      border-radius: rem(40px);
      text-align: left;
    }
  }

  &__copy {
    width: 100%;
    margin-bottom: rem(40px);

    @include respond-to('tablet') {
      margin-bottom: rem(48px);
    }

    @include respond-to('laptop') {
      margin-bottom: rem(64px);
    }
  }

  &__button-group {
    @include flex(center, center, column);

    gap: rem(24px);

    @include respond-to('tablet') {
      flex-direction: row;
    }

    @include respond-to('laptop') {
      flex-direction: row;
      gap: rem(32px);
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
