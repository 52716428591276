.main-hero {
  &__wrapper {
    @include flex(center, null, column);

    width: 100%;
    max-width: rem(1250px);
    margin-left: auto;
    margin-right: auto;
  }

  &__cta {
    @include flex(center, null);

    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    gap: rem(24px);
  }

  &__button {
    width: 100%;
    max-width: rem(368px);
    order: 0;

    &:nth-child(2) {
      order: 1;
    }

    &:last-child {
      @include respond-to('laptop') {
        order: 2;
      }
    }
  }

  &__achievements {
    width: 100%;
    margin: rem(40px) auto 0;

    @include respond-to('laptop-l') {
      margin-top: rem(96px);
    }
  }
}
