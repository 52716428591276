.search {
  width: 100%;
  position: relative;

  &__field {
    padding-left: rem(48px);
  }

  &__btn {
    @include reset-button;
    @include flex(center, center);
    @include size(rem(28px));

    position: absolute;
    left: rem(12px);
    top: 50%;
    transform: translateY(-50%);
  }
}
