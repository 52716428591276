.user-achievements-block {
  border-radius: rem(16px);
  background-color: var(--f9);
  padding: rem(16px) rem(16px) rem(24px);

  @include respond-to('laptop') {
    padding: rem(24px) rem(24px) rem(32px);
  }

  &__header {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: rem(16px);
  }

  &__title {
    @include font-size(18px, 24px);

    margin-bottom: 0;
    font-weight: 700;
  }

  &__body {
    padding-top: rem(24px);
  }

  &__cta {
    @include flex(null, stretch, column);

    margin-top: rem(16px);
    gap: rem(16px);
  }

  &__refresh {
    @include flex(center, center);
    @include font-size(14px, 18px);

    gap: rem(6px);
    padding: rem(8px) rem(16px);
    font-weight: 500;
  }

  &__message {
    @include font-size(14px, 18px);

    margin: rem(16px) 0;
    background-color: var(--white);
    border-radius: rem(8px);
    padding: rem(16px);
    font-weight: 500;

    @include respond-to('laptop') {
      @include font-size(16px, 24px);
    }
  }
}

.is-refreshing {
  animation: rotation 0.5s ease-in-out infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
