.request-flow {
  @include flex(null, null, column);

  width: 100%;
  max-width: rem(768px);
  margin: 0 auto;
  gap: rem(48px);
  position: relative;

  &::before {
    @include size(1px, 100%);

    content: '';
    display: block;
    border-left: 1px dashed var(--black);
    position: absolute;
    left: rem(12px);
    top: 0;
  }

  &__index {
    @include size(rem(24px));
    @include flex(center, center);
    @include font-size(18px);

    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: var(--black);
    color: var(--white);
    font-weight: 700;
  }

  &__step {
    position: relative;
    padding-left: rem(40px);

    &:nth-child(even) {
      .request-flow {
        &__index {
          background-color: var(--primary);
          color: var(--black);
        }
      }
    }

    &:last-child {
      &::before {
        @include size(rem(24px), 100%);

        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--white);
      }
    }
  }

  &__arrow {
    @include size(rem(24px));

    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(180deg);
    transition: transform $animation;

    svg {
      display: block;
    }
  }

  &__accordion {
    .ac-trigger {
      @include font-size(18px, 22px);

      margin: 0;
      padding: 0 rem(32px) 0 0;
      font-weight: 700;
      position: relative;

      &:focus {
        color: var(--black);
      }

      &::after {
        content: '';
      }
    }

    .ac {
      margin-top: 0;
      border: 0;

      &.is-active {
        .request-flow {
          &__arrow {
            transform: rotate(0);
          }
        }

        & > .ac-header {
          .ac-trigger {
            &::after {
              content: '';
            }
          }
        }
      }
    }
  }

  &__title {
    @include font-size(18px, 22px);

    margin: 0;
    padding-right: rem(32px);
    font-weight: 700;
  }

  &__cta {
    @include flex(null, center);

    gap: rem(16px);
    flex-wrap: wrap;
    margin-top: rem(24px);
  }
}
