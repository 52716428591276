@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.container {
  @include flex(null, null, column);

  padding: rem(12px);
  gap: rem(20px);
  border-radius: rem(16px);
  background: var(--white);

  @include respond-to('laptop') {
    gap: rem(40px);
  }

  &.primary {
    background-color: var(--primary);
  }

  &.mono {
    background-color: var(--black);
    color: var(--white);
  }
}

.title {
  @include flex(null, center);
  @include font-size(14px, 18px);

  margin-bottom: 0;
  font-weight: 700;
  color: inherit;

  @include respond-to('laptop') {
    @include font-size(16px, 26px);
  }
}

.row {
  @include flex(flex-end, flex-end);

  font-family: var(--heading-font-family);
  font-weight: 700;
  gap: rem(8px);
}

.stat {
  @include font-size(28px, 32px);

  margin-bottom: 0;
  color: inherit;
}

.mark {
  @include font-size(20px, 28px);

  margin-bottom: 0;
  color: inherit;
}
