.anchor {
  --top: #{rem(-40px)};

  position: absolute;
  top: var(--top);
  width: 100%;
  height: 1px;

  @include respond-to('laptop') {
    --top: #{rem(-80px)};
  }
}
