.user-profile-nav {
  width: 100%;

  &__list {
    @include reset-list;
    @include flex(null, null, column);

    gap: rem(24px);
  }

  &__item {
    &:last-child {
      padding-top: rem(20px);
      border-top: 1px solid #e7e9ef;
    }
  }

  &__link {
    @include flex(null, center);
    @include font-size(16px, 24px);

    gap: rem(12px);
    position: relative;
    padding-right: rem(8px);
    font-weight: 500;

    &:hover {
      text-decoration: none;
      color: var(--black);

      @at-root html:not(.is-touch) & {
        &::after {
          opacity: 1;
        }
      }
    }

    &::after {
      @include size(rem(5px), rem(20px));

      content: '';
      display: block;
      background-color: var(--primary);
      position: absolute;
      right: 0;
      top: rem(2px);
      bottom: 0;
      opacity: 0;
      transition: opacity $animation;
    }

    &.is-active {
      font-weight: 700;

      &::after {
        opacity: 1;
      }
    }
  }

  &__icon {
    @include size(rem(16px));
    @include font-size(16px);
  }

  &--popup {
    .user-profile-nav {
      &__link {
        @include font-size(14px, 19px);

        gap: rem(12px);
        padding: 0;

        &::after {
          content: none;
        }

        &.is-active {
          font-weight: 500;
        }
      }
    }
  }
}
