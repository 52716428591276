.article {
  padding: rem(24px) 0 rem(88px);

  &__container {
    display: grid;
    row-gap: rem(24px);

    @include respond-to('laptop') {
      grid-template-areas:
        'header header header'
        'body body aside';
      gap: rem(32px) rem(48px);
    }
  }

  &__header {
    @include respond-to('laptop') {
      grid-area: header;
    }
  }

  &__preview {
    aspect-ratio: 2.58;
    object-fit: cover;
    width: 100%;

    &-wrapper {
      overflow: hidden;
      border-radius: rem(8px);
      margin-bottom: rem(24px);
    }
  }

  &__date {
    @include font-size(18px, 30px);

    width: 100%;
    display: block;
    margin-bottom: 0;
    padding-bottom: rem(8px);
    border-bottom: 1px solid #d5d5d5;
    color: #8c8c8c;
  }

  &__body {
    @include respond-to('laptop') {
      grid-area: body;
    }
  }

  &__content {
    h2 {
      @include font-size(28px, 32px);

      margin-bottom: rem(48px);
      margin-top: rem(72px);

      @include respond-to('laptop') {
        @include font-size(40px, 48px);

        margin-bottom: rem(64px);
        margin-top: rem(88px);
      }
    }

    h3 {
      @include font-size(24px, 30px);

      margin-bottom: rem(40px);
      margin-top: rem(72px);

      @include respond-to('laptop') {
        @include font-size(32px, 40px);

        margin-bottom: rem(56px);
        margin-top: rem(88px);
      }
    }

    h4 {
      @include font-size(22px, 26px);

      margin-bottom: rem(32px);
      margin-top: rem(40px);

      @include respond-to('laptop') {
        @include font-size(24px, 32px);

        margin-bottom: rem(48px);
        margin-top: rem(56px);
      }
    }

    img {
      width: auto !important;
      height: auto !important;
      margin-bottom: rem(16px);
      object-fit: contain;
      border-radius: rem(8px);
    }

    blockquote {
      @include font-size(32px, 40px);

      margin: rem(24px) 0;
      padding-left: rem(36px);
      position: relative;
      text-transform: uppercase;
      font-weight: var(--fw-600);

      @include respond-to('laptop') {
        padding-left: rem(76px);
      }

      &::before {
        @include size(rem(12px), 100%);

        content: '';
        display: block;
        background-color: #d4d4d4;
        position: absolute;
        left: 0;
        top: 0;

        @include respond-to('laptop') {
          left: rem(40px);
        }
      }

      > * {
        margin: 0;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit;
      }
    }

    p {
      @include font-size(18px, 30px);

      margin-bottom: rem(32px);
      font-weight: var(--fw-500);

      & * {
        &:not(img, iframe) {
          margin: inherit !important;
          padding: inherit !important;
          font-size: inherit !important;
          font-family: inherit !important;
          font-style: inherit !important;
          color: inherit !important;
        }
      }
    }

    ul {
      padding-left: rem(48px);
    }

    ol {
      list-style: none;
      counter-reset: counter;
      padding-left: rem(24px);

      li {
        @include flex;

        counter-increment: counter;
        gap: rem(16px);

        &::before {
          @include size(rem(30px));
          @include flex(center, center);

          content: counter(counter);
          flex-shrink: 0;
          border-radius: 50%;
          background-color: var(--primary);
        }
      }
    }

    ul,
    ol {
      margin: rem(48px) 0;

      @include respond-to('laptop') {
        padding-left: rem(64px);
      }

      li {
        @include font-size(18px, 30px);

        font-weight: var(--fw-500);

        &:not(:last-child) {
          margin-bottom: rem(24px);
        }
      }
    }

    .youtube-embed-wrapper {
      margin-bottom: rem(40px);
      border-radius: rem(40px);

      iframe {
        width: 100% !important;
      }
    }

    a {
      text-decoration: none;
      border-bottom: 1px solid var(--black);
      transition: border-color $animation;

      &:hover {
        text-decoration: none;
        border-color: transparent;
        color: var(--black);
      }
    }
  }

  &__title {
    @include font-size(32px, 40px);

    margin-bottom: rem(32px);

    @include respond-to('laptop') {
      @include font-size(56px, 64px);
    }
  }

  &__lead {
    @include font-size(20px, 32px);

    margin-bottom: rem(32px);
    padding-bottom: rem(40px);
    border-bottom: 1px solid #d5d5d5;
    font-weight: var(--fw-600);

    @include respond-to('laptop') {
      @include font-size(24px, 40px);

      padding-bottom: rem(56px);
    }
  }

  &__aside {
    @include respond-to('laptop') {
      grid-area: aside;
    }

    &-title {
      @include font-size(32px, 40px);
    }

    &-container {
      @include flex(null, null, column);

      gap: rem(32px);
    }
  }
}
