.tc-donation {
  @include flex(space-between, center);

  width: 100%;
  gap: rem(16px);

  &__person {
    @include flex(null, center);

    gap: rem(8px);
  }

  &__icon {
    @include size(rem(40px));
    @include flex(center, center);

    flex: 1 0 auto;
    border-radius: 50%;
    background-color: var(--f9);
  }

  &__name {
    @include font-size(12px, 14px);

    margin-bottom: rem(4px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
  }

  &__type {
    @include font-size(12px, 14px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    color: var(--grey);
  }

  &__sum {
    @include font-size(14px, 20px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    text-align: right;

    @include respond-to('mobile') {
      @include font-size(18px, 22px);
    }
  }
}
