.breadcrumbs {
  width: 100%;
  margin-top: rem(16px);
  overflow: hidden;

  @include respond-to('laptop') {
    margin-top: rem(24px);
  }

  &__list {
    @include reset-list;
    @include flex(null, center);

    gap: rem(16px);
  }

  &__item {
    @include font-size(16px, 26px);

    position: relative;

    &:not(:last-child) {
      @include flex(null, center);

      gap: rem(16px);
      font-weight: var(--fw-500);
      color: var(--c-95);

      &::after {
        @include font-size(22px, 26px);

        content: '>';
        color: var(--black);
      }
    }

    &:last-child {
      pointer-events: none;
    }
  }

  &__link {
    color: inherit;
    font-weight: var(--fw-500);
    white-space: nowrap;

    &:hover,
    &:visited {
      color: inherit;
    }
  }
}
