.kolo-cannes-donations {
  &__list {
    @include flex(flex-start, stretch, column);

    width: 100%;
    max-width: rem(480px);
    gap: rem(24px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(48px);

    @include respond-to('laptop') {
      display: grid;
      max-width: 100%;
      grid-template-columns: repeat(3, 1fr);
      padding: rem(40px) rem(24px);
    }
  }

  &__item {
    @include flex(center, center, column);

    padding: rem(24px) rem(16px);
    background-color: var(--f9);
    border-radius: rem(24px);

    @include respond-to('laptop-l') {
      padding: rem(40px) rem(24px);
    }

    &--top {
      background-color: var(--primary);
    }
  }

  &__label {
    @include font-size(20px, 32px);

    margin-bottom: rem(8px);
    font-weight: var(--fw-600);

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }
  }

  &__value {
    @include font-size(40px, 48px);

    margin-bottom: 0;
    font-weight: var(--fw-600);

    @include respond-to('laptop') {
      @include font-size(48px, 56px);
    }

    @include respond-to('laptop-l') {
      @include font-size(56px, 64px);
    }

    @include respond-to('4k') {
      @include font-size(64px, 76px);
    }
  }

  &__email {
    @include font-size(16px, 24px);

    margin-top: rem(8px);
    margin-bottom: 0;

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }
  }
}
