.reminder {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: rem(24px);
  background-color: var(--f9);
  padding: rem(24px) rem(24px);
  border-radius: rem(20px);

  @include respond-to('mobile') {
    border-radius: rem(24px);
    padding: rem(32px) rem(40px);
  }

  @include respond-to('tablet') {
    gap: rem(32px);
  }

  @include respond-to('laptop') {
    flex-direction: row;
    align-items: flex-start;
    gap: rem(48px);
  }

  @include respond-to('laptop-l') {
    padding: rem(48px) rem(64px);
    border-radius: rem(40px);
    gap: rem(88px);
  }

  @include respond-to('4k') {
    padding: rem(64px) rem(88px);
  }

  &__logo {
    width: rem(122px);
    height: rem(120px);

    @include respond-to('laptop-l') {
      width: rem(216px);
      height: rem(212px);
    }
  }

  &__container {
    width: 100%;
  }

  &__title {
    @include font-size(24px, 32px);

    margin-bottom: rem(24px);
    text-align: center;

    @include respond-to('laptop') {
      text-align: left;
    }

    @include respond-to('laptop-l') {
      @include font-size(32px, 40px);
    }
  }

  &__description {
    width: 100%;
    max-width: rem(624px);
    margin-bottom: 0;
    line-height: 1.6;
    color: var(--grey);
    text-align: center;

    @include respond-to('laptop') {
      text-align: left;
    }
  }

  &__form {
    display: grid;
    grid-template-areas:
      'day'
      'month'
      'button';
    justify-items: center;
    gap: rem(24px);
    margin-top: rem(40px);

    @include respond-to('mobile') {
      grid-template-areas:
        'day month'
        'button button';
    }

    @include respond-to('laptop') {
      grid-template-areas: 'day month button';
      text-align: left;
    }

    @include respond-to('laptop-l') {
      gap: rem(40px);
    }
  }

  &__day {
    grid-area: day;
  }

  &__month {
    grid-area: month;
  }

  &__btn {
    max-width: rem(240px);
    min-width: auto;
    grid-area: button;
  }
}
