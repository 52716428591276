.video-wrapper {
  width: 100%;
  max-width: rem(1408px);
  aspect-ratio: 16/9;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: rem(16px);

  iframe {
    @include size(100%);
  }
}
