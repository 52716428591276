.reports {
  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: rem(48px) rem(32px);
    margin-bottom: rem(48px);
    overflow: hidden;
    transition: max-height 500ms ease-out;

    @include respond-to('mobile') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('laptop') {
      grid-template-columns: repeat(3, 1fr);
      gap: rem(80px) rem(48px);
      margin-bottom: rem(80px);
    }

    @include respond-to('laptop-l') {
      column-gap: rem(64px);
    }
  }

  &__heading {
    text-align: center;

    @include respond-to('mobile') {
      text-align: left;
    }
  }

  &__card {
    width: 100%;
  }

  &__img-wrapper {
    @include flex(center, center);

    width: 100%;
    overflow: hidden;
    border-radius: rem(24px);
    background-color: var(--f9);
    margin-bottom: rem(16px);
    aspect-ratio: 16 / 9;

    @include respond-to('laptop-l') {
      margin-bottom: rem(32px);
    }
  }

  &__img {
    width: 100%;
  }

  &__date {
    margin-bottom: rem(20px);
    font-size: rem(14px);
    color: var(--grey);

    @include respond-to('laptop-l') {
      margin-bottom: rem(24px);
      font-size: rem(16px);
    }
  }

  &__sum {
    @include font-size(32px, 40px);

    margin-bottom: rem(12px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-500);

    @include respond-to('laptop-l') {
      @include font-size(40px, 56px);

      margin-bottom: rem(16px);
    }
  }

  &__title {
    @include font-size(18px, 24px);

    margin-bottom: rem(20px);
    font-weight: var(--fw-700);

    @include respond-to('laptop-l') {
      @include font-size(24px, 32px);

      margin-bottom: rem(32px);
    }
  }

  &__description {
    margin-bottom: rem(20px);
    color: var(--grey);

    @include respond-to('laptop-l') {
      margin-bottom: rem(24px);
    }
  }

  &__target {
    margin-bottom: 0;
    font-weight: var(--fw-700);
  }

  &__buttons {
    @include flex(center, stretch, column);

    max-width: rem(368px);
    gap: rem(16px);
    margin: 0 auto;

    @include respond-to('tablet') {
      justify-content: flex-start;
      flex-direction: row;
      max-width: none;
      gap: rem(24px);
      margin: 0;
    }
  }
}
