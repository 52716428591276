.advent-carousel-card {
  @include size(100%, 100%);

  display: grid;
  transition: opacity $animation;

  &__body {
    @include flex(null, null, column);

    padding: rem(12px) rem(16px) rem(18px);
    gap: rem(4px);
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: var(--black);
  }

  &__date {
    @include size(rem(148px), rem(148px));
    @include flex(center, center);
    @include font-size(68px);

    border: 8px solid var(--f5);
    border-radius: 50%;
    text-align: center;
    font-family: var(--heading-font-family);
    font-weight: 700;
  }

  &__text {
    @include font-size(24px, 38px);

    text-align: center;
  }

  &__footer {
    @include flex(center, center);

    height: rem(38px);
    padding: 0 rem(8px);
  }

  &__holiday {
    @include size(100%);
    @include flex(center, center);
    @include font-size(14px);

    margin-bottom: 0;
    border-top: 1px dashed var(--cb);
    color: rgba($black, 0.5);
    text-align: center;
  }

  &__header {
    height: rem(46px);
  }

  &__gift {
    @include size(100%, rem(40px));
    @include flex(center, center);
    @include font-size(14px, 18px);

    gap: rem(8px);
    background-color: var(--primary);
    font-weight: 500;
  }

  &__gift-border {
    width: 100%;
    height: 6px;
    background-color: var(--white);
    border-bottom: 3px solid var(--primary);
  }
}
