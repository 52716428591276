.advent-day {
  @include flex(null, null, column);

  width: 100%;
  overflow: hidden;
  margin-bottom: rem(32px);
  border-radius: rem(8px);

  @include respond-to('tablet') {
    border-radius: rem(16px);
    margin-bottom: rem(56px);
  }

  &__header {
    @include flex(space-between, center);

    background-color: var(--primary);
    padding: rem(4px) rem(16px);

    @include respond-to('tablet') {
      padding: rem(6px) rem(40px);
    }
  }

  &__text {
    @include font-size(16px, 40px);

    font-weight: 700;

    @include respond-to('tablet') {
      @include font-size(24px, 40px);
    }
  }

  &__date {
    @include flex(center, center);
    @include size(rem(40px));
    @include font-size(24px);

    background-color: var(--white);
    border-radius: 50%;
    font-family: var(--heading-font-size);
    font-weight: 700;

    @include respond-to('tablet') {
      @include size(rem(72px));
      @include font-size(42px);
    }
  }

  &__holliday {
    @include flex(center, center);
    @include font-size(18px, 28px);

    padding: rem(24px) rem(20px);
    background-color: var(--white);
    border-bottom-left-radius: rem(8px);
    border-bottom-right-radius: rem(8px);
    font-weight: 500;
    color: var(--c-666);
    text-align: center;

    @include respond-to('tablet') {
      @include font-size(24px, 38px);

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__body {
    padding: rem(32px) rem(20px);
    background-color: var(--white);
    border-radius: rem(8px);
    border-top: 1px dashed var(--cb);

    @include respond-to('tablet') {
      padding: rem(32px) rem(40px);
      border-radius: 0 0 rem(16px) rem(16px);
    }

    &--no-border {
      border: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: rem(66px) 1fr;
    gap: rem(24px);

    @include respond-to('tablet') {
      grid-template-columns: rem(138px) 1fr;
    }
  }

  &__decor {
    @include size(rem(66px), rem(67px));

    @include respond-to('tablet') {
      @include size(rem(138px));
    }
  }

  &__content {
    @include flex(flex-start, flex-start, column);
  }

  &__title {
    @include font-size(18px, 21px);

    margin-bottom: rem(8px);
    font-weight: 700;

    @include respond-to('tablet') {
      @include font-size(24px, 28px);
    }
  }

  &__message {
    @include font-size(14px, 21px);

    margin-bottom: 0;

    @include respond-to('tablet') {
      @include font-size(18px, 28px);
    }
  }

  &__now {
    @include flex(flex-start, center);
    @include font-size(14px, 18px);

    flex-grow: 0;
    gap: rem(4px);
    margin-bottom: rem(4px);
    padding: rem(4px) rem(6px);
    border: 1px solid var(--cb);
    border-radius: rem(20px);
    font-weight: 500;

    @include respond-to('tablet') {
      @include font-size(18px, 23px);

      gap: rem(8px);
      margin-bottom: rem(8px);
      padding: rem(4px) rem(12px);
    }

    svg {
      @include size(rem(16px));

      @include respond-to('tablet') {
        @include size(rem(20px));
      }
    }
  }

  &__limit {
    @include flex(null, null, column);

    gap: rem(4px);
    margin-top: rem(24px);

    @include respond-to('tablet') {
      margin-top: rem(16px);
      flex-direction: row;
    }
  }

  &__countdown {
    @include font-size(14px, 21px);

    font-weight: 600;

    @include respond-to('tablet') {
      @include font-size(18px, 28px);
    }
  }

  &__cta {
    margin-top: rem(24px);

    @include respond-to('tablet') {
      padding-left: rem(162px);
    }
  }

  &__btn {
    width: 100%;

    @include respond-to('tablet') {
      width: auto;
    }
  }

  &--closed {
    .advent-day {
      &__header {
        border-radius: rem(8px);

        @include respond-to('tablet') {
          border-radius: 0;
        }
      }
    }
  }
}
