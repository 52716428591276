.hero-vacation {
  &__grid {
    @include flex(null, center, column-reverse);

    gap: rem(32px);

    @include respond-to('laptop-l') {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__content {
    @include flex(null, center, column);

    max-width: rem(640px);

    @include respond-to('laptop-l') {
      align-items: flex-start;
    }
  }

  &__title {
    margin-bottom: rem(32px);
  }

  &__graphics {
    @include flex(center, center);

    max-width: rem(328px);
    position: relative;

    @include respond-to('laptop') {
      max-width: rem(480px);
      margin-left: 0;
      margin-right: 0;
    }

    @include respond-to('4k') {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__card {
    position: absolute;

    &:first-of-type {
      min-width: rem(120px);
      bottom: 0;
      left: 0;

      @include respond-to('laptop') {
        min-width: rem(320px);
        left: auto;
        right: calc(50% + rem(64px));
      }

      @include respond-to('laptop-l') {
        right: calc(50% + rem(64px));
      }
    }

    &:last-of-type {
      min-width: rem(120px);
      top: 0;
      right: 0;

      @include respond-to('laptop') {
        min-width: rem(240px);
        top: rem(-24px);
      }

      @include respond-to('laptop-l') {
        top: rem(-24px);
      }
    }
  }
}
