.progress-bar {
  width: 100%;

  &__top {
    @include size(100%, rem(24px));

    position: relative;
    overflow: hidden;
    background-color: #ccc;
    border-radius: rem(12px);
  }

  &__scale {
    height: 100%;
    background-color: var(--primary);
    border-radius: rem(12px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__value {
    @include font-size(12px, 24px);

    position: absolute;
    left: rem(12px);
    top: 0;
    z-index: 2;
  }

  &__milestones {
    @include size(100%, rem(24px));

    position: relative;
  }

  &__milestone-position {
    width: 2px;
    position: absolute;
    top: 0;

    &::before {
      @include size(2px, 9px);

      content: '';
      background-color: #ccc;
      position: absolute;
      left: 1px;
      top: 0;
    }
  }

  &__milestone-value {
    @include font-size(12px);

    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    font-family: var(--heading-font-family);
    color: var(--grey);

    &.has-reached {
      font-weight: var(--fw-700);
    }
  }

  &--banner {
    @include respond-to('tablet') {
      min-width: rem(182px);
    }

    @include respond-to('laptop-l') {
      min-width: rem(278px);
    }

    @include respond-to('4k') {
      min-width: rem(368px);
    }
  }

  &--sm {
    .progress-bar {
      &__top {
        height: rem(10px);
      }

      &__value {
        @include font-size(8px);

        width: rem(20px);
        margin-left: rem(-24px);

        &--fixed {
          margin-left: rem(4px);
        }
      }
    }
  }

  &--md {
    .progress-bar {
      &__top {
        height: rem(16px);
      }

      &__value {
        @include font-size(10px, 14px);

        width: rem(24px);
        margin-left: rem(-28px);

        &--fixed {
          margin-left: rem(8px);
        }
      }
    }
  }

  &--black {
    .progress-bar {
      &__top {
        background-color: var(--black);
        border: 1px solid var(--black);
        box-shadow: 0 0 1px 1px rgba($white, 0.5);
      }

      &__scale {
        background-color: var(--white);
      }

      &__value {
        text-align: center;
        color: var(--white);
        mix-blend-mode: exclusion;
      }
    }
  }

  &--white {
    .progress-bar {
      &__top {
        background-color: var(--white);
        border: 1px solid var(--white);
        box-shadow: 0 0 1px 1px rgba($black, 0.5);
      }

      &__scale {
        background-color: var(--black);
      }

      &__value {
        text-align: center;
        color: var(--white);
        mix-blend-mode: exclusion;
      }
    }
  }
}
