.login-page {
  @include flex(null, center, column);

  &__container {
    width: 100%;
    max-width: rem(369px);
    position: relative;
  }

  &__title {
    @include font-size(28px, 34px);

    margin-bottom: rem(40px);
  }

  &__providers-list {
    @include reset-list;
    @include flex(null, null, column);

    margin-bottom: rem(24px);
  }

  &__description {
    @include font-size(14px, 20px);

    margin-bottom: rem(24px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-200);
  }

  &__form {
    @include flex(center, null, column);

    margin-bottom: rem(24px);
  }

  &__row {
    @include flex(space-between, center);

    margin: rem(8px) 0 rem(24px);
  }

  &__link {
    @include font-size(14px, 20px);

    font-weight: var(--fw-400);
    text-decoration: underline;
    color: var(--secondary);

    &:hover {
      color: var(--secondary);
      text-decoration: none;
    }

    &:visited {
      color: var(--secondary);
    }
  }

  &__text {
    @include font-size(14px, 20px);

    margin-bottom: 0;

    &--gap {
      margin: rem(24px) 0;
    }
  }

  &__btn {
    margin-top: rem(24px);
    margin-bottom: rem(16px);

    &:visited {
      color: #000;
      text-decoration: none;
    }
  }

  &__my_gap {
    margin-top: rem(32px);
    margin-bottom: rem(32px);
  }

  &__hr {
    height: 2px;
    border: 0;
    background-color: var(--f9);
  }

  &__separator {
    @include flex(center, center);

    margin-top: rem(32px);
    margin-bottom: rem(32px);
    gap: 18px;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: var(--c4);
    }
  }
}
