.tc-partners {
  @include flex(flex-start, center);

  flex-wrap: wrap;

  &__logo {
    @include flex(center, center);

    height: rem(48px);
    padding: rem(16px) rem(24px);
    gap: rem(8px);
    border-radius: rem(48px);
    background-color: var(--f5);
    text-decoration: none;

    img,
    svg {
      height: rem(16px);
      width: auto;
    }
  }

  &--sm {
    .tc-partners {
      &__logo {
        height: rem(32px);
        padding: rem(10px) rem(16px);

        img,
        svg {
          height: rem(12px);
        }
      }
    }
  }
}
