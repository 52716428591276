.kolona-report-hero {
  text-align: center;

  &__content {
    padding-top: rem(80px);

    @include respond-to('laptop') {
      padding-top: rem(38px);
      max-width: rem(768px);
    }
  }

  &__container {
    @include flex(space-between, center, column);

    height: rem(560px);
    overflow: hidden;

    @include respond-to('laptop') {
      height: rem(690px);
    }
  }

  &__sub-title {
    font-size: rem(18px);

    @include respond-to('laptop') {
      font-size: rem(24px);
    }
  }

  &__title {
    font-size: rem(22px);
    line-height: 1.2;
    margin-bottom: 20px;

    @include respond-to('laptop') {
      font-size: rem(40px);
    }
  }

  &__list {
    @include flex;

    padding: 0;
    margin: 0 0 rem(24px);
    gap: rem(20px);
    list-style: none;

    @include respond-to('laptop') {
      margin-bottom: rem(20px);
    }
  }

  &__item {
    padding: rem(6px) rem(10px);
    background: var(--black);
    font-size: rem(12px);
    text-transform: uppercase;
    border-radius: rem(4px);

    @include respond-to('laptop') {
      font-size: rem(16px);
    }
  }

  &__icon {
    @include size(rem(32px));

    margin-right: rem(10px);
  }

  &__image {
    position: relative;
    bottom: rem(15px);
    transform: scale(1.2);

    @include respond-to('laptop') {
      bottom: rem(32px);
      max-width: rem(740px);
    }
  }
}
