.vacation-donate-guide {
  @include flex(null, null, column-reverse);

  gap: rem(32px);

  @include respond-to('laptop') {
    display: grid;
    grid-template-columns: rem(468px) 1fr;
  }

  &__left {
    @include flex(flex-start, center, column);

    @include respond-to('laptop') {
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__list {
    @include reset-list;
    @include flex(null, null, column);

    gap: rem(32px);
    counter-reset: item;
  }

  &__item {
    @include flex(flex-start, flex-start);
    @include font-size(14px, 21px);

    gap: rem(16px);
    font-weight: 500;

    @include respond-to('tablet') {
      @include font-size(18px, 28px);
    }

    &::before {
      @include size(rem(56px));
      @include flex(center, center);
      @include font-size(22px);

      content: counter(item);
      counter-increment: item;
      border: 2px solid var(--primary);
      border-radius: 50%;
      flex-shrink: 0;
      font-weight: 700;
    }
  }

  &__right {
    @include flex(center, center);

    @include respond-to('tablet') {
      padding: rem(40px);
      border-radius: rem(16px);
      background-color: var(--f5);
      background-image: url('~@assets/images/vacation/vacation-decor.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
}
