.kolona-hero {
  background-repeat: no-repeat;
  background-position: 70% center;
  background-size: cover;
  position: relative;

  @include respond-to('laptop') {
    background-position: center top;
    gap: rem(56px);
  }

  &::after {
    @include size(100%);

    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: rgba($black, 0.7);
    transform: matrix(1, 0, 0, -1, 0, 0);

    @include respond-to('laptop') {
      background: linear-gradient(180deg, var(--black) 0%, rgb(0 0 0 / 0%) 100%);
    }
  }

  &__container {
    @include flex(null, null, column);

    gap: rem(24px);
    width: 100%;
    max-width: rem(668px);
    position: relative;
    z-index: 1;
  }

  &__icon {
    @include size(rem(40px));
    @include flex(center, center);

    border-radius: 50%;
    border: 2px solid var(--white);
  }

  &__title {
    line-height: 1.2;
  }

  &__sub-title {
    font-family: var(--heading-font-family);
    color: rgba($white, 0.8);
  }

  &__description {
    @include font-size(20px, 30px);

    color: rgba($white, 0.7);

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }
  }

  &__cta {
    @include flex(center, center);

    margin-top: rem(40px);

    @include respond-to('mobile') {
      display: block;
    }

    @include respond-to('laptop') {
      margin-top: rem(24px);
    }
  }
}
