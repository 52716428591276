.kava-control {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;

  &__content {
    @include flex(null, ceter, column);

    gap: rem(4px);
    padding: rem(12px) rem(20px);
    background: rgba(#fff, 0.6);
    border: 2px solid rgba($black, 0.15);
    border-radius: rem(20px);
    transition:
      background-color $animation,
      border-color $animation;

    @include respond-to('laptop') {
      padding: rem(18px) rem(40px);
      gap: rem(8px);
    }
  }

  &__value {
    @include font-size(20px);

    font-weight: var(--fw-700);
    text-align: center;
    color: var(--black);
    transition: color $animation;

    @include respond-to('laptop') {
      @include font-size(24px);
    }
  }

  &__description {
    @include font-size(10px);

    font-weight: var(--fw-500);
    text-align: center;
    color: rgba($black, 0.5);
    transition: color $animation;

    @include respond-to('laptop') {
      @include font-size(14px);
    }
  }

  &__radio {
    @include reset-radio-button;

    &:checked + .kava-control__content {
      background: rgba($kava, 0.7);
      border: 2px solid var(--kava);

      .kava-control {
        &__value {
          color: #fff;
        }

        &__description {
          color: rgba(#fff, 0.5);
        }
      }
    }
  }
}
