.wrap-up-card {
  width: 100%;
  max-width: rem(368px);
  background-color: var(--black);
  border-radius: rem(16px);
  padding: rem(16px) rem(16px) rem(32px);
  overflow: hidden;

  &__image {
    width: 100%;
    height: 0;
    padding-top: 100%;
    max-width: 100%;
    position: relative;

    img {
      @include absolute-center;
    }
  }

  &__pin {
    @include absolute-center;
    @include size(334px);
  }

  &__title {
    @include font-size(20px, 24px);

    margin-top: rem(24px);
    margin-bottom: rem(12px);
    color: var(--white);
    text-align: center;
    font-family: var(--heading-font-family);
    font-weight: 700;

    @include respond-to('mobile') {
      @include font-size(22px, 28px);
    }
  }

  &__description {
    @include font-size(12px, 18px);

    margin-bottom: 0;
    text-align: center;
    color: #9f9b90;
    font-family: var(--heading-font-family);
    text-transform: uppercase;
  }

  &__accent {
    color: var(--primary);
  }
}
