.kolona-celebs {
  @include flex(null, center, column);

  gap: rem(32px);

  @include respond-to('tablet') {
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  }

  &__person {
    @include flex(null, center, column);

    width: 100%;
    max-width: rem(368px);

    &-photo {
      margin-bottom: rem(16px);
    }

    &-name {
      @include flex(center, center);
      @include font-size(16px);

      margin-bottom: rem(12px);
      font-weight: var(--fw-700);
      text-align: center;
      background-color: #eaeaea;
      border-radius: rem(24px);
      padding: rem(12px) rem(24px);
      white-space: nowrap;

      @include respond-to('laptop') {
        @include font-size(20px);
      }
    }

    &-position {
      margin-bottom: 0;
      font-weight: var(--fw-500);
      text-align: center;
      color: var(--c-95);
    }
  }
}
