.select {
  @include font-size(16px);

  position: relative;
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  width: 100%;
  height: rem(48px);
  min-width: rem(104px);
  max-width: 100%;
  border-radius: rem(8px);
  background-color: #fff;
  cursor: pointer;

  @include respond-to('mobile') {
    max-width: rem(320px);
  }

  &::after {
    --size: #{rem(8px)};

    content: '';
    grid-area: select;
    justify-self: end;
    width: var(--size);
    height: var(--size);
    margin-right: rem(16px);
    margin-bottom: rem(4px);
    background-color: transparent;
    border-right: 1px solid rgba($black, 0.6);
    border-bottom: 1px solid rgba($black, 0.6);
    transform: rotateZ(45deg);
    pointer-events: none;

    @include respond-to('mobile') {
      --size: #{rem(10px)};

      margin-bottom: rem(8px);
    }
  }

  &--sm {
    @include font-size(16px, 16px);

    height: rem(32px);

    @include respond-to('laptop-l') {
      height: rem(40px);
    }
  }

  &__focus {
    position: absolute;
    inset: -1px;
    border: 1px solid #fff;
    border-radius: inherit;
    pointer-events: none;
    transition: border #{$animation};
  }

  &__default {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;

    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 rem(48px) 0 rem(12px);
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    grid-area: select;
    color: var(--black);

    @include respond-to('laptop-l') {
      padding: 0 rem(40px) 0 rem(16px);
    }

    &::-ms-expand {
      display: none;
    }

    &:focus + .select__focus {
      border-color: var(--primary);
    }
  }

  &--v2 {
    height: rem(48px);
    width: 100%;
    min-width: auto;
    border-radius: rem(24px);

    &::after {
      --size: #{rem(8px)};

      margin-right: rem(16px);
      margin-bottom: rem(4px);
      border-color: var(--black);
    }

    .select {
      &__default {
        padding: 0 rem(34px) 0 rem(12px);
        color: var(--black);
      }

      // stylelint-disable
      &__focus {
        border-width: 2px;
      }
      // stylelint-enable
    }
  }

  &--v3 {
    @include flex(null, center, column);

    gap: rem(8px);
    height: auto;
    min-width: auto;
    border-radius: 0;
    background-color: transparent;

    @include respond-to('laptop') {
      flex-direction: row;
    }

    &::after {
      content: none;
    }

    .select {
      &__wrapper {
        height: rem(40px);
        width: 100%;
        max-width: rem(328px);
        position: relative;
        border-radius: rem(8px);
        background-color: #fff;

        @include respond-to('laptop') {
          max-width: rem(140px);
        }
      }
      // stylelint-disable
      &__focus {
        border: 1px solid var(--cb);
        inset: 0;
      }
      // stylelint-enable

      &__arrow {
        @include size(rem(24px));

        position: absolute;
        right: rem(8px);
        top: rem(8px);
        font-size: rem(24px);
        transition: transform $animation;
        user-select: none;
        pointer-events: none;
      }

      &__default {
        padding: 0 rem(32px) 0 rem(8px);
        color: var(--black);
      }

      &__label {
        @include font-size(14px, 18px);

        color: var(--c-666);
      }
    }

    &:focus-within {
      .select__focus {
        border-color: var(--black);
      }

      .select__arrow {
        transform: rotate(180deg);
      }
    }
  }

  &--grey {
    @include font-size(16px, 16px);

    height: rem(48px);
    min-width: rem(112px);
    background-color: var(--f9);

    .select {
      &__default {
        padding: 0 rem(32px) 0 rem(24px);

        &:disabled {
          color: var(--c-9e);
        }
      }
    }
  }
}
