.header {
  width: 100%;
  height: var(--header-height);
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: #fff;

  @include respond-to('laptop-l') {
    border-bottom: 1px solid #e7e9ef;
  }

  &__wrapper {
    @include size(100%);
    @include flex(space-between, center);

    gap: rem(32px);
    border-bottom: 1px solid #e7e9ef;

    @include respond-to('laptop-l') {
      border: 0;
    }
  }

  &__container {
    height: 100%;
    position: relative;

    @include respond-to('max-laptop-l') {
      max-width: 100%;
      padding: 0 rem(24px);
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: rem(8px);

    @include respond-to('laptop-l') {
      gap: rem(32px);
    }
  }

  &__logo {
    display: block;
    width: rem(69px);
    height: rem(18px);

    @include respond-to('laptop-l') {
      width: rem(100px);
      height: rem(26px);
    }

    @include respond-to('4k') {
      width: rem(128px);
      height: rem(34px);
    }

    &-icon {
      display: block;
      width: 100%;
    }
  }

  &__nav {
    display: none;

    @include respond-to('laptop-l') {
      display: block;
    }
  }

  &__cta {
    @include flex(null, center);

    gap: rem(8px);
  }

  &__services {
    display: none;

    @include respond-to('laptop-l') {
      display: block;
    }
  }

  &__profile {
    @include flex(center);

    gap: rem(24px);
    position: relative;

    &-name {
      display: block;
    }

    &-close {
      display: none;
    }

    &-btn {
      @include reset-button;
      @include font-size(18px);

      position: relative;

      &.is-active {
        .header__profile {
          &-name {
            display: none;
          }

          &-close {
            display: flex;
          }
        }
      }
    }

    &-dropdown {
      @include size(100%, calc(100vh - var(--header-height)));

      display: none;
      position: absolute;
      top: var(--header-height);
      right: 0;
      z-index: 100;

      @include respond-to('mobile') {
        @include size(auto);
      }

      &.is-opened {
        display: block;
        animation: 200ms ease-in-out slide-up;
      }
    }
  }

  &__burger {
    @include respond-to('laptop-l') {
      display: none;
    }
  }

  &__login-btn {
    @include font-size(18px);
  }

  &__donate {
    &_laptop {
      display: none;

      @include respond-to('laptop-l') {
        display: inline;
      }
    }

    &_mobile {
      display: inline;

      @include respond-to('laptop-l') {
        display: none;
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(4px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
