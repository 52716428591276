.directions {
  &__title {
    @include respond-to('laptop') {
      margin-bottom: rem(56px);
    }
  }

  &__img {
    @include respond-to('laptop') {
      margin-bottom: rem(56px);
    }
  }
}
