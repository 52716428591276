.tc-donations {
  @include flex(center, center, column);

  width: 100%;
  gap: rem(24px);
  margin-top: rem(32px);

  @include respond-to('laptop') {
    display: none;
    padding: 0 rem(32px);
  }

  @include respond-to('laptop-l') {
    padding: 0 rem(40px);
  }

  &.is-desktop {
    display: none;

    @include respond-to('laptop') {
      display: flex;
    }
  }

  &__footer {
    display: none;

    @include respond-to('laptop') {
      @include flex(null, center, column);

      gap: rem(24px);
      margin-top: rem(32px);
    }

    @include respond-to('laptop-l') {
      margin-top: rem(40px);
    }
  }

  &__explainer {
    @include font-size(12px, 16px);

    color: var(--grey);
    text-align: center;

    a {
      text-decoration: underline;

      &:hover {
        color: var(--black);
        text-decoration: none;
      }
    }
  }
}
