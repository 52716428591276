.accordion {
  --vertical-space: #{rem(20px)};

  @include respond-to('laptop') {
    --vertical-space: #{rem(32px)};
  }

  border-top: 1px solid rgba($grey-9e, 0.24);
  padding-top: var(--vertical-space);

  &__trigger {
    position: relative;
  }

  &__mark {
    @include size(rem(32px));
    @include flex(center, center);

    position: absolute;
    top: 0;
    right: 0;
    transition: transform $animation;

    @include respond-to('laptop') {
      @include size(rem(40px));
    }
  }

  &__section {
    margin-top: 0;
    margin-bottom: var(--vertical-space);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid rgba($grey-9e, 0.24);
    background-color: transparent;

    .accordion {
      &__trigger {
        @include font-size(20px, 28px);

        margin-bottom: var(--vertical-space);
        padding: 0;
        padding-right: rem(56px);
        font-family: var(--font-family);
        font-weight: var(--fw-600);

        @include respond-to('laptop') {
          @include font-size(24px, 40px);
        }

        &:focus {
          color: var(--black);
        }
      }

      &__panel {
        .accordion {
          &__text {
            @include font-size(14px, 21px);

            font-family: var(--font-family);
            padding: 0;
            padding-bottom: var(--vertical-space);

            @include respond-to('laptop') {
              @include font-size(16px, 24px);
            }

            > a {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &.is-active {
      .accordion {
        &__mark {
          transform: rotate(45deg);
        }
      }
    }
  }

  .ac > .ac-header .ac-trigger::after {
    content: none;
  }

  .ac.is-active > .ac-header .ac-trigger::after {
    content: none;
  }
}
