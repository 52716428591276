.our-partners {
  margin-bottom: rem(32px);

  @include respond-to('laptop') {
    margin-bottom: rem(64px);
  }

  &__slider {
    padding-bottom: rem(80px);

    @include respond-to('mobile') {
      padding-bottom: 0;
    }
  }

  &__slide {
    @include flex(space-between, flex-start, column-reverse);

    padding: rem(20px) rem(24px);
    background-color: #fff;
    border-radius: rem(16px);
    gap: rem(8px);

    @include respond-to('tablet') {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: rem(24px);
    }

    @include respond-to('laptop') {
      padding: rem(40px) rem(48px);
    }
  }

  &__desc {
    width: 100%;
    max-width: rem(632px);
    font-family: var(--heading-font-family);
    color: var(--grey);
    line-height: 1.6;
  }

  &__pagination {
    margin-top: rem(20px);

    &.swiper-pagination-bullets,
    &.swiper-pagination-bullets.swiper-pagination-horizontal,
    &.swiper-pagination-custom,
    &.swiper-pagination-fraction {
      @include flex(flex-start, center);

      gap: 0;

      @include respond-to('mobile') {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: static;
        gap: rem(16px);
      }
    }

    &.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
      transform: scale(1);
    }

    &.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
      transform: scale(1);
    }

    &.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
      transform: scale(1);
    }
  }

  &__logo-wrapper {
    --width: #{rem(120px)};
    --height: #{rem(68px)};

    @include size(var(--width), var(--height));
    @include flex(center, center);

    @include respond-to('tablet') {
      --width: #{rem(180px)};
      --height: #{rem(100px)};
    }

    @include respond-to('laptop') {
      --width: #{rem(240px)};
      --height: #{rem(132px)};
    }

    @include respond-to('laptop-l') {
      --width: #{rem(360px)};
      --height: #{rem(200px)};
    }
  }

  &__logo {
    object-fit: contain;
  }
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  --width: #{rem(140px)};
  --height: #{rem(64px)};

  @include flex(center, center);
  @include size(var(--width), var(--height));

  flex: 1 0 auto;
  max-width: var(--width);
  margin: 0 rem(4px);
  padding: rem(8px) rem(16px);
  border-radius: rem(12px);
  background-color: #fff;
  opacity: 1;
  transition: background-color $animation;

  @include respond-to('tablet') {
    margin: 0;
  }

  @include respond-to('laptop-l') {
    --width: #{rem(268px)};
    --height: #{rem(80px)};

    padding: rem(12px) rem(24px);
    border-radius: rem(16px);
  }

  & > img {
    max-height: 100%;
  }

  &.swiper-pagination-bullet-active {
    background-color: var(--primary);
  }
}
