.vacation-companies {
  width: 100%;

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(8px);

    @include respond-to('tablet') {
      grid-template-columns: repeat(3, 1fr);
    }

    @include respond-to('laptop') {
      grid-template-columns: repeat(4, 1fr);
    }

    @include respond-to('laptop-l') {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__logo {
    @include size(100%, rem(64px));
    @include flex(center, center);

    padding: rem(16px);
    border-radius: rem(32px);
    border: 1px solid var(--c4);
    transition: box-shadow $animation;

    @include respond-to('laptop') {
      height: rem(104px);
      padding: rem(24px);
      border-radius: rem(52px);
    }

    &:hover {
      text-decoration: none;
      box-shadow: 0 4px 22px rgb(0 0 0 / 6%);
    }

    &-join {
      @include flex(center, center);
      @include font-size(14px, 21px);

      gap: rem(8px);
      border-style: dashed;
      background-color: #f5f5f5;
      color: #434343;

      &:hover,
      &:visited {
        color: #434343;
      }
    }

    &:last-of-type:not(:nth-child(even)) {
      @include respond-to('max-tablet') {
        grid-column: 1 / 3;
      }
    }
  }

  &__img {
    @include size(100%);

    object-fit: contain;
  }

  &--grid {
    .vacation-companies {
      &__list {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
    }
  }
}
