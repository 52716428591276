.not-found-page {
  &__title {
    margin-bottom: rem(24px);
  }

  &__cta {
    @include font-size(16px, 24px);

    margin-bottom: rem(40px);

    @include respond-to('laptop') {
      @include font-size(24px, 32px);

      margin-bottom: rem(56px);
    }
  }

  &__img {
    width: 100%;
    max-width: rem(800px);
    margin-bottom: rem(64px);
  }
}
