.founders {
  &__content {
    @include flex(null, center, column);

    width: 100%;
    padding: rem(40px) 0;
    background-color: var(--f9);

    @include respond-to('mobile') {
      border-radius: rem(40px);
    }

    @include respond-to('laptop') {
      padding: rem(96px) 0;
    }
  }

  &__container {
    padding: 0;

    @include respond-to('mobile') {
      padding: 0 rem(16px);
    }
  }

  &__title {
    padding: 0 rem(16px);
  }

  &__description {
    padding: 0 rem(16px);
  }

  &__slider {
    width: 100%;

    @include respond-to('laptop') {
      margin-bottom: rem(48px);
    }
  }

  &__slide {
    --size: #{rem(100px)};

    width: var(--size);

    @include respond-to('laptop') {
      --size: #{rem(174px)};
    }
  }

  &__partners {
    @include flex(center);

    gap: rem(24px);
    flex-wrap: wrap;
    margin-top: rem(16px);

    @include respond-to('laptop') {
      margin-top: rem(48px);
    }
  }

  &__partner {
    @include size(rem(96px), rem(48px));
    @include flex(center);

    @include respond-to('laptop') {
      @include size(rem(160px), rem(68px));
    }
  }

  &__logo {
    object-fit: contain;
  }
}
