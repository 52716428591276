.user-profile-logout {
  @include reset-button;
  @include flex(null, center);
  @include font-size(16px, 24px);

  gap: rem(16px);
  width: 100%;
  padding: rem(24px) 0;
  border-right: 4px solid transparent;
  position: relative;
  font-family: var(--heading-font-family);
  font-weight: var(--fw-200);
  transition:
    color $animation,
    background-color $animation;

  @include respond-to('laptop-l') {
    @include font-size(18px, 32px);
  }

  &:hover {
    color: var(--black);

    @at-root html:not(.is-touch) & {
      &::after {
        opacity: 1;
      }
    }
  }

  &::after {
    @include size(rem(4px), rem(32px));

    content: '';
    display: block;
    background-color: var(--primary);
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity $animation;
  }

  &__icon {
    @include size(rem(16px));
  }

  &--popup {
    @include font-size(10px, 16px);

    width: auto;
    gap: rem(8px);
    margin-left: auto;
    margin-right: auto;
    padding: rem(8px) 0 0;

    &:hover {
      color: var(--black);

      &::after {
        content: none;
      }
    }
  }
}
