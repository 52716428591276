.payment-amount-selection {
  @include flex(center, center, column);

  width: 100%;
  max-width: rem(320px);
  gap: rem(16px);
  margin-left: auto;
  margin-right: auto;

  @include respond-to('tablet') {
    gap: rem(24px);
  }

  @include respond-to('laptop-l') {
    max-width: rem(480px);
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: rem(8px);

    @include respond-to('tablet') {
      gap: rem(16px);
    }

    @include respond-to('laptop-l') {
      gap: rem(24px);
    }
  }

  &__btn {
    @include reset-button;
    @include font-size(18px);

    height: rem(48px);
    min-width: rem(84px);
    flex: 1 0 auto;
    border-radius: rem(24px);
    background-color: #fff;
    border: 2px solid rgba($black, 0.1);
    cursor: pointer;
    color: var(--black);
    transition:
      border-color $animation,
      background-color $animation;

    @include respond-to('laptop-l') {
      @include font-size(24px);

      height: rem(64px);
      border-radius: rem(32px);
    }

    &:hover {
      border-color: var(--primary);
    }

    &.is-active {
      background-color: rgba($primary, 0.2);
      border-color: var(--primary);
      font-weight: var(--fw-700);
    }
  }

  &__controls {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: rem(16px);

    @include respond-to('laptop-l') {
      gap: rem(24px);
    }
  }

  &__input-wrapper {
    @include flex(center, center);

    gap: rem(4px);
    height: rem(48px);
    padding: 0 rem(16px);
    border-radius: rem(24px);
    background-color: #fff;
    border: 2px solid rgba($black, 0.1);
    transition:
      border-color $animation,
      background-color $animation;

    @include respond-to('laptop-l') {
      height: rem(64px);
      border-radius: rem(32px);
    }

    &:focus-within {
      border-color: var(--primary);
    }

    &::before,
    &::after {
      @include font-size(18px);

      display: block;
      font-weight: var(--fw-700);

      @include respond-to('laptop-l') {
        @include font-size(24px, 32px);
      }
    }

    &.is-before {
      &::before {
        content: attr(data-currency);
      }
    }

    &.is-after {
      &::after {
        content: attr(data-currency);
      }
    }
  }

  &__select {
    min-width: auto;
  }

  &__input {
    @include font-size(18px, 24px);

    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    font-weight: var(--fw-700);
    text-align: center;

    @include respond-to('laptop-l') {
      @include font-size(24px, 32px);
    }
  }

  &--targeted-banner-modal {
    gap: rem(16px);
    margin-bottom: rem(32px);

    .payment-amount-selection {
      &__buttons {
        gap: rem(8px);
      }

      &__btn {
        @include font-size(18px);

        height: rem(48px);
        border-color: rgba($black, 0.1);

        &.is-active {
          background-color: var(--white);
          border-width: 4px;
        }
      }

      &__controls {
        grid-template-columns: rem(96px) 1fr;
      }

      &__input-wrapper {
        height: rem(48px);
        border-color: rgba($black, 0.1);

        &::before,
        &::after {
          @include font-size(18px);
        }
      }

      &__input {
        @include font-size(18px);
      }
    }
  }
}
