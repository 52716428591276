.modal-dialog {
  @include flex(center, center);

  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  transition: transform 500ms ease-in-out;

  @include respond-to('mobile') {
    @include size(auto, 100%);

    max-width: rem(480px);
  }

  @include respond-to('laptop-l') {
    max-width: rem(640px);
  }
}

.slide-in-down .modal-dialog {
  animation-duration: 0.75s;
  animation-fill-mode: both;
  animation-name: slide-in-down;
}

.slide-out-up .modal-dialog {
  animation-duration: 0.75s;
  animation-fill-mode: both;
  animation-name: slide-out-up;
}

.modal {
  height: calc(100% - #{var(--header-height)});
  position: fixed;
  inset: var(--header-height) 0 0 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  @include respond-to('mobile') {
    top: 0;
    height: 100%;
  }

  &.fade {
    .modal-dialog {
      transform: translate(0, -50px);
    }
  }

  &.show {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }
}

.modal-open {
  overflow: hidden;

  .modal {
    overflow: hidden auto;
  }
}

.modal-content {
  @include size(100%);

  position: relative;
  padding: rem(24px) rem(16px);
  background-color: #fff;
  outline: 0;

  @include respond-to('mobile') {
    height: auto;
    padding: rem(40px) rem(16px);
    border-radius: rem(10px);
  }

  @include respond-to('tablet') {
    border-radius: rem(20px);
    padding: rem(32px) rem(40px);
  }

  @include respond-to('laptop-l') {
    border-radius: rem(40px);
    padding: rem(40px) rem(40px) 0;
  }
}

.modal-backdrop {
  position: fixed;
  inset: 0;
  z-index: 1040;
  background-color: rgba($black, 0.64);
  backdrop-filter: blur(20px);
  opacity: 0.5;

  @include respond-to('mobile') {
    &.fade {
      opacity: 0;

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    &.show {
      opacity: 0.5;
    }
  }
}

.close {
  @include reset-button;
}

.modal-header {
  position: relative;
  padding-right: rem(32px);

  .close {
    @include size(rem(32px));

    position: absolute;
    right: 0;
    top: 0;

    @include respond-to('mobile') {
      @include size(rem(28px));
    }
  }
}

.modal-title {
  @include font-size(20px, 28px);

  margin: 0;

  @include respond-to('mobile') {
    @include font-size(24px, 32px);
  }
}

.modal-text {
  @include font-size(14px, 22px);

  margin: rem(16px) 0 rem(40px);
  color: var(--grey);
}

.modal-body {
  position: relative;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.fade {
  opacity: 0;
  transition: opacity $animation;

  &.show {
    opacity: 1;
  }
}

@keyframes slide-in-down {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-up {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}
