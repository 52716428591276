.wrap-up {
  &__container {
    @include flex(flex-start, center, column);

    gap: rem(30px);
    width: 100%;
    max-width: rem(968px);
    margin-left: auto;
    margin-right: auto;

    @include respond-to('laptop') {
      display: grid;
      grid-template-areas:
        'content card'
        'info card';
    }
  }

  &__content {
    @include respond-to('laptop') {
      grid-area: content;
    }
  }

  &__lead {
    @include font-size(24px, 34px);

    font-weight: 700;
  }

  &__text {
    @include font-size(18px, 24px);

    font-weight: 500;
  }

  &__info {
    width: 100%;

    @include respond-to('laptop') {
      grid-area: info;
    }
  }

  &__cta {
    width: 100%;
    margin-top: rem(32px);
    margin-bottom: rem(32px);
  }

  &__flow {
    @include flex(null, stretch, column);

    gap: rem(16px);

    @include respond-to('mobile') {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: rem(32px);
    }
  }

  &__step {
    @include flex(flex-start, center);

    gap: rem(16px);
    border-radius: rem(16px);
    background-color: var(--f9);
    padding: rem(32px);

    @include respond-to('mobile') {
      flex-direction: column;
      padding: rem(32px) rem(16px) rem(16px);
    }
  }

  &__step-icon {
    @include size(rem(40px));
    @include flex(center, center);

    background-color: var(--primary);
    border-radius: 50%;

    svg {
      @include size(rem(24px));
    }
  }

  &__step-text {
    @include font-size(14px, 16px);

    text-align: center;
    font-weight: 500;
  }

  &__card {
    width: 100%;
    max-width: rem(368px);

    @include respond-to('mobile') {
      width: rem(368px);
    }

    @include respond-to('laptop') {
      grid-area: card;
    }
  }

  &__banner {
    @include flex(flex-start, center, column);

    gap: rem(20px);
    margin-top: rem(70px);
    border-radius: rem(16px);
    background-color: var(--f9);
    padding: rem(32px) rem(16px);

    @include respond-to('mobile') {
      padding: rem(40px) rem(40px) rem(40px) rem(80px);
      place-items: center;
    }

    @include respond-to('laptop-l') {
      display: grid;
      grid-template-columns: 1fr rem(272px) rem(356px);
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__banner-socials {
    @include flex(space-between, center, column);

    gap: rem(20px);

    @include respond-to('mobile') {
      flex-direction: row;
    }
  }

  &__banner-title {
    @include font-size(24px, 32px);

    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    max-width: rem(332px);

    @include respond-to('laptop-l') {
      text-align: left;
      justify-self: flex-start;
    }
  }

  &__banner-text {
    @include font-size(18px, 24px);

    font-weight: 500;
    margin-bottom: 0;
    text-align: center;

    @include respond-to('laptop-l') {
      text-align: left;
    }
  }
}
