.nav {
  &__list {
    @include reset-list;
    @include flex(null, null, column);

    gap: rem(12px);

    @include respond-to('laptop-l') {
      align-items: center;
      flex-direction: row;
      gap: rem(20px);
    }
  }

  &__item {
    line-height: 1;
  }

  &__link {
    @include font-size(14px, 14px);

    display: block;
    font-weight: 500;

    &:hover {
      color: var(--black);
    }
  }

  &--mobile {
    .nav {
      &__list {
        gap: rem(28px);
      }

      &__item {
        @include flex(null, center);

        gap: rem(12px);
      }

      &__link {
        @include font-size(16px, 22px);

        &:hover {
          text-decoration: none;
        }
      }

      &__icon {
        @include size(rem(18px));
        @include font-size(18px);
      }
    }
  }
}
