.about-hero {
  overflow: hidden;

  &__container {
    position: relative;
  }

  &__col {
    @include flex(flex-start, flex-start, column);

    width: 100%;
    max-width: rem(1010px);
  }

  &__content {
    width: 100%;

    @include respond-to('tablet') {
      max-width: rem(480px);
    }

    @include respond-to('laptop') {
      max-width: rem(600px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(680px);
    }
  }

  &__circle {
    display: none;

    @include respond-to('tablet') {
      --size: #{rem(240px)};

      @include size(var(--size));
      @include flex(center, center, column);

      position: absolute;
      right: rem(16px);
      bottom: 0;
      background-color: #fff8c1;
      border-radius: 50%;
      transform: rotate(-16deg);
      gap: rem(24px);
    }

    @include respond-to('laptop') {
      --size: #{rem(320px)};

      gap: rem(40px);
    }

    @include respond-to('laptop-l') {
      --size: #{rem(448px)};

      gap: rem(56px);
    }

    @include respond-to('4k') {
      --size: #{rem(560px)};

      gap: rem(72px);
    }

    &-text {
      @include respond-to('tablet') {
        @include font-size(40px);

        margin-bottom: 0;
        font-family: var(--heading-font-family);
        font-weight: var(--fw-700);
      }

      @include respond-to('laptop') {
        @include font-size(56px);
      }

      @include respond-to('laptop-l') {
        @include font-size(80px);
      }

      @include respond-to('4k') {
        @include font-size(98px);
      }
    }

    &-logo {
      @include respond-to('tablet') {
        @include font-size(32px);

        margin-bottom: 0;
      }

      @include respond-to('laptop') {
        @include font-size(40px);
      }

      @include respond-to('laptop-l') {
        @include font-size(56px);
      }

      @include respond-to('4k') {
        @include font-size(72px);
      }

      svg {
        height: 1em;
        display: inline-block;
      }
    }
  }
}
