.motivation {
  color: var(--white);
  background-color: var(--black);

  &__grid {
    @include respond-to('laptop-l') {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: rem(70px);
      max-width: none;
    }
  }

  &__left {
    margin-bottom: rem(32px);
    flex-shrink: 0;
    width: 100%;

    @include respond-to('laptop-l') {
      margin-bottom: 0;
      max-width: rem(730px);
    }

    @include respond-to('4k') {
      max-width: rem(970px);
    }
  }

  &__right {
    @include respond-to('laptop-l') {
      width: rem(368px);
      flex-shrink: 0;
    }
  }
}
