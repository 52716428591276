.kolona-report-text {
  &__content {
    font-size: rem(18px);
    line-height: 1.4;
  }

  &__grid {
    display: grid;
    grid-gap: rem(16px);

    &--2 {
      @include respond-to('tablet') {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond-to('laptop') {
        grid-gap: rem(32px);
      }
    }

    &--3 {
      @include respond-to('tablet') {
        grid-template-columns: repeat(3, 1fr);
      }

      @include respond-to('laptop') {
        grid-gap: rem(12px);
      }
    }
  }

  &__title {
    font-size: rem(18px);
    margin-bottom: rem(16px);

    @include respond-to('laptop') {
      text-align: center;
      margin-bottom: rem(24px);
      font-size: rem(24px);
    }
  }

  &__section {
    margin: 0 auto;

    &--short {
      max-width: rem(768px);
    }

    &--long {
      max-width: rem(1080px);
    }

    &:not(:last-child) {
      margin-bottom: rem(32px);

      @include respond-to('laptop') {
        margin-bottom: rem(74px);
      }
    }

    @include respond-to('laptop') {
      padding-top: 20px;
    }
  }
}
