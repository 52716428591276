@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.wrapper {
  @include flex(null, null, column);
}

.filter {
  margin-bottom: rem(16px);
}

.transactions {
  @include flex(null, null, column);

  gap: rem(24px);
  padding: rem(16px);
  background-color: var(--white);
  border-radius: rem(16px);

  @include respond-to('laptop') {
    padding: rem(24px);
  }
}
