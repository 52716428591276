@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.transaction {
  display: grid;
  grid-template-columns: 1fr rem(96px);
  gap: rem(4px) rem(16px);
  padding: rem(8px) 0;
  border-bottom: 1px solid var(--c4);

  @include respond-to('laptop') {
    grid-template-columns: rem(180px) rem(88px) 1fr rem(132px);
    gap: rem(32px);
  }
}

.text {
  @include font-size(14px, 16px);

  margin-bottom: 0;

  @include respond-to('laptop') {
    @include font-size(14px, 21px);
  }
}

.textLg {
  font-weight: 700;

  @include respond-to('laptop') {
    @include font-size(16px, 21px);
  }
}

.alignRight {
  text-align: right;
}

.greyText {
  color: var(--c4);
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--black);
  }
}
