.copy-link-to-clipboard {
  display: flex;
  align-items: stretch;
  gap: rem(20px);
  height: rem(48px);
  padding: rem(10px) rem(24px);
  background-color: rgba($black, 0.1);
  overflow: hidden;
  border-radius: rem(24px);
  position: relative;
  transition: background-color $animation;

  @include respond-to('mobile') {
    height: rem(64px);
    border-radius: rem(32px);
    padding: rem(16px) rem(24px);
  }

  @include respond-to('laptop-l') {
    height: rem(88px);
    border-radius: rem(44px);
    padding: rem(18px) rem(32px);
  }

  &__content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-right: rem(16px);
    border-right: 2px solid rgba($black, 0.6);
    position: relative;
  }

  &__value {
    @include font-size(16px, 16px);

    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    color: var(--black);
    user-select: none;
    pointer-events: none;
    transition:
      opacity $animation 200ms,
      visibility $animation 200ms;

    @include respond-to('mobile') {
      @include font-size(20px, 20px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 24px);
    }
  }

  &__btn {
    width: rem(24px);
    height: rem(28px);
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    @include respond-to('mobile') {
      width: rem(28px);
      height: rem(32px);
    }

    @include respond-to('laptop-l') {
      width: rem(46px);
      height: rem(52px);
    }
  }

  &__icon {
    display: block;
    height: 100%;
  }

  &__success {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity $animation 0,
      visibility $animation 0;

    &-message {
      @include font-size(16px, 16px);

      margin-bottom: 0;
      font-weight: var(--fw-700);
      text-align: center;
      color: #fff;

      @include respond-to('mobile') {
        @include font-size(20px, 20px);
      }

      @include respond-to('laptop-l') {
        @include font-size(24px, 24px);
      }
    }
  }

  &.is-copied {
    background-color: var(--secondary);

    .copy-link-to-clipboard {
      &__value {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0;
      }

      &__success {
        opacity: 1;
        visibility: visible;
        transition-delay: 200ms;
      }
    }
  }
}
