.empty {
  @include flex(null, center, column);

  &__logo-wrapper {
    --size: #{rem(100px)};

    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    margin-bottom: rem(24px);

    @include respond-to('tablet') {
      --size: #{rem(200px)};

      margin-bottom: rem(40px);
    }
  }

  &__message {
    width: 100%;
    max-width: rem(884px);
    margin-bottom: 0;
    font-size: rem(16px);
    text-align: center;

    @include respond-to('mobile') {
      font-size: rem(24px);
    }

    @include respond-to('tablet') {
      font-size: rem(32px);
    }
  }
}
