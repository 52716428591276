.telegram-cta {
  width: 100%;

  &__container {
    @include flex(null, center, column);

    gap: rem(24px);
    align-items: center;

    @include respond-to('laptop-l') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__message-wrapper {
    @include flex(null, center);

    gap: rem(16px);
  }

  &__message {
    @include font-size(14px, 20px);

    margin-bottom: 0;
    color: var(--c-999);

    @include respond-to('tablet') {
      @include font-size(16px, 24px);
    }

    @include respond-to('4k') {
      @include font-size(20px, 30px);
    }
  }

  &__img-wrapper {
    @include size(rem(24px));

    flex: 1 0 auto;

    @include respond-to('tablet') {
      @include size(rem(32px));
    }

    @include respond-to('laptop') {
      @include size(rem(40px));
    }

    @include respond-to('laptop-l') {
      @include size(rem(56px));
    }

    @include respond-to('4k') {
      @include size(rem(72px));
    }
  }

  &__bot {
    @include font-size(12px);

    margin-top: rem(4px);
    text-transform: lowercase;
    color: var(--c-999);
    text-align: left;

    @include respond-to('tablet') {
      @include font-size(14px);
    }
  }
}
