.kolona-help {
  background-color: var(--black);
  position: relative;
  overflow: hidden;
  border-radius: rem(48px);
  padding: rem(64px) 0;

  @include respond-to('laptop') {
    padding: rem(136px) 0 rem(96px);
  }

  &__container {
    @include flex(space-between, null, column);

    position: relative;
    gap: rem(56px);

    @include respond-to('tablet') {
      flex-direction: row;
    }
  }

  &__title {
    @include flex(null, center, column);
    @include font-size(32px, 40px);

    width: 100%;
    gap: rem(24px);
    margin-bottom: 0;
    font-family: var(--heading-font-family);
    text-align: center;

    @include respond-to('tablet') {
      @include font-size(40px, 48px);

      max-width: rem(400px);
      flex-direction: column;
      align-items: flex-start;
      gap: rem(40px);
      text-align: left;
    }

    @include respond-to('laptop') {
      @include font-size(48px, 56px);
    }
  }

  &__icon {
    @include size(rem(66px), rem(48px));

    display: inline-block;

    @include respond-to('tablet') {
      @include size(rem(75px), rem(54px));
    }

    svg {
      height: 100%;
    }
  }

  &__response {
    @include respond-to('tablet') {
      max-width: rem(368px);
    }

    &-description {
      @include font-size(12px, 16px);

      margin-bottom: 0;
      color: rgba($white, 0.5);
    }
  }

  &__loop {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__gallery {
    display: grid;
    grid-template-areas:
      'one one one one'
      'two two three three'
      'four five six six';
    gap: rem(16px);
    width: 100%;
    max-width: rem(448px);
    margin-top: rem(56px);
    margin-bottom: rem(56px);

    @include respond-to('tablet') {
      grid-template-areas:
        'two two three three one one'
        'four five six six one one';
      max-width: none;
    }

    @include respond-to('laptop') {
      gap: rem(24px);
    }

    @include respond-to('4k') {
      gap: rem(32px);
    }
  }

  &__photo {
    @include flex(center, center);

    overflow: hidden;
    border-radius: rem(8px);

    &:nth-child(1) {
      grid-area: one;
    }

    &:nth-child(2) {
      grid-area: two;
    }

    &:nth-child(3) {
      grid-area: three;
    }

    &:nth-child(4) {
      grid-area: four;
    }

    &:nth-child(5) {
      grid-area: five;
    }

    &:nth-child(6) {
      grid-area: six;
    }
  }
}
