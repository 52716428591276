.greetings {
  &__list {
    margin-top: rem(24px);
    overflow: hidden;

    @include respond-to('mobile') {
      margin-top: rem(40px);
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: rem(20px);
    }
  }

  &__more {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-top: rem(48px);

    &-btn {
      @include font-size(24px, 24px);

      display: flex;
      gap: rem(16px);
      padding: 0;
      background-color: transparent;
      border: 0;
      color: var(--black);
      cursor: pointer;
      transition: color $animation;

      &::after {
        @include size(#{rem(16px)});

        content: '';
        display: block;
        margin-bottom: rem(8px);
        background-color: transparent;
        border-right: 1px solid $black;
        border-bottom: 1px solid $black;
        transform: rotateZ(45deg);
        transform-origin: center center;
      }

      &:focus {
        outline: none;
        border-color: var(--primary);
      }

      &:hover {
        @at-root html:not(.is-touch) & {
          color: var(--primary);

          .greetings__more-btn-text {
            &::before {
              transform: translateY(1px);
            }
          }
        }
      }

      &.is-expanded {
        color: var(--black);

        &::after {
          margin-bottom: 0;
          margin-top: rem(8px);
          transform: rotateZ(-135deg);
        }
      }

      &-text {
        position: relative;
        padding: 0 rem(4px);

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: -1;
          background-color: var(--black);
          transform: translateY(100%);
          transition: transform $animation;
        }
      }
    }
  }

  &__cta {
    margin-top: rem(40px);

    @include respond-to('mobile') {
      margin-top: rem(80px);
    }

    @include respond-to('laptop-l') {
      margin-top: rem(104px);
    }
  }
}
