@import './request-flow';

.ra-hero {
  position: relative;
  overflow: hidden;

  &__container {
    position: relative;

    &::before {
      @include size(rem(140px));

      content: '';
      display: block;
      border-radius: 50%;
      border: 4px solid var(--primary);
      position: absolute;
      right: -8%;
      top: rem(-40px);
      z-index: 0;

      @include respond-to('mobile') {
        right: 25%;
      }

      @include respond-to('tablet') {
        right: 20%;
      }

      @include respond-to('tablet') {
        right: 24%;
      }

      @include respond-to('laptop') {
        @include size(rem(200px));

        top: rem(-60px);
        border-width: 8px;
        right: 20%;
      }

      @include respond-to('laptop-l') {
        @include size(rem(286px));

        right: 14%;
      }
    }
  }

  &__title {
    @include respond-to('laptop') {
      margin-bottom: rem(56px);
    }
  }

  &__gap {
    margin-bottom: rem(56px);

    @include respond-to('laptop') {
      margin-bottom: rem(80px);
    }
  }
}

.ra-cta {
  @include flex(null, null, column);

  gap: rem(32px);
  padding: rem(48px) rem(16px);
  background-color: #f2f2f2;
  border-radius: rem(16px);

  @include respond-to('laptop') {
    padding: rem(48px);
  }
}
