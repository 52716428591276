.subscription-history {
  width: 100%;

  &__title {
    @include font-size(20px, 24px);

    margin-bottom: rem(24px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
  }

  &__table {
    width: 100%;
  }

  &__col {
    @include font-size(14px, 21px);

    &:nth-child(1),
    &:nth-child(2) {
      font-weight: var(--fw-700);
    }

    &:nth-child(2),
    &:nth-child(4) {
      text-align: right;

      @include respond-to('tablet') {
        text-align: left;
      }
    }

    &:not(:first-child) {
      white-space: nowrap;
    }
  }

  &__head {
    display: none;
    background-color: var(--f5);
    padding: rem(4px) 0;
    border-radius: rem(8px);

    @include respond-to('tablet') {
      display: block;
    }

    .subscription-history {
      &__col {
        @include font-size(14px, 20px);

        font-weight: var(--fw-400);
        color: var(--c-9e);
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem(8px);
    padding: 0 rem(16px) rem(8px);
    border-bottom: 1px solid var(--c4);

    @include respond-to('tablet') {
      padding: 0 rem(16px);
      border-bottom: none;
      grid-template-columns: 1fr repeat(3, rem(106px));
      gap: rem(40px);
    }

    &:not(:last-child) {
      margin-bottom: rem(8px);
    }
  }

  &__status {
    @include font-size(12px, 20px);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem(2px) rem(8px);
    border-radius: rem(4px);
    background-color: #f3cdcd;

    @include respond-to('tablet') {
      padding: rem(4px) rem(12px);
      border-radius: rem(8px);
    }

    &.is-paid {
      background-color: #29D645;
    }
  }

  &__show-more {
    margin-top: rem(32px);
  }
}
