.subscription-info {
  width: 100%;
  border-radius: rem(16px);
  border: 1px solid var(--c4);
  padding: rem(24px);

  &__title {
    @include font-size(18px, 24px);

    font-weight: var(--fw-700);

    &--lg {
      @include font-size(20px, 24px);

      font-family: var(--heading-font-family);
    }
  }

  &__text {
    @include font-size(14px, 21px);
  }

  &__icon {
    @include size(rem(40px));

    border-radius: 50%;
    background-color: var(--primary);
    flex: 0 0 auto;
  }

  &__content {
    max-width: rem(279px);
  }

  &__button {
    width: 100%;

    @include respond-to('tablet') {
      width: auto;
      height: 40px;
      font-size: rem(14px);
      margin-top: rem(32px);
    }
  }

  &--two-column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: rem(32px);
    max-width: none;
  }
}
