.js-modal-wrapper {
  @include size(100%);

  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  display: none;

  &.is-opened {
    opacity: 1;
    visibility: visible;
    overflow: hidden auto;
    display: flex;

    // @at-root html:not(.is-touch) & {
    //   padding-right: 15px;
    // }
  }
}

.js-modal {
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  display: none;
  animation-duration: 240ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  &.is-opened {
    opacity: 1;
    visibility: visible;
    overflow: hidden auto;
    display: block;
    animation-name: modal-fade-in;
  }
}

.js-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 999;
  display: none;
  animation-duration: 240ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  &.is-opened {
    display: block;
    animation-name: overlay-fade-in;
  }

  &.overlay-fade-out {
    animation-name: overlay-fade-out;
  }
}

@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes overlay-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes modal-fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}
