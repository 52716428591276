.user-profile-page {
  --gap: #{rem(48px)};
  --aside-width: #{rem(268px)};

  max-width: rem(1168px);
  margin: 0 auto;

  @include respond-to('laptop') {
    display: grid;
    grid-template-columns: var(--aside-width) calc(100% - (var(--aside-width) + var(--gap)));
    gap: var(--gap);
  }

  @include respond-to('laptop-l') {
    --gap: #{rem(32px)};
  }

  &__aside {
    display: none;

    @include respond-to('laptop') {
      display: block;
    }
  }

  &__header {
    @include flex(space-between, null, column);

    gap: rem(16px);

    @include respond-to('4k') {
      flex-direction: row;
      align-items: center;
    }
  }

  &__title {
    @include font-size(32px, 48px);

    margin-bottom: 0;

    @include respond-to('laptop-l') {
      @include font-size(40px, 56px);
    }
  }

  &__sub-title {
    @include font-size(14px, 20px);

    margin-bottom: 0;
  }

  &__section-title {
    @include font-size(20px, 24px);

    margin-bottom: rem(32px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-400);
  }

  &__body {
    padding-top: rem(32px);

    @include respond-to('laptop-l') {
      padding-top: rem(16px);
    }
  }

  &__donations {
    margin-bottom: rem(28px);
    overflow: auto hidden;
  }

  &__table {
    width: 100%;
    min-width: rem(880px);
  }

  &__settigns {
    width: 100%;
    max-width: rem(440px);
  }

  &__subtitle {
    @include font-size(20px, 24px);

    margin-bottom: rem(24px);
    font-weight: var(--fw-400);
  }

  &__submit {
    margin-top: rem(24px);
  }

  &__birthday {
    margin-top: rem(24px);
  }

  &__grid {
    width: 100%;
    gap: rem(16px);
    display: grid;

    @include respond-to('tablet') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('laptop-l') {
      gap: rem(16px);
    }
  }

  &__greeting {
    @include font-size(20px, 24px);

    margin-bottom: rem(64px);
    font-family: var(--heading-font-family);
  }
}
