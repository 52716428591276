.about-founders {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: rem(32px) rem(24px);
    align-items: flex-start;

    @include respond-to('mobile') {
      grid-template-columns: repeat(4, 1fr);
      column-gap: rem(56px);
    }

    @include respond-to('tablet') {
      grid-template-columns: repeat(6, 1fr);
      row-gap: rem(40px) rem(24px);
    }

    @include respond-to('laptop') {
      grid-template-columns: repeat(4, 1fr);
      row-gap: rem(56px) rem(88px);
    }

    @include respond-to('laptop-l') {
      grid-template-columns: repeat(5, 1fr);
      row-gap: rem(72px) rem(72px);
    }

    @include respond-to('4k') {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__card {
    justify-self: center;
  }
}
