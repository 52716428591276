.info-hint {
  @include reset-button;
  @include flex(null, center);
  @include font-size(16px);

  @include respond-to('laptop-l') {
    @include font-size(20px);
  }

  &__icon {
    @include size(rem(20px), rem(20px));

    margin-right: rem(8px);

    @include respond-to('laptop-l') {
      @include size(rem(24px), rem(24px));
    }
  }
}
