.alert {
  position: fixed;
  top: rem(120px);
  right: rem(32px);
  z-index: 100;
  background-color: #fff5b3;
  padding: rem(12px) rem(48px) rem(12px) rem(24px);
  border-radius: rem(10px);
  width: calc(100% - rem(64px));
  max-width: rem(420px);

  &__message {
    @include font-size(12px, 20px);

    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }

  &__close {
    @include reset-button;

    position: absolute;
    right: rem(24px);
    top: rem(14px);
  }
}
