.kolo-cannes {
  &__title {
    line-height: 1.2;
  }

  &__section {
    padding: rem(40px) 0;
    position: relative;
    overflow: hidden;

    @include respond-to('laptop') {
      padding: rem(80px) 0;
    }

    @include respond-to('laptop-l') {
      padding: rem(96px) 0;
    }
  }

  &__mb-space {
    margin-bottom: rem(48px);
  }

  &__btn {
    @media screen and (max-width: 37.45em) {
      width: 100%;
      max-width: rem(280px);
    }
  }

  &__button-group {
    position: relative;
  }
}
