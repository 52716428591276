.kolossus-explainer {
  background-color: var(--f9);

  @include respond-to('laptop-l') {
    background-color: transparent;
  }

  &__container {
    width: 100%;
    background-color: var(--f9);

    @include respond-to('laptop-l') {
      padding: rem(56px) 0;
      border-radius: rem(56px);
    }
  }

  &__content {
    @include flex(null, center, column);

    width: 100%;
    max-width: rem(1024px);
    margin-left: auto;
    margin-right: auto;
  }

  &__flow {
    @include flex(space-between, center);

    position: relative;
    width: 100%;
    margin-bottom: rem(40px);

    @include respond-to('laptop') {
      justify-content: center;
      margin-bottom: rem(64px);
      grid-column-gap: rem(64px);
    }
  }

  &__step {
    --size: #{rem(64px)};

    @include flex(center, center);
    @include size(var(--size));

    position: relative;
    z-index: 1;
    padding: rem(8px);
    border-radius: 50%;
    background-color: #f0f0f0;

    @include respond-to('laptop') {
      --size: 12.8vw;

      padding: rem(16px);
    }

    @include respond-to('4k') {
      --size: #{rem(184px)};
    }
  }

  &__connector {
    @include size(70%, 1px);

    position: absolute;
    inset: 50% auto auto 50%;
    z-index: 0;
    background-color: rgba($black, 0.2);
    transform: translate(-50%, 0);
  }

  &__text {
    @include font-size(10px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);

    @include respond-to('laptop') {
      font-size: 2vw;
    }

    @include respond-to('4k') {
      @include font-size(26px);
    }
  }

  &__rules {
    @include flex(center, flex-start);

    width: 100%;
    margin-bottom: rem(32px);
    grid-gap: rem(24px);
    flex-wrap: wrap;

    @include respond-to('mobile') {
      align-items: flex-start;
      grid-gap: rem(32px);
      margin-bottom: rem(64px);
      flex-wrap: nowrap;
    }

    @include respond-to('laptop') {
      grid-gap: rem(92px);
    }
  }

  &__rule {
    @include flex(null, center, column);

    width: 100%;
    max-width: rem(154px);

    @include respond-to('tablet') {
      max-width: rem(280px);
    }

    &-icon {
      --size: #{rem(80px)};

      @include flex(center, center);
      @include size(var(--size));

      margin-bottom: rem(16px);
      border-radius: 50%;
      background-color: var(--primary);
    }

    &-description {
      width: 100%;
      margin-bottom: 0;
      font-size: rem(14px);
      line-height: 1.3;
      text-align: center;

      @include respond-to('laptop') {
        font-size: 1.7vw;
      }

      @include respond-to('4k') {
        font-size: rem(24px);
      }
    }
  }

  &__cta {
    @include flex(center, center, column);

    width: 100%;
    grid-gap: rem(24px);

    @include respond-to('tablet') {
      flex-direction: row;
    }
  }
}
