.copy-to-clipboard {
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: rem(4px);

  &__button {
    @include reset-button;
    @include font-size(18px, 24px);

    color: var(--grey);
    text-align: left;
    word-break: break-all;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: rem(24px);
  }

  &__popup {
    @include font-size(14px, 20px);

    padding: rem(4px) rem(8px);
    border-radius: rem(4px);
    position: absolute;
    left: 50%;
    top: rem(-28px);
    transform: translateX(-50%);
    background-color: var(--grey);
    color: #fff;
  }

  &--block {
    @include flex(space-between);
    @include size(100%, rem(56px));
    @include font-size(16px, 28px);

    padding-left: rem(14px);
    background-color: var(--white);
    border-radius: rem(32px);
    border: 4px solid var(--white);
    font-weight: 600;
    overflow: hidden;

    @include respond-to('laptop') {
      @include font-size(20px, 28px);

      height: rem(64px);
      padding-left: rem(24px);
    }

    .copy-to-clipboard {
      &__button {
        @include absolute-center;
        @include size(100%);

        opacity: 0;
      }

      &__icon {
        @include size(rem(48px));

        background-color: var(--primary);
        border-radius: 50%;
        position: relative;
        z-index: 2;

        @include respond-to('laptop') {
          @include size(rem(56px));
        }
      }

      &__popup {
        @include size(100%);
        @include font-size(16px, 40px);

        top: 0;
        left: 0;
        z-index: 1;
        padding-left: rem(14px);
        background-color: var(--white);
        color: var(--black);
        transform: translateX(0);

        @include respond-to('laptop') {
          @include font-size(20px, 48px);

          height: rem(48px);
          padding-left: rem(24px);
        }
      }
    }
  }
}
