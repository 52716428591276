.tc-status {
  @include flex(null, center);
  @include font-size(14px, 18px);

  font-weight: 700;
  color: var(--c-666);
  gap: rem(4px);

  &::before {
    @include size(rem(8px), rem(8px));

    content: '';
    display: block;
    border-radius: 50%;
    background-color: var(--primary);
  }

  &--ended {
    &::before {
      @include size(rem(4px), rem(4px));

      background-color: transparent;
      border: 2px solid var(--c-666);
    }
  }
}
