.socials {
  display: flex;
  align-items: center;
  gap: rem(64px);

  &__icon {
    width: 100%;
    height: 100%;
    display: block;
    fill: var(--c-999);
    transition: fill 200ms ease-in-out;
  }

  &__link {
    display: block;
    height: rem(30px);

    &--fb {
      height: rem(28px);
    }

    &--ints {
      height: rem(26px);
    }

    &--yt {
      width: rem(30px);
      height: rem(24px);
    }

    &--tg {
      height: rem(26px);
    }

    &:hover {
      text-decoration: none;

      .socials {
        &__icon {
          fill: var(--primary);
        }
      }
    }
  }
}
