.campaigns {
  &__listing {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: rem(20px);
    margin: rem(24px) 0;

    @include respond-to('mobile') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('laptop') {
      grid-template-columns: repeat(3, 1fr);
      margin: rem(48px) 0;
    }

    @include respond-to('4k') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__filter {
    @include flex(center, center, column);

    gap: rem(24px);

    @include respond-to('mobile') {
      flex-direction: row;
    }

    @include respond-to('laptop') {
      gap: rem(32px);
    }
  }

  &__filter-link {
    @include font-size(24px, 24px);

    padding-bottom: rem(10px);
    border-width: 4px;
    border-bottom-style: solid;
    border-color: transparent;
    text-align: center;
    transition: border-color $animation;

    @include respond-to('mobile') {
      @include font-size(16px, 16px);
    }

    @include respond-to('tablet') {
      @include font-size(24px, 24px);
    }

    @include respond-to('laptop') {
      @include font-size(32px, 32px);

      border-width: 6px;
    }

    @include respond-to('4k') {
      @include font-size(40px, 40px);
    }

    &:hover {
      border-color: var(--primary);
      text-decoration: none;
      color: var(--black);
    }

    &.is-active {
      border-color: var(--primary);
      font-weight: var(--fw-700);
    }
  }

  &__buttons {
    @include flex(center, center, column);

    gap: rem(24px);
    margin-top: rem(40px);

    @include respond-to('laptop') {
      gap: rem(32px);
      flex-direction: row;
    }

    @include respond-to('laptop') {
      margin-top: rem(80px);
    }
  }
}
