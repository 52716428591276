@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.block {
  width: calc(100% + rem(48px));
  max-width: calc(100% + rem(48px));
  overflow: hidden;
  position: relative;
  left: rem(-24px);

  @include respond-to('tablet') {
    width: 100%;
    max-width: 100%;
    left: 0;
  }
}

.scroll {
  @include flex(null, center);

  gap: rem(4px);
  flex-wrap: nowrap;
  width: auto;
  overflow: auto hidden;
}

.btn {
  @include font-size(16px);

  height: rem(40px);
  padding: rem(8px) rem(24px);

  &:first-of-type {
    @include respond-to('max-tablet') {
      margin-left: rem(24px);
    }
  }

  &:last-of-type {
    @include respond-to('max-tablet') {
      margin-right: rem(24px);
    }
  }
}
