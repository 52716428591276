.checkbox {
  @include font-size(14px, 20px);

  display: block;
  position: relative;
  padding-left: rem(32px);
  cursor: pointer;
  user-select: none;

  &__mark {
    @include size(rem(18px));

    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid var(--c-9e);
    border-radius: rem(3px);
    transition: background-color $animation, border-color $animation;

    &::after {
      @include size(rem(5px), rem(10px));

      content: '';
      position: absolute;
      opacity: 0;
      left: 5px;
      top: 0;
      border: solid var(--black);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      transition: opacity $animation;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox__mark {
      background-color: var(--primary);
      border-color: transparent;

      &::after {
        opacity: 1;
      }
    }
  }

  &:hover {
    .checkbox__input ~ .checkbox__mark {
      border-color: var(--black-hover);
    }
  }

  &--lg {
    @include font-size(16px, 20px);

    padding-left: rem(32px);

    @include respond-to('laptop-l') {
      @include font-size(20px, 24px);

      padding-left: rem(40px);
    }
  }
}
