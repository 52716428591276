.mobile-menu {
  @include size(100%);
  @include flex(null, null, column);

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1100;
  overflow: hidden;
  opacity: 0;
  transition: opacity $animation;
  visibility: hidden;

  @include respond-to('laptop-l') {
    position: static;
    display: none;
  }

  &.is-opened {
    opacity: 1;
    visibility: visible;
  }

  &__header {
    @include size(100%, rem(80px));

    padding: 0 rem(24px);
    flex-shrink: 0;
    background-color: var(--white);
    position: relative;
    z-index: 2;
  }

  &__header-container {
    @include size(100%);
    @include flex(space-between, center);

    border-bottom: 1px solid rgba($grey-9e, 0.24);
  }

  &__container {
    @include flex(space-between, null, column);

    width: 100%;
    max-width: rem(480px);
    flex-grow: 1;
    background-color: var(--white);
    overflow: hidden auto;
    box-shadow: $box-shadow;
    position: relative;
  }

  &__footer {
    @include flex(center, center);

    width: 100%;
    position: sticky;
    gap: rem(16px);
    bottom: 0;
    left: 0;
    padding: rem(28px) 0;
    background-color: var(--white);
    z-index: 3;
  }

  &__logo {
    @include size(rem(69px), rem(18px));
  }

  &__body {
    @include flex(null, null, column);

    gap: rem(28px);
    padding: rem(28px) rem(24px);
    position: relative;

    &.is-hidden {
      display: none;
    }
  }

  &__close {
    @include reset-button;
    @include size(rem(24px));
    @include font-size(24px);

    cursor: pointer;
  }

  &__back {
    @include reset-button;

    display: none;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    gap: rem(8px);
    padding: rem(28px) rem(24px);
    cursor: pointer;
    background-color: var(--white);
    transition:
      opacity $animation,
      visibility $animation;
    color: var(--black);
    z-index: 2;

    &.is-active {
      display: flex;
    }

    &-icon {
      @include size(rem(18px));
      @include font-size(18px);
    }
  }

  &__cta {
    padding: 0 rem(24px);

    &-container {
      @include flex(center, null, column);

      gap: rem(12px);
      padding: rem(28px) 0;
      border-top: 1px solid #e7e9ef;
      border-bottom: 1px solid #e7e9ef;
    }
  }

  &__email {
    @include flex(center, center);
    @include font-size(16px, 22px);

    gap: rem(8px);

    &-icon {
      @include size(rem(18px));
      @include flex(center, center);

      font-size: rem(18px);
    }
  }
}
