.motivation-thumbnail {
  text-align: center;
  background: var(--black-light);
  border: 1px solid var(--black-hover);
  border-radius: rem(24px);
  padding: rem(32px) rem(16px);

  @include respond-to('laptop-l') {
    padding: rem(48px) rem(32px);
  }

  &__title,
  &__helper,
  &__sub-title {
    color: var(--white);
  }

  &__helper,
  &__sub-title {
    font-size: rem(18px);
  }

  &__sub-title {
    margin-bottom: rem(24px);

    @include respond-to('laptop-l') {
      margin-bottom: rem(44px);
    }
  }

  &__title {
    font-size: rem(22px);
    margin-bottom: rem(32px);
    font-weight: var( --fw-700);
    line-height: 1.2;

    &--desktop {
      display: none;

      @include respond-to('laptop-l') {
        display: block;
      }
    }

    &--mobile {
      @include respond-to('laptop-l') {
        display: none;
      }
    }

    @include respond-to('laptop-l') {
      font-size: rem(24px);
      margin-bottom: rem(16px);
    }
  }

  &__qr {
    display: none;

    @include respond-to('laptop-l') {
      @include flex(center, center);

      margin-bottom: rem(68px);
    }
  }

  &__helper {
    display: none;

    @include respond-to('laptop-l') {
      display: block;
      margin-bottom: rem(8px);
    }
  }

  &__button {
    box-shadow: 0 rem(19px) rem(36px) 0 #FFDD0026;

    @include respond-to('laptop-l') {
      width: 100%;
    }
  }
}
