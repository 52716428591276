.motivation-slider {
  &__wrapper {
    position: relative;

    @include respond-to('laptop-l') {
      padding-top: rem(24px);
    }
  }

  &__decor {
    @include absolute-center;
  }

  &__top {
    text-align: center;
  }

  &__data {
    min-height: rem(413px);
  }

  &__item {
    position: relative;
    margin: 0 auto;
    padding: rem(56px) 0;
  }

  &__text {
    @include flex(center, center);

    height: rem(345px);
    overflow-x: auto;
    font-size: rem(24px);
    font-weight: var(--fw-500);
    text-align: center;

    > div {
      padding-top: rem(14px);
    }

    @include respond-to('laptop-l') {
      font-size: rem(32px);
    }
  }

  &__pagination {
    position: absolute;
    top: rem(26px);
    left: 50%;
    transform: translateX(-50%);
    font-size: rem(18px);
  }

  &__nav {
    @include flex(center, center);

    gap: rem(24px);
  }

  &__button {
    @include size(rem(68px));
    @include flex(center, center);

    position: relative;
    border: 1px solid var(--black-hover);
    border-radius: rem(68px);
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--dark);
    }

    &:active {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px var(--black-hover);
    }
  }

  &__title {
    font-size: rem(18px);
    font-weight: var(--fw-600);
  }

  &__sub-title {
    font-size: rem(16px);
    color: var(--grey-434);
  }
}
