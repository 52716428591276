.js-file-input {
  position: relative;

  &-container {
    @include size(rem(100px));

    position: relative;
    background-color: var(--c-9e);
    overflow: hidden;
    border: 1px solid var(--c-95);

    &.is-error {
      border-color: var(--error);
    }
  }

  &-label {
    @include size(100%);

    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  &-preview-block {
    @include flex(center, center);
    @include size(100%);

    position: absolute;
    left: 0;
    top: 0;
    user-select: none;
    z-index: 2;
    overflow: hidden;

    img {
      @include size(100%);

      object-fit: cover;
    }
  }

  &-restriction {
    &.is-error {
      color: var(--error);
    }
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    z-index: -1;
  }
}
