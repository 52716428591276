.quick-donation-cta {
  padding-bottom: 0;
  position: relative;
  overflow: hidden;

  @include respond-to('tablet') {
    padding-bottom: rem(80px);
  }

  @include respond-to('laptop') {
    padding-bottom: rem(80px);
  }

  @include respond-to('laptop-l') {
    padding-bottom: rem(140px);
  }

  @include respond-to('4k') {
    padding-bottom: rem(168px);
  }

  &::after {
    --size: #{rem(282px)};

    @include size(var(--size));

    content: '';
    display: block;
    position: absolute;
    right: 20%;
    bottom: rem(32px);
    border-radius: 50%;
    border: 48px solid rgba($primary, 0.24);
    z-index: 0;
    box-sizing: border-box;

    @include respond-to('tablet') {
      --size: #{rem(400px)};

      right: rem(40px);
      bottom: rem(-160px);
      border-width: 60px;
    }

    @include respond-to('laptop') {
      --size: #{rem(560px)};

      right: rem(50px);
      bottom: rem(-400px);
      border-width: 100px;
    }

    @include respond-to('laptop-l') {
      --size: #{rem(640px)};

      right: rem(60px);
      bottom: rem(-500px);
      border-width: 140px;
    }
  }

  &__container {
    @include flex(flex-start, center, column);

    width: 100%;
    max-width: rem(1016px);
    position: relative;
    z-index: 3;

    @include respond-to('tablet') {
      align-items: flex-start;
    }
  }

  &__heading {
    max-width: none;
    text-align: center;

    @include respond-to('tablet') {
      max-width: rem(560px);
      text-align: left;
    }

    @include respond-to('laptop') {
      max-width: rem(760px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(880px);
    }

    @include respond-to('4k') {
      max-width: none;
    }
  }

  &__description {
    @include font-size(20px, 32px);

    width: 100%;
    max-width: rem(560px);
    margin-bottom: rem(64px);
    color: var(--grey);
    text-align: center;

    @include respond-to('tablet') {
      text-align: left;
    }

    @include respond-to('laptop') {
      max-width: rem(740px);
    }
  }

  &__img-wrapper {
    @include flex(flex-end, flex-end);

    width: 100%;
    position: relative;
    z-index: 2;

    @include respond-to('tablet') {
      display: block;
      max-width: rem(300px);
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    @include respond-to('laptop') {
      max-width: rem(380px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(480px);
    }

    @include respond-to('4k') {
      max-width: rem(524px);
    }
  }

  &__img {
    max-width: rem(240px);

    @include respond-to('tablet') {
      max-width: 100%;
    }
  }
}
