.langs {
  display: none;
  gap: rem(16px);

  @include respond-to('laptop-l') {
    @include flex(center, center);
  }

  &__btn {
    @include font-size(14px, 14px);

    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
    font-weight: 500;
    color: var(--aphine);
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    &.is-active {
      color: var(--black);
      cursor: default;
      text-decoration: none;
      pointer-events: none;
      user-select: none;
    }
  }

  &.is-mobile {
    @include flex(center, center);

    padding: 0;
    border: 0;
    gap: rem(16px);

    @include respond-to('laptop-l') {
      display: none;
    }

    .langs {
      &__btn {
        @include flex(center, center);

        gap: rem(16px);

        &::after {
          @include size(2px, 2px);

          content: '';
          display: block;
          background-color: var(--cb);
          flex-shrink: 0;
        }
      }
    }
  }
}
