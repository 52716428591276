.founder-card {
  --size: #{rem(100px)};

  @include flex(center, null, column);

  width: 100%;
  max-width: var(--size);
  user-select: none;

  @include respond-to('laptop') {
    --size: #{rem(174px)};
  }

  &__photo-wrapper {
    @include size(var(--size));
    @include flex(center);

    margin-bottom: rem(24px);
    border-radius: 50%;
    overflow: hidden;
  }

  &__fullname {
    @include font-size(14px, 20px);

    margin-bottom: rem(8px);
    font-weight: var(--fw-600);

    @include respond-to('laptop') {
      @include font-size(18px, 30px);
    }
  }

  &__role {
    @include font-size(12px, 18px);

    margin-bottom: 0;
    color: var(--grey);

    @include respond-to('laptop') {
      @include font-size(16px, 22px);
    }
  }
}
