$breakpoints: (
  'mobile': (
    min-width: 37.5em,
  ),
  // 600px
  'tablet':
    (
      min-width: 48em,
    ),
  // 768px
  'laptop':
    (
      min-width: 62em,
    ),
  // 992px
  'laptop-l':
    (
      min-width: 75em,
    ),
  // 1200px
  '4k':
    (
      min-width: 90em,
    ),
  // 1440px
  'max-laptop-l':
    (
      max-width: 75em,
    ),
  // 1200px
  'max-laptop':
    (
      max-width: 62em,
    ),
  // 992px
  'max-tablet':
    (
      max-width: 48em,
    ),
  // 768px
  'max-mobile':
    (
      max-width: 37.5em,
    ),
  // 600px
) !default;

// Colors
$primary: #fd0;
$secondary: #0085ff;
$black: #000;
$dark: #111;
$grey: #3e5065;
$grey-9e: #9e9e9e;
$white: #fff;
$kava: #502505;
$aphine: #858ca7;

// Transition
$transition-duration: 240ms;
$transition-easing: 'ease-in-out';
$animation: #{$transition-duration} #{$transition-easing};

// Box shadow
$box-shadow: 0 4px 22px rgba($black, 0.06);

:root {
  // Colors
  --primary: #fd0;
  --primary-hover: #ffe74d;
  --secondary: #0085ff;
  --black: #000;
  --black-hover: #1a1a1a;
  --black-light: #0D0D0E;
  --white: #fff;
  --dark: #111;
  --grey: #3e5065;
  --grey-434: #434343;
  --c-999: #999;
  --c-9e: #9e9e9e;
  --d9: #d9d9d9;
  --f9: #f9f9f9;
  --c-95: #959595;
  --c9: #c9c9c9;
  --f5: #f5f5f5;
  --red: #c70000;
  --kava: #502505;
  --error: #ff5252;
  --cb: #cbcbcb;
  --c-666: #666;
  --aphine: #858ca7;
  --c4: #c4c4c4;
  --blue: #1684E9;

  // Typography
  --line-height: 1.5;
  --font-family: 'Manrope', 'Arial', sans-serif;
  --heading-font-family: 'eUkraineHead', sans-serif;
  --google-font: 'Roboto', sans-serif;
  --fw-100: 100;
  --fw-200: 200;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // Size
  --header-height: #{rem(80px)};
  --container-width: 100%;
  --fs-h1: #{rem(32px)};
  --fs-h2: #{rem(24px)};
  --fs-h3: #{rem(24px)};
  --fs-h4: #{rem(24px)};
  --fs-h5: #{rem(16px)};
  --fs-h6: #{rem(16px)};

  @include respond-to('mobile') {
    --container-width: #{rem(600px)};
    --fs-h1: #{rem(40px)};
    --fs-h2: #{rem(32px)};
  }

  @include respond-to('tablet') {
    --container-width: #{rem(768px)};
    --fs-h1: #{rem(48px)};
    --fs-h2: #{rem(40px)};
    --fs-h3: #{rem(32px)};
  }

  @include respond-to('laptop') {
    --container-width: #{rem(992px)};
    --fs-h1: #{rem(56px)};
    --fs-h2: #{rem(48px)};
    --fs-h3: #{rem(40px)};
    --fs-h4: #{rem(32px)};
    --fs-h5: #{rem(24px)};
  }

  @include respond-to('laptop-l') {
    --header-height: #{rem(98px)};
    --container-width: #{rem(1200px)};
    --fs-h1: #{rem(64px)};
    --fs-h2: #{rem(56px)};
    --fs-h3: #{rem(48px)};
  }

  @include respond-to('4k') {
    --container-width: #{rem(1440px)};
    --fs-h1: #{rem(72px)};
    --fs-h2: #{rem(64px)};
  }
}
