.our-results {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(40px) rem(4px);
  align-items: center;
  position: relative;

  @include respond-to('laptop') {
    grid-template-columns: rem(280px) 1fr rem(280px);
    gap: rem(20px);
  }

  &__categories {
    @include flex(null, null, column);

    gap: rem(4px);

    @include respond-to('laptop') {
      gap: rem(16px);
    }
  }

  &__item {
    @include flex(center, center, column);
    @include size(100%, rem(88px));

    gap: rem(4px);
    padding: rem(12px) rem(20px);
    background-color: var(--f5);
    border-radius: rem(60px);

    @include respond-to('laptop') {
      height: auto;
      gap: rem(8px);
      padding: rem(28px);
    }
  }

  &__value {
    @include font-size(24px, 28px);

    font-weight: 700;
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(32px, 38px);
    }
  }

  &__label {
    @include font-size(12px, 14px);

    text-align: center;

    @include respond-to('laptop') {
      @include font-size(14px, 16px);
    }
  }

  &__general {
    @include flex(center, center, column);
    @include size(100%, auto);

    gap: rem(74px);
    position: relative;

    @include respond-to('max-laptop') {
      grid-column: 1 / 3;
      grid-row: 1;
    }

    @include respond-to('laptop') {
      height: rem(280px);
      gap: rem(20px);
    }

    @include respond-to('laptop-l') {
      height: rem(416px);
      gap: rem(40px);
    }

    &::before {
      --size: #{rem(160px)};

      @include absolute-center;
      @include size(var(--size));

      content: '';
      background-color: var(--primary);
      border-radius: 50%;
      z-index: 0;

      @include respond-to('laptop') {
        --size: #{rem(280px)};
      }

      @include respond-to('laptop-l') {
        --size: #{rem(416px)};
      }
    }
  }

  &__content {
    @include flex(center, center, column);

    max-width: rem(200px);
    position: relative;
  }

  &__total {
    @include font-size(38px, 44px);

    margin-bottom: 0;
    white-space: nowrap;
    position: relative;

    @include respond-to('laptop') {
      @include font-size(48px, 56px);
    }

    @include respond-to('laptop-l') {
      @include font-size(74px, 90px);
    }
  }

  &__text {
    @include font-size(14px, 21px);

    text-align: center;
  }
}
