.kolossus-hero {
  &__container {
    position: relative;
  }

  &__content {
    @include flex(null, center, column);

    width: 100%;
    text-align: center;

    @include respond-to('laptop') {
      max-width: rem(600px);
      align-items: flex-start;
      text-align: left;
    }

    @include respond-to('laptop-l') {
      max-width: rem(700px);
    }

    @include respond-to('4k') {
      max-width: rem(900px);
    }
  }

  &__sub-title {
    @include font-size(20px, 30px);

    margin-bottom: rem(20px);

    @include respond-to('laptop') {
      @include font-size(32px, 40px);

      margin-bottom: rem(32px);
    }
  }

  &__description {
    margin-bottom: rem(32px);
    text-align: center;

    @include respond-to('laptop') {
      margin-bottom: rem(88px);
      text-align: left;
    }
  }

  &__decor {
    max-width: 45.5vw;
    margin-bottom: rem(32px);

    @include respond-to('laptop') {
      max-width: rem(400px);
      position: absolute;
      right: rem(16px);
      bottom: rem(120px);
      margin-bottom: 0;
    }

    @include respond-to('laptop-l') {
      max-width: rem(450px);
      bottom: rem(80px);
    }

    @include respond-to('4k') {
      max-width: rem(494px);
      bottom: 0;
    }
  }
}
