.hk-achievements {
  display: grid;
  gap: rem(32px);

  @include respond-to('tablet') {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    @include flex(null, center, column);
  }

  &__title {
    @include font-size(16px, 16px);

    width: 100%;
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(20px, 20px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 24px);
    }
  }

  &__value {
    @include flex(center, center);
    @include font-size(24px, 24px);

    width: 100%;
    flex: 1 0 auto;
    margin-bottom: 0;
    padding: rem(20px);
    background-color: #fff8c1;
    border-radius: rem(20px);
    font-weight: var(--fw-700);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(32px, 32px);

      border-radius: rem(24px);
    }

    @include respond-to('laptop-l') {
      @include font-size(40px, 40px);
    }

    @include respond-to('4k') {
      @include font-size(48px, 48px);
    }
  }
}
