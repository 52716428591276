.happy-kolo-logo {
  display: flex;
  align-items: center;
  gap: rem(20px);

  &__image-wrapper {
    width: rem(154px);
    height: rem(154px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    display: block;
    max-width: 100%;
  }

  &__text {
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
    line-height: 1;
  }

  &__upper-text {
    font-size: rem(40px);
    margin-bottom: rem(8px);
  }

  &__lower-text {
    font-size: rem(52px);
    margin-bottom: 0;
  }
}
