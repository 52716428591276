.user-kolona-card {
  @include flex(space-between, null, column);

  gap: rem(20px);
  border-radius: rem(20px);
  background: linear-gradient(180deg, rgb(255 221 0 / 40%) 0%, rgb(255 221 0 / 20%) 100%);
  margin-bottom: rem(16px);
  padding: rem(32px) rem(20px);
  overflow: hidden;

  @include respond-to('tablet') {
    flex-direction: row;
  }

  &__content {
    @include flex(null, null, column);

    width: 100%;
    gap: rem(8px);
  }

  &__title {
    @include font-size(28px, 36px);

    display: inline-block;
    margin-bottom: rem(16px);
    font-weight: 700;
    text-align: center;

    @include respond-to('tablet') {
      text-align: left;
    }
  }

  a.user-kolona-card__title {
    text-decoration: none;
    border-bottom: 1px solid var(--black);
    transition: border-color $animation;

    &:hover {
      text-decoration: none;
      border-color: transparent;
    }
  }

  &__description {
    border-radius: rem(8px) rem(20px) rem(20px) rem(20px);
    padding: rem(12px) rem(16px);
    background-color: var(--white);
  }

  &__text {
    @include font-size(12px, 16px);

    font-weight: 600;
    color: rgba($black, 0.4);
  }

  &__photo {
    @include size(rem(160px));
    @include flex(center, center);

    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    background-color: rgba($black, 0.1);
    border-radius: 50%;
    border: 4px solid var(--white);

    &::after {
      @include absolute-center;
      @include size(rem(120px));

      content: '';
      border: 1px dashed rgba($black, 0.2);
      border-radius: 50%;
      z-index: 1;
    }
  }

  &__img {
    position: relative;
    object-fit: cover;
    z-index: 2;
  }

  &__footer {
    @include flex(space-between, center, column);

    gap: rem(16px);

    @include respond-to('tablet') {
      flex-direction: row;
    }
  }

  &--empty {
    align-items: center;

    .user-kolona-card {
      &__title {
        @include font-size(20px, 28px);
      }

      &__description {
        color: rgba($black, 0.4);
        text-align: center;
        border: 0;
        padding: 0;
        background-color: transparent;

        @include respond-to('tablet') {
          text-align: left;
        }
      }
    }
  }
}
