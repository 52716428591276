.targeted-banner {
  padding: rem(8px) 0;
  position: relative;
  background-color: var(--black);

  .container {
    @include respond-to('max-laptop') {
      max-width: 100%;
      padding-left: rem(24px);
      padding-right: rem(24px);
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr rem(102px);
    gap: 0 rem(8px);

    @include respond-to('tablet') {
      @include flex(null, center);

      gap: rem(24px);
    }
  }

  &__img {
    @include size(rem(48px));
    @include flex(center, center);

    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;

    @include respond-to('laptop') {
      @include size(rem(80px));
    }
  }

  &__general {
    @include flex(null, flex-start);

    gap: rem(8px);
    flex-grow: 1;

    @include respond-to('tablet') {
      align-items: center;
      gap: rem(16px);
    }
  }

  &__content {
    @include flex(null, null, column);

    gap: rem(8px);
    flex-grow: 1;

    @include respond-to('tablet') {
      flex-direction: row;
      align-items: center;
      gap: rem(24px);
    }
  }

  &__title {
    @include font-size(14px, 16px);
    @include flex(null, center);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    color: var(--white);
    font-weight: 700;
    text-decoration: none;
    flex-grow: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &:hover,
    &:visited {
      text-decoration: none;
      color: var(--white);
    }

    @include respond-to('laptop') {
      @include font-size(24px, 28px);
    }
  }

  &__logos {
    @include flex(flex-start, center);

    flex-wrap: wrap;
    column-gap: rem(8px);
    overflow: hidden;
    flex-shrink: 0;

    @include respond-to('tablet') {
      @include size(rem(172px), rem(64px));

      padding-left: rem(16px);
      border-left: 1px solid rgba($white, 0.5);
    }

    @include respond-to('laptop-l') {
      width: rem(192px);
      column-gap: rem(12px);
    }

    @include respond-to('4k') {
      width: rem(234px);
    }
  }

  &__partner {
    height: rem(10px);
    margin-bottom: 0;

    @include respond-to('tablet') {
      height: rem(16px);
    }

    img {
      @include size(auto, 100%);

      object-fit: contain;
      opacity: 0.5;
    }
  }

  &__progress {
    @include flex(null, flex-end, row-reverse);

    gap: rem(8px);
    grid-column: span 2;
    flex-shrink: 0;

    @include respond-to('tablet') {
      flex-direction: column;
      gap: rem(8px);
    }
  }

  &__cta {
    @include font-size(10px);

    min-width: auto;

    @include respond-to('max-laptop-l') {
      height: rem(32px);
      min-height: auto;
    }

    @include respond-to('tablet') {
      @include font-size(14px);

      order: 5;
    }

    @include respond-to('laptop-l') {
      @include font-size(18px);
    }
  }

  &__close {
    @include reset-button;
    @include size(12px);
    @include flex(center, center);

    position: absolute;
    left: rem(8px);
    top: rem(8px);
  }

  &__empty-container {
    display: grid;
    grid-template-columns: rem(48px) 1fr;
    width: 100%;
    gap: rem(4px);

    @include respond-to('tablet') {
      display: block;
    }
  }

  &__empty-label {
    @include font-size(12px);

    display: inline;
    color: rgba($white, 0.5);

    @include respond-to('tablet') {
      display: none;
    }
  }

  &__empty {
    @include size(100%, rem(12px));

    background-image: url('~@assets/images/slashes.png');
    background-repeat: repeat;
    background-size: cover;

    @include respond-to('tablet') {
      min-width: rem(182px);
    }

    @include respond-to('laptop-l') {
      min-width: rem(278px);
    }

    @include respond-to('4k') {
      min-width: rem(368px);
    }
  }
}
