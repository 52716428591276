.achievements {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(16px);

  @include respond-to('laptop') {
    grid-template-columns: repeat(4, 1fr);
    gap: rem(24px);
  }

  &__item {
    @include flex(center, center, column);

    padding: rem(16px) rem(16px);
    background-color: rgba($primary, 0.24);
    border-radius: rem(24px);
    font-weight: var(--fw-600);
    gap: rem(8px);

    @include respond-to('laptop-l') {
      padding: rem(16px) rem(32px);
      gap: rem(16px);
    }
  }

  &__value {
    @include font-size(16px);

    text-align: center;

    @include respond-to('mobile') {
      @include font-size(24px);
    }

    @include respond-to('tablet') {
      @include font-size(32px);
    }
  }

  &__label {
    @include font-size(14px);

    color: rgba($black, 0.32);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(16px);
    }

    @include respond-to('laptop-l') {
      @include font-size(20px);
    }
  }
}
