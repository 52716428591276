.kolossus-club {
  display: grid;
  grid-gap: rem(24px) rem(16px);
  grid-template-rows: auto;

  @include respond-to('mobile') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond-to('laptop') {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(48px) rem(24px);
  }

  @include respond-to('4k') {
    grid-template-columns: repeat(4, 1fr);
  }
}
