.search-result {
  margin-top: rem(24px);

  @include respond-to('laptop') {
    margin-top: rem(40px);
  }

  &__label {
    @include font-size(16px, 24px);

    @include respond-to('laptop') {
      @include font-size(18px, 24px);
    }
  }

  &__empty {
    @include font-size(24px, 28px);

    margin: rem(100px) auto;
    font-family: var(--heading-font-family);
    font-weight: 700;
    color: var(--c9);

    @include respond-to('laptop') {
      @include font-size(32px, 38px);
    }
  }
}
