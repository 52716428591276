@font-face {
  font-family: eUkraine;
  src:
    url('~@assets/fonts/e-Ukraine-Thin.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-Ukraine-Thin.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-Ukraine-Thin.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraine;
  src:
    url('~@assets/fonts/e-Ukraine-UltraLight.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-Ukraine-UltraLight.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-Ukraine-UltraLight.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraine;
  src:
    url('~@assets/fonts/e-Ukraine-Light.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-Ukraine-Light.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-Ukraine-Light.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraine;
  src:
    url('~@assets/fonts/e-Ukraine-Regular.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-Ukraine-Regular.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-Ukraine-Regular.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraine;
  src:
    url('~@assets/fonts/e-Ukraine-Medium.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-Ukraine-Medium.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-Ukraine-Medium.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraine;
  src:
    url('~@assets/fonts/e-Ukraine-Bold.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-Ukraine-Bold.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-Ukraine-Bold.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraineHead;
  src:
    url('~@assets/fonts/e-UkraineHead-Thin.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-UkraineHead-Thin.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-UkraineHead-Thin.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraineHead;
  src:
    url('~@assets/fonts/e-UkraineHead-UltraLight.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-UkraineHead-UltraLight.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-UkraineHead-UltraLight.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraineHead;
  src:
    url('~@assets/fonts/e-UkraineHead-Light.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-UkraineHead-Light.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-UkraineHead-Light.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraineHead;
  src:
    url('~@assets/fonts/e-UkraineHead-Regular.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-UkraineHead-Regular.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-UkraineHead-Regular.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraineHead;
  src:
    url('~@assets/fonts/e-UkraineHead-Medium.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-UkraineHead-Medium.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-UkraineHead-Medium.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraineHead;
  src:
    url('~@assets/fonts/e-UkraineHead-Bold.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-UkraineHead-Bold.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-UkraineHead-Bold.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: eUkraineHeadLogo;
  src:
    url('~@assets/fonts/e-UkraineHead-LOGO.woff') format('woff'),
    // Modern browsers
    url('~@assets/fonts/e-UkraineHead-LOGO.woff2') format('woff2'),
    // Super modern browsers
    url('~@assets/fonts/e-UkraineHead-LOGO.ttf') format('truetype'); // Safari, Android, iOS

  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
