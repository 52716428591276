@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.block {
  @include flex(null, null, column);

  padding: rem(32px) rem(24px);
  gap: rem(40px);
  background: var(--f5);

  @include respond-to('mobile') {
    border-radius: rem(24px);
  }

  @include respond-to('laptop') {
    gap: rem(56px);
    padding: rem(40px) rem(32px);
  }
}

.container {
  @include respond-to('max-mobile') {
    padding: 0;
    max-width: 100%;
  }
}
