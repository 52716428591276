.contacts {
  @include flex(space-between, center, column);

  gap: rem(40px);

  @include respond-to('laptop') {
    flex-direction: row;
    align-items: flex-start;
    gap: rem(24px);
  }

  &__heading {
    @include font-size(24px, 32px);

    margin-bottom: rem(32px);
    font-weight: var(--fw-700);

    @include respond-to('tablet') {
      margin-bottom: rem(40px);
    }

    @include respond-to('4k') {
      margin-bottom: rem(64px);
    }
  }

  &__column {
    width: 100%;
    max-width: 100%;
    position: relative;

    @include respond-to('laptop') {
      max-width: rem(420px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(548px);
    }
  }

  &__title {
    @include font-size(20px, 24px);

    margin-bottom: rem(24px);
    font-weight: var(--fw-600);
  }

  &__row {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: rem(40px);

      @include respond-to('laptop') {
        margin-bottom: rem(74px);
      }
    }
  }

  &__text {
    font-size: rem(14px);

    @include respond-to('tablet') {
      @include font-size(18px, 30px);
    }
  }

  &__link {
    font-size: rem(14px);
    font-weight: var(--fw-600);
    text-decoration: underline;

    @include respond-to('tablet') {
      font-size: rem(16px);
    }

    &:hover {
      text-decoration: none;
      color: var(--black);
    }
  }

  &__buttons-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(12px);

    @include respond-to('mobile') {
      flex-direction: row;
    }

    @include respond-to('laptop') {
      align-items: center;
    }
  }
}
