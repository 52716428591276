.subscription-card {
  @include size(100%);
  @include flex(null, null, column);

  border-radius: rem(16px);
  overflow: hidden;
  flex-shrink: 0;

  &__wrapper {
    @include flex(null, null, column);

    padding: rem(24px);
    border-radius: rem(16px);
    background-color: var(--f5);
    height: 100%;
  }

  &.active {
    border: 1px solid var(--primary);

    .subscription-card {
      &__wrapper {
        background-color: var(--primary);
      }
    }
  }

  &__unsubscribe {
    @include flex(null, center);
    @include font-size(14px, 20px);

    transition: color 0.3s ease;
    gap: rem(4px);

    &:hover {
      text-decoration: none;
    }
  }

  &__bottom {
    @include flex(null, center);

    padding: rem(8px) rem(24px);
    height: 100%;
  }

  &__header {
    @include flex(space-between, center, row);

    gap: rem(16px);
    padding-bottom: rem(8px);
  }

  &__title {
    @include font-size(20px, 24px);

    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
  }

  &__description {
    @include font-size(14px, 21px);

    margin: rem(8px) 0 rem(40px);
    font-weight: var(--fw-200);
  }

  &__text {
    @include font-size(10px, 20px);

    font-weight: var(--fw-200);
  }

  &__label {
    @include font-size(14px, 20px);

    white-space: nowrap;
  }

  &__info {
    @include flex(null, flex-end, column);

    gap: rem(8px);
  }

  &__status {
    @include font-size(16px);

    padding: rem(4px) rem(12px);
    border: 1px solid var(--black);
    border-radius: rem(8px);
    font-weight: var(--fw-700);
    overflow: hidden;
    flex-shrink: 0;

    &.is-active {
      background-color: var(--black);
      color: var(--white);
    }
  }

  &__date {
    @include font-size(10px, 20px);

    font-weight: var(--fw-200);
    text-align: right;
  }

  &__row {
    @include flex(space-between, center);

    gap: rem(16px);

    &:not(:last-child) {
      margin-bottom: rem(8px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__field {
    @include font-size(14px, 20px);

    font-weight: var(--fw-400);
  }

  &__value {
    @include font-size(14px, 21px);

    text-align: right;
    font-weight: var(--fw-700);
  }

  &__btn {
    margin-top: auto;
  }

  &__photo-wrapper {
    @include size(rem(80px));

    border: rem(4px) solid var(--primary);
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;

    @include respond-to('tablet') {
      @include size(rem(120px));

      border-width: rem(8px);
    }
  }
}
