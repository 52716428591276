.kolo-cannes-modal {
  top: 50%;
  transform: translateY(-50%);

  &__video {
    @include flex(center, center);
  }

  &__iframe {
    height: 70vh;
    border: 0;
    aspect-ratio: 0.56;
  }

  .modal {
    top: 0;
  }

  .modal-dialog {
    max-width: none;
  }

  .modal-content {
    width: auto;
    height: auto;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }

  .modal-header {
    .close {
      top: -32px;
    }
  }
}
