.services-nav {
  display: none;
  width: 100%;
  padding: 0 rem(24px);

  &.is-active {
    display: block;
  }

  &__container {
    @include flex(null, null, column);

    gap: rem(28px);
    padding: 0;
  }

  &__category {
    padding-bottom: rem(28px);
    position: relative;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      border-bottom: 1px solid #e7e9ef;
    }

    &:not(:last-child) {
      z-index: 1;
    }

    &:last-child {
      border: 0;
      position: relative;

      &::before {
        @include size(calc(100% + rem(48px)), calc(100% + rem(28px)));

        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        left: rem(-24px);
        top: rem(-27px);
        background-color: #eff1f6;
      }
    }
  }

  &__label {
    @include font-size(14px, 18px);

    margin-bottom: rem(24px);
    text-transform: uppercase;
    color: var(--aphine);
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  &__list {
    @include flex(null, null, column);
    @include reset-list;

    gap: rem(20px);
  }

  &__item {
    @include flex(null, null, column);

    gap: rem(4px);
  }

  &__link {
    @include flex(null, center);
    @include font-size(16px, 22px);

    gap: rem(12px);
    font-weight: 500;

    &:visited {
      color: var(--black);
    }

    &:hover {
      color: var(--black);
    }
  }

  &__icon-block {
    @include size(rem(18px));
    @include font-size(18px);
  }

  &__description {
    @include font-size(13px, 16px);

    padding-left: rem(30px);
    font-weight: 500;
    color: #646464;
  }

  &__column {
    position: relative;
  }

  &--desktop {
    width: 100%;
    overflow: hidden auto;
    position: fixed;
    top: rem(96px);
    left: 0;
    right: 0;
    box-shadow: $box-shadow;
    z-index: 10;

    &::before {
      @include size(50%, 100%);

      content: '';
      background-color: var(--white);
      position: absolute;
      left: 0;
      top: 0;
    }

    &::after {
      @include size(50%, 100%);

      content: '';
      background-color: #eff1f6;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.is-active {
      display: none;

      @include respond-to('laptop-l') {
        display: block;
      }
    }

    .services-nav {
      &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 0 rem(16px);
        gap: 0;
        position: relative;
        z-index: 2;
      }

      &__column {
        height: 100%;
        padding-right: rem(16px);
      }

      &__link {
        @include font-size(14px, 19px);

        gap: rem(6px);
      }

      &__icon-block {
        @include size(rem(16px));
        @include font-size(16px);
      }

      &__description {
        padding-left: rem(22px);
      }

      &__category {
        padding-top: rem(32px);
        border: 0;
        overflow: hidden;

        &:not(:last-child) {
          padding-bottom: rem(68px);
          background-color: var(--white);
        }

        &:last-child {
          padding-bottom: rem(40px);
        }

        &:not(:first-child) {
          .services-nav__column {
            padding-left: rem(32px);
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          .services-nav__column {
            border-left: 1px solid #e7e9ef;
          }
        }
      }
    }
  }
}
