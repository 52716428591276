.campaign-donation-amount {
  &__label {
    @include font-size(14px, 24px);

    display: none;
    margin-bottom: 0;
    color: var(--grey);

    @include respond-to('laptop') {
      display: block;
    }
  }

  &__content {
    @include flex(null, baseline);

    flex-wrap: wrap;
    gap: rem(8px);
  }

  &__current {
    @include flex(null, baseline);
    @include font-size(24px, 32px);

    gap: rem(8px);
    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: 700;
  }

  &__total {
    @include font-size(16px, 24px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: 400;
    color: var(--grey);
  }

  &--widget {
    margin-bottom: rem(32px);

    @include respond-to('laptop-l') {
      margin-bottom: rem(40px);
    }

    .campaign-donation-amount {
      &__current {
        @include respond-to('laptop-l') {
          @include font-size(32px, 40px);
        }
      }

      &__total {
        @include respond-to('laptop-l') {
          @include font-size(20px, 24px);
        }
      }
    }
  }

  &--banner {
    @include respond-to('max-tablet') {
      min-width: rem(102px);
      flex-shrink: 0;
    }

    .campaign-donation-amount {
      &__content {
        flex-direction: column-reverse;
        align-items: flex-end;
        row-gap: 0;

        @include respond-to('tablet') {
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: flex-end;
          row-gap: rem(8px);
        }
      }

      &__current {
        @include font-size(14px);

        color: var(--white);
        white-space: nowrap;

        @include respond-to('max-laptop') {
          gap: rem(4px);
        }

        @include respond-to('tablet') {
          &::after {
            content: '/';
          }
        }

        @include respond-to('laptop-l') {
          @include font-size(28px, 30px);
        }

        &--empty {
          &::after {
            content: none;
          }
        }
      }

      &__currency {
        @include font-size(14px);

        @include respond-to('laptop-l') {
          @include font-size(20px);
        }
      }

      &__total {
        @include font-size(10px, 14px);

        font-family: var(--font-family);
        font-weight: 700;
        color: rgba($white, 0.5);
        white-space: nowrap;

        @include respond-to('laptop-l') {
          @include font-size(20px);
        }

        a {
          color: rgba($white, 0.5);
          pointer-events: none;
        }
      }

      &__label {
        @include font-size(12px, 20px);

        display: none;
        font-family: var(--font-family);
        font-weight: 600;
        color: rgba($white, 0.5);

        @include respond-to('tablet') {
          display: inline;
        }
      }
    }
  }

  &--modal {
    margin-bottom: rem(8px);

    .campaign-donation-amount {
      &__content {
        justify-content: space-between;

        &--empty {
          justify-content: flex-end;
        }
      }

      &__current {
        @include font-size(24px, 26px);

        font-family: var(--font-family);
        white-space: nowrap;

        @include respond-to('laptop-l') {
          @include font-size(28px, 30px);
        }
      }

      &__currency {
        @include font-size(16px, 18px);

        @include respond-to('laptop-l') {
          @include font-size(20px, 22px);
        }
      }

      &__total {
        @include font-size(16px, 20px);

        font-family: var(--font-family);
        font-weight: 700;
        color: rgba($black, 0.5);
        white-space: nowrap;

        @include respond-to('laptop-l') {
          @include font-size(20px, 26px);
        }
      }

      &__label {
        @include font-size(12px, 18px);

        font-family: var(--font-family);
        font-weight: 600;
        color: rgba($black, 0.5);
      }
    }
  }
}
