.progress {
  @include flex(center);

  gap: rem(16px);
  margin-top: rem(40px);
  flex-wrap: wrap;

  @include respond-to('mobile') {
    gap: rem(40px);
  }

  @include respond-to('laptop') {
    gap: rem(96px);
    margin-top: rem(80px);
  }

  &__item {
    @include flex(center, center, column);

    gap: rem(12px);

    @include respond-to('laptop') {
      gap: rem(16px);
    }
  }

  &__circle {
    --size: #{rem(72px)};

    @include size(var(--size));
    @include flex(center, center);

    background-color: var(--primary);
    border-radius: 50%;

    @include respond-to('laptop') {
      --size: #{rem(112px)};
    }
  }

  &__value {
    @include font-size(16px, 20px);

    font-family: var(--heading-font-family);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(24px, 28px);
    }
  }

  &__label {
    @include font-size(14px, 20px);

    text-align: center;
    font-weight: var(--fw-700);

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }
  }
}
