.kava-prediction {
  width: 100%;

  &__wrapper {
    @include flex(null, center, column);

    padding: rem(8px) rem(24px);
    background: #0085ff;
    border-radius: rem(16px);

    @include respond-to('laptop') {
      padding: rem(16px) rem(24px);
    }
  }

  &__label {
    @include font-size(14px, 22px);

    margin-bottom: rem(8px);
    font-weight: var(--fw-500);
    text-align: center;
    color: rgba($black, 0.4);

    &--lg {
      @include font-size(14px, 24px);

      margin-bottom: rem(8px);

      @include respond-to('tablet') {
        @include font-size(18px, 32px);

        margin-bottom: rem(16px);
        text-align: left;
      }
    }
  }

  &__text {
    @include font-size(16px, 24px);

    margin-bottom: 0;
    font-weight: var(--fw-500);
    color: #fff;

    @include respond-to('tablet') {
      @include font-size(20px, 28px);
    }
  }
}
