.kolo-cannes-reason {
  &__wrapper {
    @include flex(flex-start, center, column);

    gap: rem(24px);
    margin-bottom: rem(48px);

    @include respond-to('tablet') {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: rem(48px);
      margin-bottom: rem(64px);
      align-items: flex-start;
    }

    @include respond-to('laptop-l') {
      gap: rem(88px);
      margin-bottom: rem(88px);
    }
  }

  &__item {
    @include flex(flex-start, center, column);
  }

  &__img-wrapper {
    --size: #{rem(140px)};

    @include size(var(--size));

    margin-bottom: rem(32px);
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--d9);

    @include respond-to('laptop') {
      --size: #{rem(180px)};
    }

    @include respond-to('laptop-l') {
      --size: #{rem(228px)};

      margin-bottom: rem(48px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    margin-bottom: 0;
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(20px, 32px);
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }
  }

  &__author {
    @include font-size(12px);

    margin-top: rem(16px);
    margin-bottom: 0;
    text-align: center;
  }
}
