.kolossus-cta {
  @include flex(space-between, center);

  flex-direction: column;
  gap: rem(24px);

  @include respond-to('laptop') {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &__body {
    @include flex(null, center, column);

    gap: rem(24px);

    @include respond-to('mobile') {
      align-items: center;
      flex-direction: row;
    }

    @include respond-to('laptop') {
      gap: rem(48px);
    }
  }

  &__content {
    width: 100%;
    max-width: rem(640px);
  }

  &__title {
    @include font-size(24px, 32px);

    margin-bottom: rem(16px);
    text-align: center;

    @include respond-to('mobile') {
      text-align: left;
    }

    @include respond-to('laptop') {
      @include font-size(32px, 40px);
    }
  }

  &__desc {
    margin-bottom: 0;
    color: var(--grey);
    text-align: center;

    @include respond-to('mobile') {
      text-align: left;
    }
  }

  &__img {
    max-width: rem(140px);

    @include respond-to('laptop') {
      max-width: rem(182px);
    }
  }
}
