.campaign-cta {
  width: 100%;
  max-width: rem(1170px);
  margin-left: auto;
  margin-right: auto;

  &__title {
    margin-bottom: rem(24px);
    font-size: rem(24px);
    text-align: center;

    @include respond-to('tablet') {
      font-size: rem(40px);
    }

    @include respond-to('laptop-l') {
      margin-bottom: rem(40px);
      font-size: rem(56px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    text-align: center;

    p {
      @include font-size(16px, 24px);

      color: var(--grey);

      @include respond-to('laptop') {
        @include font-size(20px, 32px);
      }
    }
  }

  &__buttons {
    @include flex(center, center, column);

    width: 100%;
    max-width: rem(800px);
    gap: rem(24px);
    margin: rem(24px) auto 0;

    @include respond-to('laptop-l') {
      margin-top: rem(48px);
    }

    &--active {
      padding: rem(24px) rem(16px);
      background-color: var(--f9);
      border-radius: rem(24px);

      @include respond-to('laptop') {
        padding: rem(40px);
        border-radius: rem(40px);
      }
    }
  }

  &__button {
    @media (max-width: 599px) {
      min-width: rem(256px);
    }
  }

  &__form {
    @include flex(null, center, column);

    gap: rem(24px);
  }

  &__fieldset {
    @include flex(center, center);

    gap: rem(24px);
  }
}
