.kolo-cannes-socials {
  &__title {
    @include font-size(24px, 32px);

    margin-bottom: rem(32px);
    font-family: var(--font-family);
    font-weight: var(--fw-normal);
    text-align: center;

    @include respond-to('tablet') {
      @include font-size(32px, 48px);
    }

    @include respond-to('laptop-l') {
      @include font-size(48px, 64px);

      margin-bottom: rem(48px);
    }
  }

  &__wrapper {
    @include flex(center, center);

    gap: rem(48px);

    @include respond-to('tablet') {
      gap: rem(96px);
    }

    @include respond-to('laptop-l') {
      gap: rem(172px);
    }
  }

  &__link {
    max-width: rem(56px);
    text-decoration: none;

    @include respond-to('tablet') {
      max-width: rem(80px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(114px);
    }

    &:hover {
      text-decoration: none;
    }

    &:visited {
      text-decoration: none;
    }
  }
}
