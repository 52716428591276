.section {
  --padding-y: #{rem(40px)};

  position: relative;
  padding: var(--padding-y) 0;

  @include respond-to('laptop') {
    --padding-y: #{rem(80px)};
  }

  &--grey-gradient {
    background: linear-gradient(rgb(249 249 249 / 100%), rgb(255 255 255 / 100%));
  }

  &--gradient {
    background: linear-gradient(rgb(255 221 0 / 80%), rgb(255 221 0 / 10%));
  }

  &--primary {
    background-color: var(--primary);
  }

  &--grey {
    background-color: var(--f9);
  }

  &--sm {
    --padding-y: #{rem(40px)};
  }

  &--profile {
    min-height: calc(100vh - rem(98px));
    padding: rem(32px) 0 rem(56px);


    @include respond-to('laptop') {
      padding: rem(16px) 0 rem(56px);
    }
  }
}
