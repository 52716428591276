.donate-requisite {
  &:not(:last-child) {
    margin-bottom: rem(16px);
  }

  &__label {
    @include font-size(14px, 24px);

    margin-bottom: rem(8px);
    color: rgba($grey, 0.48);

    &--lg {
      @include font-size(20px, 32px);

      font-weight: var(--fw-700);
      color: var(--black);
    }
  }
}
