.footer {
  width: 100%;
  position: relative;
  background-color: var(--dark);
  padding: rem(40px) 0;

  &__container {
    @include flex(null, null, column-reverse);

    width: 100%;

    @include respond-to('laptop-l') {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
  }

  &__column {
    @include flex(null, null, column-reverse);

    gap: rem(16px);

    @include respond-to('laptop-l') {
      gap: rem(32px);
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__copy {
    margin-bottom: 0;
    font-size: rem(14px);
    color: #fff;

    @include respond-to('tablet') {
      font-size: rem(16px);
    }
  }

  &__list {
    @include flex(null, null, column);

    list-style: none;
    gap: rem(16px);
    margin: 0;
    padding: 0;

    @include respond-to('laptop') {
      flex-direction: row;
      align-items: center;
      gap: rem(40px);
    }
  }

  &__link {
    font-size: rem(14px);
    color: #fff;

    @include respond-to('tablet') {
      font-size: rem(16px);
    }

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: #fff;
    }
  }

  &__socials {
    margin-bottom: rem(32px);

    @include respond-to('laptop-l') {
      margin-right: rem(80px);
      margin-bottom: 0;
    }
  }
}
