.payment-logos {
  width: 100%;
  max-width: rem(288px);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: rem(16px);

  & > svg {
    width: 100%;
  }
}
