.payment-buttons {
  @include flex(null, center, column);

  gap: rem(12px);

  @include respond-to('tablet') {
    gap: rem(24px);
  }

  &--grid {
    @include respond-to('laptop') {
      align-items: flex-start;
    }

    @include respond-to('laptop-l') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }

  &__logos {
    @include flex(center, center);
    @include font-size(14px, 20px);

    max-width: rem(368px);
    flex-wrap: wrap;
    gap: rem(8px);

    @include respond-to('laptop') {
      gap: rem(16px);
    }

    a {
      text-decoration: underline;
      color: var(--c-9e);

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__text {
    margin-bottom: 0;
    font-weight: 600;
    color: var(--c-9e);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(18px, 30px);
    }
  }
}
