.payment-method {
  &__title {
    @include font-size(14px, 20px);

    margin-bottom: rem(32px);
    color: var(--c-9e);
  }

  &__card {
    @include flex(null, center);
    @include font-size(12px, 20px);

    gap: rem(16px);
    margin-bottom: rem(24px);
    flex-wrap: wrap;
    font-family: var((--heading-font-family));
    font-weight: var(--fw-200);

    @include respond-to('mobile') {
      @include font-size(16px, 24px);
    }
  }
}
