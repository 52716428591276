.kolo-cannes-bet-modal {
  top: 0;

  .modal-content {
    height: auto;
  }

  &__form {
    padding: rem(40px) 0 0;

    @include respond-to('laptop-l') {
      padding: rem(40px) 0;
    }
  }

  &__bets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(16px);
    margin-top: rem(40px);
    margin-bottom: rem(40px);

    @include respond-to('mobile') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__button {
    @include flex(stretch, center);
  }

  &__amount {
    @include font-size(24px);

    font-weight: var(--fw-700);
  }
}
