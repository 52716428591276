.content-block {
  position: relative;

  &__image {
    overflow: hidden;
    margin-bottom: rem(24px);

    @include respond-to('tablet') {
      max-width: 46vw;
      border-radius: 0 rem(24px) rem(24px) 0;
      margin-bottom: 0;
    }
  }

  &__container {
    @include respond-to('tablet') {
      @include flex(flex-end);

      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      transform: translateX(-50%);
    }
  }

  &__content {
    @include respond-to('tablet') {
      max-width: rem(360px);
      padding-top: rem(48px);
    }

    @include respond-to('laptop') {
      max-width: rem(480px);
      padding-top: rem(80px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(560px);
      padding-top: rem(102px);
    }

    @include respond-to('4k') {
      max-width: rem(672px);
      padding-top: rem(148px);
    }
  }
}
