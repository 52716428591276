.kolona-banner {
  @include flex(space-between, center, column);

  width: 100%;
  max-width: rem(520px);
  position: relative;
  background-color: var(--white);
  border-radius: rem(16px);
  z-index: 2;
  margin: rem(40px) auto rem(-262px);
  padding: rem(20px) rem(16px);
  transition: box-shadow $animation;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba($black, 0.12);
  gap: rem(70px);

  &:hover {
    text-decoration: none;
    box-shadow: 0 4px 22px rgba($black, 0.12);
    color: var(--black);
  }

  @include respond-to('laptop') {
    max-width: 100%;
    gap: 0;
    margin-top: rem(56px);
    margin-bottom: 0;
    padding: rem(24px) rem(32px);
    flex-direction: row;
  }

  &::after {
    @include size(rem(564px));

    content: '';
    background-color: var(--primary);
    position: absolute;
    border-radius: 50%;
    right: 50%;
    transform: translate(50%, 0);
    top: auto;
    bottom: rem(-376px);

    @include respond-to('laptop') {
      right: rem(-150px);
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &--main {
    margin: 0 auto rem(32px);

    @include respond-to('laptop') {
      box-shadow: none;
      border: 1px solid var(--c4);
      margin-bottom: rem(56px);
    }
  }

  &__content {
    @include flex(null, null, column);

    width: 100%;
    gap: rem(16px);
    position: relative;
    z-index: 2;

    @include respond-to('laptop') {
      max-width: rem(480px);
    }
  }

  &__graphics {
    width: 100%;
    max-width: rem(452px);
    position: relative;
    z-index: 2;
  }

  &__image {
    @include size(100%);

    object-fit: contain;
  }

  &__title {
    @include font-size(20px, 24px);

    margin-bottom: 0;

    @include respond-to('laptop') {
      @include font-size(32px, 40px);
    }
  }

  &__text {
    @include font-size(16px, 20px);

    margin-bottom: 0;

    @include respond-to('laptop') {
      @include font-size(20px, 24px);
    }
  }
}
