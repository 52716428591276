.warning {
  width: 100%;
  max-width: rem(968px);
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f5f5;
  border: 1px solid #e2e2e2;
  padding: rem(16px);
  border-radius: rem(8px);
  overflow: hidden;
  position: relative;

  @include respond-to('laptop') {
    border-radius: rem(16px);
    padding: rem(24px) rem(48px);
  }

  &__container {
    @include flex(space-between, flex-start);

    gap: rem(8px);

    @include respond-to('laptop') {
      gap: rem(30px);
      align-items: center;
    }
  }

  &__content {
    @include respond-to('laptop') {
      padding: rem(16px);
    }

    p {
      margin-bottom: rem(24px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    @include size(rem(20px), rem(20px));

    flex-shrink: 0;
    margin-top: rem(4px);

    @include respond-to('laptop') {
      @include size(rem(48px), rem(43px));

      margin-top: 0;
    }
  }
}
