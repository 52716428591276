.kolona-result {
  &__title {
    margin-bottom: rem(40px);

    @include respond-to('laptop') {
      margin-bottom: rem(80px);
    }
  }

  &__total {
    @include font-size(32px, 40px);

    margin-bottom: 0;
    font-weight: var(--fw-700);

    @include respond-to('laptop') {
      @include font-size(48px, 56px);
    }
  }

  &__img-container {
    margin-bottom: rem(16px);

    @include respond-to('tablet') {
      margin-bottom: rem(24px);
    }
  }

  &__cta {
    max-width: rem(768px);
    margin-bottom: rem(40px);
    background-color: var(--f5);
    border-radius: rem(48px);
    padding: rem(32px) rem(16px);

    @include respond-to('tablet') {
      margin-bottom: rem(56px);
      padding: rem(32px) rem(64px);
    }

    &-header {
      width: 100%;
      position: relative;
      margin-bottom: rem(24px);

      &::before,
      &::after {
        @include font-size(56px);

        font-weight: var(--fw-700);
        color: var(--c9);
        position: absolute;
        top: 0;

        @include respond-to('laptop') {
          @include font-size(80px);
        }
      }

      &::before {
        content: '[';
        left: 0;
        text-align: left;
      }

      &::after {
        content: ']';
        right: 0;
        text-align: right;
      }
    }
  }

  &__plus {
    display: none;

    @include respond-to('laptop') {
      @include flex(space-between);

      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }

    &--top {
      top: rem(40px);
    }

    &--bottom {
      bottom: rem(56px);
    }

    &::before,
    &::after {
      @include font-size(100px, 100px);

      content: '+';
      font-family: var(--heading-font-family);
      font-weight: var(--fw-100);
      color: var(--f5);
    }
  }
}
