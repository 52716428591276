.kolo-cannes-explainer {
  &__wrapper {
    @include flex(center, flex-start, column);

    gap: rem(48px);
    margin-bottom: rem(48px);
    position: relative;

    @include respond-to('tablet') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rem(16px);
    }

    &--decor {
      display: none;

      @include respond-to('tablet') {
        display: grid;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin-bottom: 0;
        padding-left: rem(16px);
        padding-right: rem(16px);
      }
    }
  }

  &__item {
    @include flex(flex-start, center, column);
  }

  &__img-wrapper {
    --size: #{rem(220px)};

    @include size(var(--size));
    @include flex(center);

    margin-bottom: rem(24px);
    border-radius: 50%;
    position: relative;
    z-index: 0;

    @include respond-to('laptop') {
      --size: #{rem(300px)};

      margin-bottom: rem(32px);
    }

    @include respond-to('laptop-l') {
      --size: #{rem(380px)};

      margin-bottom: rem(48px);
    }

    @include respond-to('4k') {
      --size: #{rem(456px)};
    }
  }

  &__img {
    position: relative;
    z-index: 3;
  }

  &__year {
    @include font-size(40px);

    margin-bottom: rem(32px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
    text-align: center;
    position: relative;
    z-index: 1;

    @include respond-to('laptop') {
      @include font-size(64px);

      margin-bottom: rem(48px);
    }

    @include respond-to('laptop-l') {
      @include font-size(72px);
    }

    @include respond-to('4k') {
      @include font-size(80px);
    }
  }

  &__description {
    @include font-size(20px, 32px);

    margin-bottom: 0;
    text-align: center;
    position: relative;
    z-index: 1;

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }
  }

  &__circle {
    --size: #{rem(520px)};

    @include absolute-center;

    max-width: var(--size);

    @include respond-to('tablet') {
      --size: #{rem(620px)};
    }

    @include respond-to('laptop') {
      --size: #{rem(780px)};
    }

    @include respond-to('laptop-l') {
      --size: #{rem(960px)};
    }

    @include respond-to('4k') {
      --size: #{rem(1148px)};
    }

    &--mobile {
      display: block;

      @include respond-to('tablet') {
        display: none;
      }
    }
  }

  &__arrow {
    display: none;

    @include respond-to('tablet') {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: rem(80px);
      top: rem(248px);
    }

    @include respond-to('laptop') {
      width: rem(100px);
      top: rem(348px);
    }

    @include respond-to('laptop-l') {
      width: rem(120px);
      top: rem(420px);
    }

    @include respond-to('4k') {
      width: rem(220px);
      top: rem(472px);
    }
  }
}
