.kolo-cannes-hero {
  position: relative;
  overflow: hidden;

  @include respond-to('laptop') {
    padding: rem(80px) 0;
  }

  @include respond-to('laptop-l') {
    padding: rem(96px) 0;
  }

  &__container {
    @include flex(flex-start, center, column);

    width: 100%;

    @include respond-to('laptop') {
      display: block;
      max-width: rem(600px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(660px);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__title {
    margin-bottom: rem(16px);
    text-align: center;
    position: relative;
    z-index: 2;

    @include respond-to('laptop') {
      text-align: left;
      margin-bottom: rem(24px);
    }
  }

  &__subtitle {
    @include font-size(16px, 24px);

    margin-bottom: rem(24px);
    font-weight: var(--fw-500);
    text-align: center;
    color: rgba($black, 0.68);

    @include respond-to('laptop') {
      @include font-size(20px, 32px);

      margin-bottom: rem(32px);
      text-align: left;
    }

    @include respond-to('4k') {
      @include font-size(24px, 40px);

      margin-bottom: rem(48px);
    }
  }

  &__description {
    @include font-size(20px, 32px);

    margin-bottom: rem(32px);
    font-weight: var(--fw-500);
    text-align: center;
    color: var(--black);

    @include respond-to('laptop') {
      text-align: left;
    }

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);

      margin-bottom: rem(48px);
    }

    @include respond-to('4k') {
      @include font-size(32px, 44px);
    }
  }

  &__cta {
    @include flex(center, center, column);

    width: 100%;
    gap: rem(24px);

    @include respond-to('mobile') {
      flex-direction: row;
    }

    @include respond-to('laptop') {
      justify-content: flex-start;
    }
  }

  &__promo {
    --size: #{rem(180px)};

    @include size(var(--size));

    position: relative;
    margin-bottom: rem(24px);

    @include respond-to('laptop') {
      --size: #{rem(240px)};

      position: absolute;
      left: auto;
      top: 50%;
      right: 5%;
      transform: translate(0%, -50%);
      z-index: 0;
      margin-bottom: 0;
    }

    @include respond-to('laptop-l') {
      --size: #{rem(360px)};

      right: 10%;
    }

    @include respond-to('4k') {
      --size: #{rem(460px)};

      right: 14%;
    }
  }

  &__button {
    @include size(100%);
    @include flex(center);
    @include reset-button;

    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }

  &__circles {
    @include absolute-center;

    width: rem(480px);
    pointer-events: none;

    @include respond-to('laptop') {
      width: rem(640px);
    }

    @include respond-to('laptop-l') {
      width: rem(920px);
    }

    @include respond-to('4k') {
      width: rem(1148px);
    }
  }

  &__button-icon {
    @include absolute-center;

    max-width: rem(40px);

    @include respond-to('laptop') {
      max-width: rem(64px);
    }

    @include respond-to('laptop-l') {
      max-width: rem(86px);
    }
  }
}
