.vacation {
  &__h2 {
    @include font-size(28px, 34px);

    margin-bottom: rem(32px);

    @include respond-to('laptop') {
      @include font-size(32px, 38px);
    }
  }

  &__text {
    @include font-size(14px, 21px);

    margin-bottom: rem(21px);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__description {
    @include respond-to('laptop-l') {
      max-width: rem(396px);
    }
  }

  &__cta {
    width: 100%;
    max-width: rem(326px);
  }
}
