.kolona-create {
  &__controls {
    @include flex(space-between, center, column-reverse);

    margin-top: rem(32px);
    gap: rem(16px);

    @include respond-to('mobile') {
      flex-direction: row;
    }
  }

  &__link {
    @include reset-button;

    text-decoration: underline;
    color: #444;

    &:hover {
      text-decoration: none;
      color: #444;
    }

    &:visited {
      color: #444;
    }
  }

  &__modal-cta {
    @include flex(space-between, center, column);

    gap: rem(16px);

    @include respond-to('mobile') {
      gap: rem(32px);
      flex-direction: row;
    }
  }
}
