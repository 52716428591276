.user-achievement-banner {
  width: 100%;
  overflow: hidden;
  border-radius: rem(16px);
  position: relative;
  background-color: #030201;
  margin-top: rem(22px);
  padding: rem(16px);

  @include respond-to('tablet') {
    padding: rem(24px) rem(40px);
  }

  @include respond-to('laptop-l') {
    padding: rem(24px) rem(84px);
  }

  &__container {
    @include flex(flex-start, stretch, column);

    position: relative;
    z-index: 2;
    gap: rem(24px);

    @include respond-to('laptop-l') {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__content {
    @include flex(space-between, null);

    gap: rem(16px);

    @include respond-to('laptop-l') {
      display: grid;
      grid-template-columns: rem(264px) rem(226px);
      max-width: rem(490px);
      align-items: center;
      gap: rem(4px);
    }
  }

  &__title {
    @include font-size(16px, 24px);

    color: var(--white);
    font-family: var(--heading-font-family);
    font-weight: 500;
    margin-bottom: 0;
    max-width: rem(264px);

    @include respond-to('tablet') {
      @include font-size(24px, 32px);
    }

    @include respond-to('laptop-l') {
      max-width: none;
    }
  }

  &__description {
    @include font-size(12px, 16px);

    color: var(--white);
    font-weight: 500;
    margin-bottom: 0;
    max-width: rem(154px);

    @include respond-to('tablet') {
      @include font-size(14px, 18px);

      max-width: rem(264px);
    }

    @include respond-to('laptop-l') {
      max-width: none;
    }
  }

  &__pin {
    position: absolute;
    z-index: 1;
    left: rem(-100px);
    bottom: rem(-48px);
    max-width: rem(340px);

    @include respond-to('tablet') {
      left: rem(-70px);
      bottom: rem(-40px);
    }

    @include respond-to('laptop-l') {
      top: 50%;
      left: auto;
      right: rem(236px);
      transform: translateY(-50%);
    }
  }

  &__cta {
    font-size: rem(14px);
    line-height: 1;
    padding: rem(11px) rem(16px);
  }
}
