.join-vacation {
  &__container {
    @include flex(space-between, center, column-reverse);

    gap: rem(32px);

    @include respond-to('tablet') {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__companies {
    max-width: rem(468px);

    @include respond-to('4k') {
      max-width: rem(640px);
    }
  }

  &__content {
    @include flex(flex-start, center, column);

    max-width: rem(468px);

    @include respond-to('tablet') {
      justify-content: center;
      align-items: flex-start;
    }
  }
}
