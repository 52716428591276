.kolona-list {
  display: grid;
  gap: rem(24px);
  grid-template-columns: repeat(2, 1fr);

  @include respond-to('tablet') {
    gap: rem(32px);
  }

  @include respond-to('laptop') {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond-to('laptop-l') {
    grid-template-columns: repeat(4, 1fr);
  }

  &--two {
    grid-template-columns: repeat(1, 1fr);

    @include respond-to('laptop') {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
