.kolo-cannes-supporter {
  @include flex(flex-start, center, column);
  @include size(100%);

  position: relative;
  padding: rem(24px) rem(24px) rem(56px);
  background-color: #fff7c2;
  border-radius: rem(24px);

  @include respond-to('laptop') {
    padding: rem(40px) rem(40px) rem(72px);
    border-radius: rem(40px);
  }

  &__picture {
    --size: #{rem(140px)};

    @include size(var(--size));
    @include flex(center, center);

    margin-bottom: rem(16px);
    border-radius: 50%;
    overflow: hidden;

    @include respond-to('laptop') {
      --size: #{rem(228px)};

      margin-bottom: rem(24px);
    }
  }

  &__name {
    @include font-size(20px, 32px);

    margin-bottom: rem(16px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-normal);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(24px, 40px);

      margin-bottom: rem(24px);
    }
  }

  &__role {
    @include font-size(16px, 24px);

    text-align: center;
    color: var(--grey);
  }

  &__socials {
    @include flex(center, center);

    width: 100%;
    gap: rem(32px);
    position: absolute;
    bottom: rem(24px);
    left: 0;
    right: 0;
  }

  &__link {
    display: block;
  }
}
