.news-card {
  @include flex(null, null, column);

  border: 1px solid #d4d4d4;
  border-radius: rem(16px);
  overflow: hidden;
  text-decoration: none;
  transition: box-shadow $animation;

  &:hover {
    box-shadow: 0 4px 40px rgba($black, 0.1);
    text-decoration: none;
    color: var(--black);
  }

  &__img {
    aspect-ratio: 2.58;
    object-fit: cover;
  }

  &__body {
    padding: rem(16px);
  }

  &__date {
    @include font-size(14px, 22px);

    margin-bottom: rem(8px);
    color: #8c8c8c;
  }

  &__title {
    @include font-size(20px, 24px);

    margin-bottom: rem(16px);
  }

  &__description {
    @include font-size(16px, 26px);

    margin-bottom: 0;
  }

  &--secondary {
    .news-card {
      &__title {
        margin-bottom: 0;
        display: inline;
        border-bottom: 1px solid transparent;
        transition: border-color $animation;
      }

      &__body {
        padding: 0;
      }
    }

    &:hover {
      text-decoration: none;
      color: var(--black);

      .news-card__title {
        border-color: var(--black);
      }
    }

    &:visited {
      color: var(--black);
    }
  }
}
