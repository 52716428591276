.js-collapsible {
  width: 100%;

  &-button {
    @include reset-button;

    width: 100%;
    text-align: left;
    outline: none;
  }

  &-content {
    overflow: hidden;
    transition: max-height $animation;
    max-height: 0;
  }

  &-icon {
    display: inline-block;
    transform-origin: 50% 50%;
    transition: transform $animation;
  }
}
