.icon {
  @include size(rem(24px));

  fill: currentColor; // stylelint-disable-line
}

.svg-icon {
  @include size(1em);

  fill: currentColor; // stylelint-disable-line
  flex-shrink: 0;
}
