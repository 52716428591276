.parent-campaign {
  display: grid;
  align-items: center;
  gap: rem(16px);
  margin-top: rem(16px);

  @include respond-to('tablet') {
    grid-template-columns: rem(108px) 1fr;
  }

  &__label {
    @include font-size(14px, 16px);

    font-weight: 700;
  }

  &__preview {
    width: rem(92px);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: 4px;
  }

  &__image {
    @include size(100%);

    object-fit: cover;
  }

  &__title {
    @include font-size(14px, 16px);

    font-weight: 700;

    @include respond-to('tablet') {
      @include font-size(16px, 19px);
    }
  }

  &__icon {
    @include size(rem(40px));
    @include flex(center, center);

    transform: rotate(180deg);
    border-radius: 50%;
    background-color: var(--primary);
    transition: background-color $animation;
    font-size: rem(20px);
  }

  &__link {
    display: grid;
    grid-template-columns: rem(92px) 1fr rem(40px);
    align-items: center;
    gap: rem(8px);
    border: 1px solid var(--c4);
    border-radius: rem(8px);
    padding: rem(8px) rem(16px) rem(8px) rem(8px);
    transition: box-shadow $animation;

    &:hover {
      text-decoration: none;
      color: var(--black);
      box-shadow: 0 4px 22px rgb(0 0 0 / 6%);

      .parent-campaign__icon {
        background-color: var(--primary-hover);
      }
    }
  }
}
