.why-kolo {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: rem(16px);

  @include respond-to('tablet') {
    grid-template-columns: repeat(2, 1fr);
    gap: rem(24px);
  }

  @include respond-to('laptop') {
    gap: rem(32px);
  }

  &__card {
    padding: rem(24px);
    background-color: rgba($white, 0.8);
    border-radius: rem(20px);

    @include respond-to('tablet') {
      padding: rem(32px);
    }

    @include respond-to('laptop') {
      border-radius: rem(40px);
    }

    @include respond-to('laptop-l') {
      padding: rem(40px);
    }
  }

  &__title {
    @include font-size(24px, 32px);

    margin-bottom: rem(20px);

    @include respond-to('tablet') {
      @include font-size(32px, 48px);
    }

    @include respond-to('laptop-l') {
      @include font-size(40px, 60px);
    }

    @include respond-to('4k') {
      @include font-size(44px, 64px);
    }
  }

  &__description {
    @include font-size(18px, 28px);

    margin-bottom: 0;

    @include respond-to('laptop-l') {
      @include font-size(24px, 40px);
    }
  }

  &--grey {
    .why-kolo {
      &__card {
        background-color: var(--f9);
      }

      &__description {
        color: var(--grey);
      }
    }
  }

  &--sm {
    .why-kolo {
      &__title {
        font-weight: var(--fw-700);

        @include respond-to('laptop-l') {
          @include font-size(40px, 48px);
        }
      }

      &__description {
        @include respond-to('laptop-l') {
          @include font-size(18px, 28px);
        }
      }
    }
  }

  &--primary {
    .why-kolo {
      &__card {
        background-color: rgba($primary, 0.4);
      }
    }
  }

  &--kava {
    .why-kolo {
      &__card {
        background-color: rgba($kava, 0.1);
      }
    }
  }
}

.why-kolo-header {
  margin-bottom: rem(40px);

  @include respond-to('tablet') {
    margin-bottom: rem(8px);
  }

  &--socials {
    @include flex(space-between, center, column);

    @include respond-to('tablet') {
      flex-direction: row;
      align-items: flex-start;
    }
  }
}
