.news {
  &__title {
    padding-left: rem(16px);
    border-left: 24px solid var(--primary);
    text-transform: uppercase;
    margin-bottom: rem(32px);

    @include respond-to('laptop') {
      margin-bottom: rem(64px);
      padding-left: rem(32px);
    }
  }

  &__listing {
    display: grid;
    gap: rem(24px);

    @include respond-to('mobile') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('laptop') {
      grid-template-columns: repeat(3, 1fr);
      gap: rem(40px);
    }
  }

  &__pagination {
    margin-top: rem(32px);

    @include respond-to('laptop') {
      margin-top: rem(64px);
    }
  }
}
