.tabs {
  @include flex(null, null, column);

  gap: rem(24px);
  position: relative;

  &__nav {
    @include flex(space-between, center);

    gap: rem(16px);
  }

  &__btn {
    @include reset-button;
    @include font-size(14px);

    flex: 1 0 auto;
    padding: rem(12px);
    background-color: transparent;
    border: 2px solid var(--f9);
    border-radius: rem(21px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
    color: var(--grey);
    transition:
      border-color $animation,
      color $animation,
      background-color $animation;

    &:hover {
      background-color: rgba($primary, 0.1);
      border-color: rgba($primary, 0.3);
    }

    &.is-active {
      border-color: var(--primary);
      color: var(--black);
    }
  }

  &__pane {
    display: none;

    &.is-active {
      display: block;
    }
  }
}
