.hero {
  &__top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: rem(32px);

    @include respond-to('laptop') {
      width: auto;
      gap: rem(88px);
      margin-bottom: rem(64px);
    }

    @include respond-to('laptop-l') {
      gap: rem(120px);
    }
  }

  &__cta {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to('laptop') {
      align-items: flex-start;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  &__logo-wrapper {
    --size: #{rem(300px)};

    width: var(--size);
    height: var(--size);
    display: none;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: flex-start;

    @include respond-to('laptop') {
      display: flex;
    }

    @include respond-to('laptop-l') {
      --size: #{rem(400px)};
    }

    @include respond-to('4k') {
      --size: #{rem(480px)};
    }
  }

  &__sub-title {
    max-width: rem(600px);
    margin-bottom: rem(32px);
    text-align: center;

    @include respond-to('laptop') {
      margin-bottom: rem(70px);
      text-align: left;
    }
  }

  &__buttons {
    @include flex(space-between, center, column);

    width: 100%;
    gap: rem(24px);

    @include respond-to('laptop') {
      flex-direction: column;
      align-items: flex-start;
    }

    @include respond-to('laptop-l') {
      gap: rem(32px);
      align-items: flex-start;
    }

    @include respond-to('4k') {
      flex-direction: row;
    }
  }
}
