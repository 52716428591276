.kolo-cannes-get-medals {
  &__wrapper {
    @include flex(center, center, column);

    width: 100%;
    margin-top: rem(32px);
    margin-bottom: rem(48px);
    gap: rem(32px);

    @include respond-to('laptop') {
      flex-direction: row;
      gap: 0;
      margin-top: rem(48px);
    }

    @include respond-to('4k') {
      margin-top: rem(96px);
    }
  }

  &__medal {
    --size: #{rem(454px)};

    @include size(var(--size));
    @include flex(flex-start, center, column);

    position: relative;
    padding: rem(96px) rem(80px) rem(24px);
    background-color: #fff7c2;
    border-radius: 50%;

    @include respond-to('laptop') {
      --size: #{rem(480px)};

      padding: rem(60px) rem(48px) rem(24px);
    }

    @include respond-to('laptop-l') {
      --size: #{rem(520px)};

      padding: rem(80px) rem(80px) rem(24px);
    }

    @include respond-to('4k') {
      --size: #{rem(688px)};

      padding: rem(124px) rem(112px) rem(24px);
    }
  }

  &__img-wrapper {
    @include size(100%);
    @include absolute-center;
  }

  &__img {
    @include absolute-center;

    max-width: 68%;

    @include respond-to('laptop') {
      max-width: 65%;
    }

    @include respond-to('4k') {
      max-width: 90%;
    }
  }

  &__title {
    @include font-size(20px, 24px);

    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }

    @include respond-to('4k') {
      @include font-size(32px, 40px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    margin-bottom: rem(32px);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(20px, 32px);
    }

    @include respond-to('4k') {
      @include font-size(24px, 40px);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
  }

  &__stripes {
    display: none;

    @include respond-to('laptop') {
      display: block;
      max-width: rem(1000px);
      position: absolute;
      top: rem(-64px);
      left: 50%;
      transform: translateX(-50%);
    }

    @include respond-to('laptop-l') {
      max-width: rem(1088px);
    }

    @include respond-to('4k') {
      max-width: rem(1468px);
    }
  }
}
