.kolossus-join {
  &__container {
    @include flex(null, null, column);

    grid-gap: rem(40px);

    @include respond-to('laptop') {
      display: grid;
      grid-gap: rem(16px) 15.3vw;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }
  }

  &__column {
    @include flex(space-between, center, column);

    @include respond-to('laptop') {
      align-items: flex-start;
    }
  }

  &__form-block {
    width: 100%;
  }

  &__content {
    text-align: center;

    @include respond-to('laptop') {
      text-align: left;
    }
  }

  &__leading {
    margin-bottom: rem(40px);
    color: var(--black);

    @include respond-to('laptop') {
      text-align: left;
    }
  }

  &__description {
    margin-bottom: rem(40px);
    color: var(--grey);
  }

  &__success {
    &-container {
      @include flex(flex-start, center, column);

      width: 100%;
      padding: rem(32px) rem(24px);
      background-color: var(--f9);
      border-radius: rem(40px);

      @include respond-to('tablet') {
        padding: rem(48px);
      }

      @include respond-to('laptop-l') {
        padding: rem(48px);
      }

      @include respond-to('4k') {
        padding: rem(56px) rem(104px) rem(104px);
      }
    }

    &-circle {
      --size: #{rem(80px)};

      @include size(var(--size));
      @include flex(center, center);

      margin-bottom: rem(24px);
      padding: rem(24px);
      border-radius: 50%;
      background-color: var(--primary);

      @include respond-to('laptop') {
        --size: #{rem(120px)};

        margin-bottom: rem(40px);
        padding: rem(40px);
      }

      @include respond-to('laptop-l') {
        --size: #{rem(160px)};
      }

      @include respond-to('4k') {
        --size: #{rem(192px)};
      }
    }

    &-icon {
      width: 100%;
    }

    &-title {
      @include font-size(24px, 32px);

      margin-bottom: rem(20px);
    }
  }
}
