.donate-hero {
  &__wrapper {
    width: 100%;
    max-width: rem(1024px);
    margin-left: auto;
    margin-right: auto;
  }

  &__leading {
    max-width: rem(680px);
  }

  &__buttons {
    @include flex(center, stretch, column);

    gap: rem(24px);
    max-width: rem(368px);
    margin-left: auto;
    margin-right: auto;

    @include respond-to('tablet') {
      max-width: none;
      flex-direction: row;
      align-items: center;
    }
  }
}
