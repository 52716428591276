.tg-cta {
  padding: rem(40px) 0;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: rem(32px);

    @include respond-to('laptop') {
      flex-direction: row;
      gap: rem(40px);
    }

    @include respond-to('4k') {
      gap: rem(140px);
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: rem(24px);

    @include respond-to('laptop') {
      flex-direction: row;
      gap: rem(40px);
    }
  }

  &__img-wrapper {
    --size: #{rem(64px)};

    width: var(--size);
    height: var(--size);
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to('laptop') {
      --size: #{rem(96px)};
    }
  }

  &__title {
    @include font-size(20px, 40px);

    margin-bottom: rem(8px);
    text-align: center;

    @include respond-to('laptop') {
      text-align: left;
    }

    @include respond-to('4k') {
      @include font-size(30px, 48px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    margin-bottom: 0;
    text-align: center;

    @include respond-to('laptop') {
      text-align: left;
    }

    @include respond-to('4k') {
      @include font-size(20px, 28px);
    }
  }
}
