.js-copy-to-clipboard {
  position: relative;

  &-button {
    @include reset-button;
  }

  &-popup {
    display: none;
    position: absolute;
    left: 50%;
    top: rem(-28px);
    transform: translateX(-50%);
  }
}
