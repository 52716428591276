.advent-calendar-page {
  background-color: var(--black);
  position: relative;

  &__container {
    position: relative;
    z-index: 2;
  }

  &__ball {
    @include size(rem(86px), rem(122px));

    position: absolute;
    left: 50%;
    top: 0;
    margin-left: rem(-43px);
    transform-origin: top center;
    animation: 3s cubic-bezier(0.42, 0, 0.58, 1) infinite alternate pendulum;

    @include respond-to('laptop') {
      @include size(rem(130px), rem(184px));

      margin-left: rem(-65px);
    }

    @keyframes pendulum {
      0% {
        transform: rotate(15deg);
      }

      100% {
        transform: rotate(-15deg);
      }
    }
  }

  &__promo {
    padding-top: rem(132px);
    padding-bottom: rem(40px);

    @include respond-to('laptop') {
      padding-top: rem(232px);
    }
  }

  &__h1 {
    margin-bottom: rem(8px);
  }

  &__h2 {
    @include font-size(32px, 36px);

    margin-bottom: rem(40px);

    @include respond-to('laptop') {
      @include font-size(40px, 48px);

      margin-bottom: rem(64px);
    }
  }

  &__text {
    @include font-size(16px, 21px);

    margin-bottom: rem(24px);
    font-weight: 500;

    @include respond-to('laptop') {
      @include font-size(18px, 28px);

      margin-bottom: rem(32px);
    }
  }

  &__cta {
    width: 100%;
    max-width: rem(340px);
    margin-top: rem(32px);
    position: relative;

    @include respond-to('laptop') {
      margin-top: rem(56px);
    }
  }

  &__cta-btn {
    width: 100%;
  }

  &__cta-snow {
    position: absolute;
    z-index: 2;
  }

  &__cta-snow-left {
    @include size(rem(70px), rem(15px));

    left: 2px;
    bottom: -7px;
  }

  &__cta-snow-right {
    @include size(rem(112px), rem(16px));

    right: 7px;
    top: -7px;
  }

  &__gift {
    padding-top: rem(96px);
  }

  &__forest {
    width: 100%;
    position: absolute;
    left: 0;
    top: rem(150px);
    z-index: 0;
    overflow: hidden;
    aspect-ratio: 0.8;

    @include respond-to('mobile') {
      top: rem(96px);
      aspect-ratio: 1.9;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: right top;
    }
  }

  &__snow {
    @include size(100%, 100%);

    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &__partners {
    width: 100%;
    max-width: rem(193px);
    margin: rem(60px) auto 0;

    @include respond-to('mobile') {
      max-width: rem(290px);
    }
  }
}
