.table {
  width: 100%;

  th {
    @include font-size(14px, 20px);

    padding-bottom: rem(4px);
    color: var(--c-9e);
    font-weight: var(--fw-200);
    text-align: left;
  }

  td {
    @include font-size(16px, 24px);

    padding: rem(12px) 0;
    font-weight: var(--fw-200);
    text-align: left;
    vertical-align: top;

    &:not(:last-child) {
      padding-right: rem(48px);
    }
  }
}
