.kava {
  &__wrapper {
    @include flex(null, center, column);

    width: 100%;
    max-width: rem(880px);
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  &__container {
    width: 100%;
    margin-top: rem(80px);
    margin-bottom: rem(40px);
  }
}
