.user-profile-copy-id {
  &__button {
    @include flex(null, center);

    width: 100%;
    max-width: rem(220px);
    gap: rem(24px);
    text-align: left;
  }

  &__popup {
    @include font-size(14px, 20px);

    padding: rem(4px) rem(8px);
    border-radius: rem(4px);
    position: absolute;
    left: 50%;
    top: rem(-28px);
    transform: translateX(-50%);
    background-color: var(--grey);
    color: #fff;
  }
}
