@import '~@styles/abstracts/helpers';
@import '~@styles/abstracts/variables';

.block {
  @include flex(null, null, column-reverse);

  gap: rem(16px);

  @include respond-to('laptop') {
    display: grid;

    &.hasLimit {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.buttons {
  @include flex(center, center);

  gap: rem(8px);

  @include respond-to('laptop') {
    gap: rem(16px);
  }
}

.limit {
  @include flex(null, center, column);

  @include respond-to('laptop') {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.pages {
  @include flex(center, center);

  gap: rem(8px);
}

.btn {
  @include size(rem(40px));

  padding: 0;
  border-width: 1px;
}

.nav {
  font-size: rem(24px);

  &:disabled {
    color: var(--cb);
  }
}

.selectWrapper {
  max-width: rem(80px) !important;
}

.selectLabel {
  white-space: nowrap;
}
