.kava-social {
  &__header {
    @include flex(null, center, column);

    margin-bottom: rem(24px);

    @include respond-to('laptop') {
      margin-bottom: rem(48px);
    }
  }

  &__title {
    margin-bottom: rem(12px);
    text-align: center;

    @include respond-to('laptop') {
      margin-bottom: rem(16px);
    }
  }

  &__text {
    @include font-size(16px, 24px);

    width: 100%;
    max-width: rem(600px);
    margin-bottom: 0;
    text-align: center;
    color: rgba($black, 0.6);

    @include respond-to('laptop') {
      @include font-size(24px, 34px);

      max-width: rem(780px);
    }
  }

  &__body {
    @include flex(space-between, center, column);

    width: 100%;
    max-width: rem(880px);
    padding: rem(20px) rem(28px);
    gap: rem(40px);
    border-radius: rem(16px);
    background: linear-gradient(180deg, rgb(61 26 1 / 80%) 0%, rgb(61 26 1 / 60%) 100%);

    @include respond-to('tablet') {
      flex-direction: row;
    }
  }

  &__hashtag {
    @include font-size(36px, 44px);

    margin-bottom: rem(4px);
    font-family: var(--heading-font-family);
    color: #fff;
    font-weight: var(--fw-700);

    &::before {
      content: '#';
      color: rgba(#fff, 0.5);
    }
  }

  &__description {
    @include font-size(18px, 22px);

    margin-bottom: 0;
    color: rgba(#fff, 0.5);
  }

  &__right {
    @include flex(space-between, center, column);

    gap: rem(20px);

    @include respond-to('mobile') {
      flex-direction: row;
      gap: rem(40px);
    }
  }

  &__link {
    @include flex(space-between, center);

    gap: rem(12px);
    padding-left: rem(18px);
    background-color: rgba(#fff, 0.9);
    border-radius: rem(26px);
    border: 2px solid rgba(#fff, 0.9);
    transition: border-color $animation;

    &:hover {
      border-color: var(--primary);
      text-decoration: none;
    }
  }

  &__link-text {
    @include flex(16px, 24px);

    margin-bottom: 0;
    font-weight: var(--fw-700);
    color: var(--kava);
  }

  &__logo {
    @include size(rem(48px));
  }
}
