.kava-hero {
  padding: rem(24px) 0 rem(60px);

  &__title {
    @include font-size(36px, 48px);

    margin-bottom: rem(12px);

    @include respond-to('laptop') {
      @include font-size(52px, 64px);

      margin-bottom: rem(24px);
    }
  }

  &__mark {
    white-space: nowrap;

    &::before,
    &::after {
      content: '”';
      color: var(--c-9e);
    }
  }

  &__subtitle {
    @include font-size(18px, 28px);

    margin-bottom: rem(12px);
    font-family: var(--font-family);
    font-weight: var(--fw-400);

    @include respond-to('laptop') {
      @include font-size(36px, 48px);

      margin-bottom: rem(24px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    margin-bottom: rem(40px);
    color: rgba($black, 0.6);

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }
  }
}
