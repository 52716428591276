.kava-cta {
  padding: rem(32px) 0 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    width: 2250px;
    height: 1072px;
    background: linear-gradient(
      180deg,
      rgb(255 184 0 / 80%) 0%,
      rgb(255 243 166 / 80%) 30%,
      rgb(255 255 255 / 80%) 100%
    );
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    z-index: 0;
  }

  &__cups {
    @include flex(center, center);

    position: relative;
    margin: 0 auto rem(48px);

    @include respond-to('laptop') {
      margin-bottom: rem(64px);
    }
  }

  &__cup {
    position: absolute;
    z-index: 0;

    &:not(.kava-cta__cup--center) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: rem(152px);

      @include respond-to('laptop') {
        max-width: rem(214px);
      }
    }

    &:first-child {
      margin-left: -100px;

      @include respond-to('laptop') {
        margin-left: -140px;
      }
    }

    &:last-child {
      margin-left: 100px;

      @include respond-to('laptop') {
        margin-left: 140px;
      }
    }

    &--center {
      max-width: rem(190px);
      position: relative;
      z-index: 1;

      @include respond-to('laptop') {
        max-width: rem(266px);
      }
    }
  }

  &__counter {
    @include flex(center, center, column);

    width: 100%;
    max-width: rem(360px);
    margin-bottom: rem(64px);
    background-color: rgba($kava, 0.2);
    border-radius: rem(16px);
    padding: rem(9px) rem(18px) rem(12px);

    @include respond-to('laptop') {
      padding: rem(12px) rem(20px) rem(20px);
    }
  }

  &__total-cups {
    @include font-size(40px, 48px);

    margin-bottom: rem(8px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
    color: var(--kava);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(48px, 56px);

      margin-bottom: rem(12px);
    }
  }

  &__description {
    @include font-size(16px, 24px);

    margin-bottom: 0;
    color: rgba($kava, 0.5);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(18px, 24px);
    }
  }
}
