.donation-amount {
  @include flex(space-between, center);

  &__block {
    @include flex(space-between, flex-end);

    gap: rem(4px);
  }

  &__label {
    @include font-size(14px, 18px);

    color: var(--c-666);
  }

  &__sum {
    @include flex(flex-end, flex-end);
    @include font-size(20px);

    gap: rem(4px);
    font-weight: 700;
    color: var(--black);

    &::after {
      @include font-size(16px, 18px);

      content: '₴';
    }

    &--target {
      color: var(--c-666);
    }
  }

  &--with-target {
    padding: rem(8px) 0;

    .donation-amount {
      &__block {
        flex-grow: 1;
      }
    }
  }
}
