.event-card {
  display: block;
  width: 100%;
  border: 1px solid var(--cb);
  border-radius: rem(16px);
  background-color: var(--white);
  overflow: hidden;
  align-self: start;

  &:hover {
    text-decoration: none;
    color: var(--black);
  }

  &__body {
    @include flex(null, null, column);

    gap: rem(16px);
    padding: rem(16px) rem(16px) rem(24px);
  }

  &__header {
    @include flex(null, null, column);

    gap: rem(4px);
  }

  &__preview {
    @include flex(center, center);

    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: var(--cb);
    aspect-ratio: 16 / 9;
  }

  &__img {
    @include size(100%);

    object-fit: cover;
  }

  &__title {
    @include font-size(18px, 24px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: 600;

    @include respond-to('laptop-l') {
      @include font-size(20px, 24px);
    }
  }

  &__description {
    @include font-size(14px, 21px);

    margin-bottom: 0;
  }

  &__donation {
    @include flex(null, null, column);

    gap: rem(12px);

    &--progress {
      border-top: 1px solid var(--cb);
      padding-top: rem(16px);
    }
  }
}
