.kolona-card {
  @include flex(null, center, column);

  gap: rem(8px);
  padding: rem(20px) rem(8px) rem(16px);
  border-radius: rem(20px);
  overflow: hidden;
  background-color: #f7f7f7;
  transition: box-shadow $animation;

  @include respond-to('mobile') {
    gap: rem(16px);
    padding: rem(28px) rem(16px) rem(32px);
    border-radius: rem(40px);
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 0 20px rgba($black, 0.2);
  }

  &--cta {
    background-color: rgb(252 228 13 / 30%);
  }

  &__photo-wrapper {
    @include size(100px);
    @include flex(center, center);

    background-color: #e1d791;
    border-radius: 50%;
    overflow: hidden;

    @include respond-to('mobile') {
      @include size(180px);
    }
  }

  &__photo {
    @include size(100%);

    object-fit: cover;
  }

  &__name {
    @include font-size(14px, 20px);

    font-weight: var(--fw-700);

    @include respond-to('mobile') {
      @include font-size(20px, 28px);
    }
  }

  &__message {
    @include font-size(9px, 14px);

    @include respond-to('mobile') {
      @include font-size(16px, 24px);
    }
  }

  &__btn {
    width: 100%;
    background-color: var(--primary);
    padding: rem(8px);
    border-radius: rem(24px);

    @include respond-to('mobile') {
      width: auto;
      padding: rem(12px) rem(24px);
    }
  }

  &__cross {
    @include size(50%);
  }
}
