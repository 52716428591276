.our-focus {
  &__directions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto rem(80px);
    gap: rem(40px);

    @include respond-to('tablet') {
      grid-template-columns: repeat(4, 1fr);
    }

    @include respond-to('laptop') {
      margin-bottom: rem(144px);
    }
  }

  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__img-wrapper {
    width: 100%;
    position: relative;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border-radius: 50%;

    &--extended {
      overflow: visible;
      border-radius: 0;

      .our-focus {
        &__img {
          max-width: 126%;
        }
      }
    }
  }

  &__dir-item {
    @include flex(flex-start, center, column);

    position: relative;
    gap: rem(16px);

    @include respond-to('laptop') {
      gap: rem(40px);
    }
  }

  &__circle {
    @include absolute-center;

    height: 100%;
    aspect-ratio: 1/1;
    background-color: rgba($primary, 0.24);
    z-index: 0;
    border-radius: 50%;
  }

  &__title {
    @include font-size(20px, 24px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: var(--fw-700);
    text-align: center;

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }

    @include respond-to('laptop-l') {
      @include font-size(36px, 48px);
    }
  }

  &__achievements {
    margin-bottom: rem(40px);
    font-family: var(--heading-font-family);

    @include respond-to('laptop') {
      margin-bottom: rem(96px);
    }

    &-title {
      @include font-size(18px, 24px);

      margin-bottom: 0;
      font-weight: var(--fw-700);
      color: rgba($black, 0.32);

      @include respond-to('tablet') {
        @include font-size(32px, 40px);
      }

      @include respond-to('laptop') {
        @include font-size(40px, 48px);
      }

      @include respond-to('laptop-l') {
        @include font-size(48px, 56px);
      }
    }

    &-sum {
      @include font-size(40px, 48px);

      margin: rem(12px) auto;
      font-weight: var(--fw-700);

      @include respond-to('tablet') {
        @include font-size(56px, 64px);

        margin: rem(24px) auto;
      }

      @include respond-to('laptop') {
        @include font-size(64px, 72px);
      }

      @include respond-to('laptop-l') {
        @include font-size(80px, 84px);
      }
    }

    &-date {
      @include font-size(14px, 16px);

      margin: 0 auto rem(40px);
      color: rgba($black, 0.32);

      @include respond-to('tablet') {
        margin: 0 auto rem(16px);
      }
    }
  }
}
