.spoiler {
  width: 100%;
  position: relative;

  &__text {
    @include font-size(18px, 28px);

    margin-bottom: 0;
    font-weight: var(--fw-500);
    color: #fff;
    text-align: center;
    opacity: 1;
    transition: opacity $animation;

    &.is-hidden {
      opacity: 0;
    }
  }

  &__particles {
    @include absolute-center;

    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 1;
    transition: opacity $animation;

    &.is-hidden {
      opacity: 0;
    }
  }
}
