.service-page {
  &__content {
    h1 {
      @include font-size(32px, 40px);

      margin-bottom: rem(24px);
      font-family: var(--heading-font-family);
      font-weight: var(--fw-700);
      text-transform: uppercase;

      @include respond-to('tablet') {
        @include font-size(40px, 48px);

        margin-bottom: rem(32px);
      }

      @include respond-to('laptop') {
        @include font-size(48px, 56px);
      }
    }

    h2 {
      @include font-size(20px, 30px);

      margin-bottom: rem(20px);
      font-family: var(--heading-font-family);
      font-weight: var(--fw-700);
      text-transform: uppercase;

      &:not(:first-child) {
        margin-top: rem(24px);

        @include respond-to('tablet') {
          margin-top: rem(32px);
        }

        @include respond-to('laptop') {
          margin-top: rem(40px);
        }
      }

      @include respond-to('tablet') {
        @include font-size(24px, 32px);

        margin-bottom: rem(24px);
      }

      @include respond-to('laptop') {
        @include font-size(32px, 48px);
      }
    }

    p {
      @include font-size(16px, 24px);

      margin-bottom: rem(16px);

      @include respond-to('laptop') {
        margin-bottom: rem(20px);
      }
    }

    ul {
      li {
        margin-bottom: rem(16px);
      }
    }

    a {
      text-decoration: none;
      border-bottom: 1px solid var(--black);
      transition: border-color $animation;
      word-break: break-all;

      &:hover {
        text-decoration: none;
        color: var(--black);
        border-color: var(--primary);
      }
    }
  }
}
