.user-profile-info {
  @include flex(null, center);

  gap: rem(16px);

  &__photo-wrapper {
    @include size(rem(40px));
    @include flex(center, center);

    flex: 1 0 auto;
    max-width: rem(40px);
    border: 2px solid #e7e9ef;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }

  &__email {
    @include font-size(14px, 20px);

    margin-bottom: 0;
    font-weight: 500;
    word-break: break-all;
  }

  &__photo {
    @include size(100%);

    object-fit: contain;
  }

  &__acronym {
    @include font-size(16px, 22px);

    font-weight: 500;
    color: var(--black);
  }
}
