.progressbar {
  @include size(100%, rem(16px));

  border: 1px solid var(--cb);
  position: relative;
  overflow: hidden;
  background-color: var(--white);
  border-radius: rem(12px);

  &__scale {
    height: 100%;
    background-color: var(--black);
    border-radius: rem(12px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__value {
    @include font-size(10px);

    margin-left: rem(-24px);
    position: absolute;
    left: rem(12px);
    top: 2px;
    z-index: 2;
    text-align: center;
    font-weight: 600;
    color: var(--white);
    mix-blend-mode: exclusion;

    &--fixed {
      margin-left: rem(4px);
    }
  }
}
