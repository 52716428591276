.video-vacation {
  &__cta {
    margin-top: rem(16px);

    @include respond-to('mobile') {
      margin-top: rem(40px);
    }

    @include respond-to('tablet') {
      margin-top: rem(80px);
    }
  }
}
