.vacation-donation {
  @include respond-to('laptop') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__image {
    overflow: hidden;
    border-radius: rem(16px);
    margin-bottom: rem(32px);

    @include respond-to('laptop') {
      margin-bottom: 0;
    }
  }

  &__content {
    @include flex(flex-start, center, column);

    @include respond-to('laptop') {
      justify-content: center;
      align-items: flex-start;
    }
  }
}
