.vacation-partners {
  @include flex(null, null, column);

  gap: rem(32px);
  margin-top: rem(64px);

  @include respond-to('laptop') {
    margin-top: rem(80px);
  }

  &__row {
    @include flex(null, null, column);

    gap: rem(24px);

    @include respond-to('laptop') {
      display: grid;
      grid-template-columns: rem(280px) 1fr;
      gap: rem(32px);
      align-items: center;
    }

    @include respond-to('laptop-l') {
      grid-template-columns: rem(368px) 1fr;
    }
  }

  &__title {
    @include font-size(20px, 24px);

    margin-bottom: 0;
    font-family: var(--heading-font-family);
    font-weight: 700;
  }

  &__label {
    @include font-size(16px, 20px);

    margin-bottom: 0;
    font-weight: 700;
    color: #7b7b7b;
  }

  &__list {
    @include flex(stretch, stretch, column);

    gap: rem(8px);

    @include respond-to('laptop') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    @include flex(center, center);
    @include size(100%, rem(80px));

    padding: rem(24px);
    background-color: var(--white);
    border: 1px solid var(--c4);
    border-radius: rem(8px);
    transition: box-shadow $animation;

    &:hover {
      text-decoration: none;
      box-shadow: 0 4px 22px rgb(0 0 0 / 6%);
    }
  }

  &__logo {
    @include size(100%);

    object-fit: contain;
  }
}
