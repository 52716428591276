.kava-selector {
  @include flex(null, center, column);

  gap: rem(60px);
  width: 100%;

  &__grid {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: rem(16px);

    @include respond-to('tablet') {
      display: grid;
      grid-template-areas:
        'controllers controllers preview'
        'controllers controllers preview'
        'prediction prediction preview';
      grid-row-gap: rem(16px) rem(40px);
    }
  }

  &__controllers {
    width: 100%;
    grid-area: controllers;
    order: 1;

    @include respond-to('laptop') {
      order: initial;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: rem(16px);
      margin-top: rem(32px);

      @include respond-to('laptop') {
        grid-row-gap: rem(16px) rem(20px);
      }
    }
  }

  &__preview {
    --size: #{rem(304px)};

    @include size(var(--size));
    @include flex(center, center);

    position: relative;
    grid-area: preview;
    background: rgba(#fff, 0.2);
    border-radius: rem(20px);
    order: 0;

    @include respond-to('laptop') {
      --size: #{rem(400px)};

      @include font-size(14px);

      order: initial;
    }
  }

  &__prediction {
    grid-area: prediction;
    order: 2;

    @include respond-to('laptop') {
      order: initial;
    }
  }

  &__emoji-select {
    @include size(100%);

    position: relative;
  }

  &__btn {
    @include reset-button;

    font-size: 3rem;
    animation: shake 6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;

    &.is-xxl {
      margin-top: rem(10px);
    }

    &-container {
      @include size(#{rem(56px)});
      @include flex(center, center);
      @include absolute-center;

      margin-top: rem(3px);
    }
  }

  &__cup-img {
    @include absolute-center;

    display: none;

    &.is-active {
      display: block;
    }
  }
}

@keyframes shake {
  2%,
  18% {
    transform: translate3d(-1px, 0, 0);
  }

  4%,
  16% {
    transform: translate3d(2px, 0, 0);
  }

  6%,
  10%,
  14% {
    transform: translate3d(-4px, 0, 0);
  }

  8%,
  12% {
    transform: translate3d(4px, 0, 0);
  }
}
