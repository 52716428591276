.user-achievements {
  @include respond-to('laptop-l') {
    display: grid;
    grid-template-columns: 1fr rem(368px);
    gap: rem(32px);
  }

  &__content {
    @include flex(null, null, column);

    gap: rem(32px);
  }

  &__title {
    @include font-size(22px, 26px);

    margin-bottom: 0;

    @include respond-to('laptop') {
      @include font-size(40px, 48px);
    }
  }

  &__leading {
    @include font-size(18px, 24px);

    margin-bottom: 0;
    font-weight: 700;

    @include respond-to('laptop') {
      @include font-size(24px, 32px);
    }
  }

  &__list {
    @include font-size(18px, 25px);

    margin: 0;
    padding-left: rem(24px);
    font-weight: 500;

    li:not(:last-child) {
      margin-bottom: rem(16px);
    }
  }

  &__text {
    @include font-size(18px, 25px);

    margin-bottom: 0;
  }

  &__aside {
    display: none;

    @include respond-to('laptop-l') {
      display: block;
      padding-top: rem(60px);
      position: relative;
    }
  }

  &__card {
    @include flex(center, center);

    @include respond-to('laptop-l') {
      display: none;
    }
  }

  &__sticky-card {
    position: sticky;
    top: calc(var(--header-height) + 1rem);
  }
}
