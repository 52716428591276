.press-block {
  display: flex;
  flex-wrap: nowrap;
  gap: rem(12px);
  overflow: auto hidden;

  @include respond-to('mobile') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(16px);
    overflow: visible;
  }

  @include respond-to('tablet') {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond-to('laptop') {
    grid-template-columns: repeat(4, 1fr);
  }

  @include respond-to('laptop-l') {
    grid-template-columns: repeat(5, 1fr);
  }

  &__link {
    @include flex(center, center);

    width: auto;
    height: rem(64px);
    padding: rem(12px) rem(24px);
    border-radius: rem(16px);
    background-color: #fff;
    text-decoration: none;
    transition: box-shadow $animation;
    cursor: pointer;
    flex: 1 0 auto;

    @include respond-to('tablet') {
      padding: rem(12px);
    }

    &:hover {
      text-decoration: none;

      @at-root html:not(.is-touch) & {
        box-shadow: 0 0 20px rgba($black, 0.2);

        .press-block__img {
          filter: grayscale(0);
        }
      }
    }
  }

  &__img {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 100%;

    @at-root html:not(.is-touch) & {
      filter: grayscale(1);
      transition: filter $animation;
    }
  }
}
